import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

export default (contents) => contents.map((product) => {

    const fields = fieldsArrayToKeyValueHash(product.fields);

    fields.Tags = fields.Tags.split(",");

    return fields;

});
