import "./buy-banner.scss";
import Cta from "../../atoms/cta";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

const buyBanner = (props) => {

    if (props.fields) {

        const {CtaCollection, Heading} = fieldsArrayToKeyValueHash(props.fields);

        if (!CtaCollection) {

            return false;

        }

        return (
            <aside className="buy-banner">
                <div className="buy-banner__inner">
                    {Heading && <h2 className="buy-banner__heading">{Heading}</h2>}
                    <div className="buy-banner__actions">
                        {CtaCollection.map((ctaInstance, index) => <Cta
                            key={`cta-${index}`}
                            cta={ctaInstance}
                            version="primary"
                            varient={index && "ghost"}
                        />)}
                    </div>
                </div>
            </aside>
        );

    }

    return false;


};

export default buyBanner;
