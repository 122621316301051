import "./style.scss";
import Cta from "../../atoms/cta";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import sanitise from "global/utils/sanitise";

import iconChatbot from 'global/images/chatbot/icon--chatbot.svg'
import iconChatbotWhite from 'global/images/chatbot/icon--chatbot--white.svg'
import styledBg from 'global/images/chatbot/styled_title_bg.png'


const cn = require('classnames')

const BannerBlock = (props) => {
  const getImage = (image, size) => {
    const imgSize = size ? size : '1920'
    let useImage = ''

    if(image && image[0].value !== '') {
      if(image[0].value.includes('mode=crop')) {
        useImage = `${image[0].value.split("?")[0]}?mode=max&width=${imgSize}`
      }else{
        useImage = image[0].value
      }
    }

    return useImage
  }

  const toggleWindow = (e) => {
    window.chatWidget.toggle()
  }

  const getChatBotIcon = (textColor) => {
    let icon = iconChatbot
    if(textColor !== '') {
      switch (textColor) {
        case 'black':
          icon = iconChatbot
          break;
        case 'white':
          icon = iconChatbotWhite
          break;
        default:
          icon = iconChatbot
          break;
      }
    }

    return icon
  }
  if (props && props.fields) {
    const {
      BackgroundColor,
      BackgroundImage,
      BackgroundImageMobile,
      BannerCopy,
      BannerEyebrow,
      BannerImage,
      BannerImageMobile,
      BannerStyledTitle,
      BannerStyledTitleColor,
      BannerTextColor,
      BannerTitle,
      BannerTitleColor,
      ChatbotButtonMobilePlacement,
      ChatbotButtonText,
      CTabackgroundColor,
      CTatextColor,
      EnableChatbot,
      MobileOrientation,
      MobileTextAlignment,
      StyledTitleBackground,
    } = fieldsArrayToKeyValueHash(props.fields);
    
    const useBG = getImage(BackgroundImage)
    const useBGMobile = BackgroundImageMobile ? getImage(BackgroundImageMobile) : useBG
    const bannerIMG = getImage(BannerImage, 1200)
    const bannerMobileIMG = getImage(BannerImageMobile, 800)
    let mobileOrient = 'bottom'
    let cbBtnPos = 'content'
    let mobileTextAlign = 'left'

    if (MobileOrientation && MobileOrientation !== '') {
      switch (MobileOrientation.toLowerCase()) {
        case 'image on top':
          mobileOrient = 'top'
          break;
        case 'image on bottom':
          mobileOrient = 'bottom'
          break;
        default:
          mobileOrient = 'bottom'
          break;
      }
    }

    if (ChatbotButtonMobilePlacement && ChatbotButtonMobilePlacement !== '') {
      switch (ChatbotButtonMobilePlacement.toLowerCase()) {
        case 'with content':
          cbBtnPos = 'content'
          break;
        case 'bottom':
          cbBtnPos = 'bottom'
          break;
        default:
          cbBtnPos = 'content'
          break;
      }
    }

    if (MobileTextAlignment && MobileTextAlignment !== '') {
      switch (MobileTextAlignment.toLowerCase()) {
        case 'left':
          mobileTextAlign = 'left'
          break;
        case 'center':
          mobileTextAlign = 'center'
          break;
        default:
          mobileTextAlign = 'left'
          break;
      }
    }

    return (
      <section className={`bannerblock ${BackgroundColor && BackgroundColor !== '' ? `bannerblock--${BackgroundColor}` : ''}`}>
        {useBG !== '' &&
          <>
            <img src={useBG} className="bannerblock__bg bannerblock__bg--desktop" aria-hidden="true" loading="lazy" />
            <img src={useBGMobile} className="bannerblock__bg bannerblock__bg--mobile" aria-hidden="true" loading="lazy" />
          </>
        }
        <div className="container container--1600">
          <div className="bannerblock__wrap">
            <div className={`content ${mobileOrient === 'top' ? 'order-1' : ''} ${mobileTextAlign !== '' ? `content--${mobileTextAlign}` : ''}`}>
              {BannerStyledTitle || BannerTitle ? (
                  <>
                    {BannerEyebrow &&
                      <div className={`content__eyebrow ${BannerTextColor && BannerTextColor !== '' ? `text--${BannerTextColor}` : ''}`}>
                        {BannerEyebrow}
                      </div>
                    }
                    <h2 className={`content__title ${BannerTitleColor && BannerTitleColor !== '' ? `text--${BannerTitleColor}` : `text--${BannerTextColor}`}`}>
                      {BannerStyledTitle && BannerStyledTitle !== '' ? (
                        <span className={`styled  ${BannerStyledTitleColor && BannerStyledTitleColor !== '' ? `text--${BannerStyledTitleColor}` : `text--${BannerTextColor}`} ${StyledTitleBackground === true ? 'withbg' : ''}`}>
                          {StyledTitleBackground === true &&
                            <span className="styledbg"><img src={styledBg} aria-hidden="true" /></span>
                          }
                          <span>{BannerStyledTitle}</span>
                        </span>
                        ) : null
                      }
                      {BannerTitle}
                    </h2>
                  </>
                ) : null
              }
              
              <div className={`content__copy ${BannerTextColor && BannerTextColor !== '' ? `text--${BannerTextColor}` : ''}`} dangerouslySetInnerHTML={{__html: sanitise(BannerCopy)}} />
              {EnableChatbot === true ? (
                  <div className={`chatbot incontent ${(cbBtnPos && cbBtnPos !== '') ? `cbcontent--${cbBtnPos}` : ''}`}>
                    <button className={`chatbot__button ${CTabackgroundColor && CTabackgroundColor !== '' ? `bg--${CTabackgroundColor}` : 'bg--red'} ${CTatextColor && CTatextColor !== '' ? `text--${CTatextColor}` : 'text--white'}`} onClick={(e) => toggleWindow()}>
                      <img src={getChatBotIcon(CTatextColor)} alt="chatbot icon" aria-hidden="true" />
                      {(ChatbotButtonText && ChatbotButtonText !== '') ? ChatbotButtonText : 'Ask us anything'}
                    </button>
                  </div>
                ) : null
              }
            </div>
            <div className={`image ${mobileOrient === 'top' ? 'order-0' : ''}`}>
              <button
                aria-label={(ChatbotButtonText && ChatbotButtonText !== '') ? ChatbotButtonText : 'Ask us anything'}
                className="bg--transparent btn--image"
                onClick={(e) => toggleWindow()}
              >
                <img src={bannerIMG} className="content__image content__image--desktop" aria-hidden="true" loading="lazy" />
                <img src={bannerMobileIMG} className="content__image content__image--mobile" aria-hidden="true" loading="lazy" />
              </button>
            </div>
            {cbBtnPos === 'bottom' ? (
                <div className={`chatbot ${(cbBtnPos && cbBtnPos !== '') ? `chatbot--${cbBtnPos}` : ''} order-3`}>
                  <button
                    aria-label={(ChatbotButtonText && ChatbotButtonText !== '') ? ChatbotButtonText : 'Ask us anything'}
                    className={`chatbot__button ${CTabackgroundColor && CTabackgroundColor !== '' ? `bg--${CTabackgroundColor}` : 'bg--red'} ${CTatextColor && CTatextColor !== '' ? `text--${CTatextColor}` : 'text--white'}`}
                    onClick={(e) => toggleWindow()}
                  >
                    <img src={getChatBotIcon(CTatextColor)} alt="chatbot icon" aria-hidden="true" />
                    {(ChatbotButtonText && ChatbotButtonText !== '') ? ChatbotButtonText : 'Ask us anything'}
                  </button>
                </div>
              ) : null
            }
            
          </div>
        </div>
      </section>
    );

  }

  return false;
};

export default BannerBlock;
