import "./ingredients.scss";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

const Ingredients = (props) => {

    const {
        Title,
        ServingText,
        IngredientList,
    } = fieldsArrayToKeyValueHash(props.fields);

    return (
        <div id="ingredients" tabIndex="-1" className="ingredients">
            <div className="ingredients__inner">
                <div className="ingredients__heading">
                    {Title &&
                    <h2 className="ingredients__title">{Title}</h2>
                    }
                    {ServingText &&
                    <p className="ingredients__summary">{ServingText}</p>
                    }
                </div>
                <ul className="ingredients__grid">
                    {
                        IngredientList.map((ingredient, index) => {

                            const {
                                IngredientTitle,
                                IngredientQuantity,
                                IngredientUnit,
                            } = fieldsArrayToKeyValueHash(ingredient.fields);

                            return <li key={`ingredient-${index}`} className="ingredients__grid-item">
                                <span className="ingredients__grid-item-heading">
                                    {IngredientQuantity} {IngredientUnit}
                                </span>
                                <span className="ingredients__grid-item-copy">
                                    {IngredientTitle}
                                </span>
                            </li>;

                        })
                    }
                </ul>
            </div>
        </div>

    );

};

export default Ingredients;
