import "./campaign-promo.scss";
import Cta from "../../atoms/cta";
import Lazy from "global/utils/lazy";
import React from "react";
import VideoPlayer from "../../molecules/video-player";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import sanitise from "global/utils/sanitise";

const CampaignPromo = (props) => {

    const {
        Heading,
        VideoId,
        PosterImage,
        Strapline,
        CtaCollection,
    } = fieldsArrayToKeyValueHash(props.fields);

    if (!VideoId || !VideoPlayer || !PosterImage) {

        return null;

    }

    const {Url} = fieldsArrayToKeyValueHash(PosterImage);

    return (
        <section className="campaign-promo">
            <Lazy
                Tag="div"
                containerClassName="campaign-promo__video"
            >
                <div className="campaign-promo__video-player">
                    <VideoPlayer
                        videoId={VideoId}
                        posterImage={Url}
                    />
                </div>
            </Lazy>
            <div className="campaign-promo__content">
                {
                    Heading &&
                        <h2 className="campaign-promo__title">
                            {Heading}
                        </h2>
                }
                {
                    Strapline &&
                        <div
                            className="campaign-promo__strapline"
                            dangerouslySetInnerHTML={{__html: sanitise(Strapline)}}
                        />
                }
                {
                    CtaCollection &&
                        CtaCollection.map((cta, index) => <Cta
                            cta={cta}
                            key={index}
                        />)
                }
            </div>
        </section>
    );

};

export default CampaignPromo;
