import mapPin from 'global/images/maps/map_pin.svg'
import pinShip from 'global/images/ftc2024/pin_ship.png'
import pinEpic from 'global/images/ftc2024/pin_epic.png'
import pinTeam from 'global/images/ftc2024/pin_team.png'
import btnBack from 'global/images/ftc2024/btn__backtomap.png'
import pinCaptainShip from 'global/images/ftc2024/pin_captain_ship.png'
import pinCaptainCargo from 'global/images/ftc2024/captain_cargo_large.png'
import pinCaptainSB from 'global/images/ftc2024/pin_captain_sb.png'
import btnPlay from 'global/images/ftc2024/btn__play.png'
import btnPrizes from 'global/images/ftc2024/btn__prizes.png'
import btnRsvp from 'global/images/ftc2024/btn__rsvpnow.png'
import bgRed from 'global/images/ftc2024/map_texture.jpg'

import panelLogoCptNfl from 'global/images/ftc2024/teams/panel_logo--cptnfl.png'
import panelLogoBlackhawks from 'global/images/ftc2024/teams/panel_logo--blackhawks.png'
import panelLogoBucks from 'global/images/ftc2024/teams/panel_logo--bucks.png'
import panelLogoBulls from 'global/images/ftc2024/teams/panel_logo--bulls.png'
import panelLogoCardinals from 'global/images/ftc2024/teams/panel_logo--cardinals.png'
import panelLogoChargers from 'global/images/ftc2024/teams/panel_logo--chargers.png'
import panelLogoCowboys from 'global/images/ftc2024/teams/panel_logo--cowboys.png'
import panelLogoGiants from 'global/images/ftc2024/teams/panel_logo--giants.png'
import panelLogoJets from 'global/images/ftc2024/teams/panel_logo--jets.png'
import panelLogoPenguins from 'global/images/ftc2024/teams/panel_logo--penguins.png'
import panelLogoRams from 'global/images/ftc2024/teams/panel_logo--rams.png'
import panelLogoWild from 'global/images/ftc2024/teams/panel_logo--wild.png'

import iconXp from 'global/images/ftc2024/icons/icons--experience.png'
import iconVip from 'global/images/ftc2024/icons/icons--vip.png'
import iconTeam from 'global/images/ftc2024/icons/icons--teamswag.png'

import imgDefault from 'global/images/ftc2024/image-default.jpg'
import sbScreen from 'global/images/ftc2024/sb_lix_screen.png'
import imgLocation1 from 'global/images/ftc2024/image-location-1.png'
import imgLocation2 from 'global/images/ftc2024/image-location-2.png'

import shipStopSample from 'global/images/ftc2024/ship_stop_sample.png'
import $ from 'jquery'
import * as moment from 'moment'

const token =
  'pk.eyJ1IjoibXdrcmllZ2VyIiwiYSI6ImNqcDA1NGQ4bDA0cjIzd3BpbGl5NzVvcXMifQ.2Cs5UE0BiZsh_wt36xHRoA'

let map

let markers = []

let filteredLocations = []

let locationsOutput = ''

import highlight1 from 'global/images/ftc2024/events/ftc_2024_highlights_1_1.jpg'
import highlight2 from 'global/images/ftc2024/events/ftc_2024_highlights_1_2.jpg'
import highlight3 from 'global/images/ftc2024/events/ftc_2024_highlights_1_3.jpg'
import highlight4 from 'global/images/ftc2024/events/ftc_2024_highlights_1_4.jpg'
import highlight5 from 'global/images/ftc2024/events/ftc_2024_highlights_1_5.jpg'
import highlight6 from 'global/images/ftc2024/events/ftc_2024_highlights_1_6.jpg'
import highlight7 from 'global/images/ftc2024/events/ftc_2024_highlights_1_7.jpg'
import highlight8 from 'global/images/ftc2024/events/ftc_2024_highlights_1_8.jpg'
import highlight9 from 'global/images/ftc2024/events/ftc_2024_highlights_1_9.jpg'
import highlight10 from 'global/images/ftc2024/events/ftc_2024_highlights_1_10.jpg'
import highlight11 from 'global/images/ftc2024/events/ftc_2024_highlights_1_11.jpg'
import highlight12 from 'global/images/ftc2024/events/ftc_2024_highlights_1_12.jpg'
import highlight13 from 'global/images/ftc2024/events/ftc_2024_highlights_1_13.jpg'
import highlight14 from 'global/images/ftc2024/events/ftc_2024_highlights_1_14.jpg'
import highlight15 from 'global/images/ftc2024/events/ftc_2024_highlights_1_15.jpg'
import highlight16 from 'global/images/ftc2024/events/ftc_2024_highlights_1_16.jpg'
import highlight17 from 'global/images/ftc2024/events/ftc_2024_highlights_1_17.jpg'
import highlight18 from 'global/images/ftc2024/events/ftc_2024_highlights_1_18.jpg'
import highlight19 from 'global/images/ftc2024/events/ftc_2024_highlights_1_19.jpg'
import highlight20 from 'global/images/ftc2024/events/ftc_2024_highlights_1_20.jpg'

const galleryData = [
  {
    image: highlight1,
    imagealt: "Follow the Captain 2023 Image 1"
  },
  {
    image: highlight2,
    imagealt: "Follow the Captain 2023 Image 2"
  },
  {
    image: highlight3,
    imagealt: "Follow the Captain 2023 Image 3"
  },
  {
    image: highlight4,
    imagealt: "Follow the Captain 2023 Image 4"
  },
  {
    image: highlight5,
    imagealt: "Follow the Captain 2023 Image 5"
  },
  {
    image: highlight6,
    imagealt: "Follow the Captain 2023 Image 6"
  },
  {
    image: highlight8,
    imagealt: "Follow the Captain 2023 Image 8"
  },
  {
    image: highlight9,
    imagealt: "Follow the Captain 2023 Image 9"
  },
  {
    image: highlight10,
    imagealt: "Follow the Captain 2023 Image 10"
  },
]

// Delete markers
function clearMarkers() {
  markers.forEach((marker) => {
    marker.remove()
  })
  markers = []
}

function getTeamImage(imageName) {
  let sendImage = ''

  switch (imageName) {
    case 'arizonacardinals':
    sendImage = panelLogoCardinals
    break;
    case 'chicagoblackhawks':
    sendImage = panelLogoBlackhawks
    break;
    case 'chicagobulls':
    sendImage = panelLogoBulls
    break;
    case 'dallascowboys':
    sendImage = panelLogoCowboys
    break;
    case 'losangeleschargers':
    sendImage = panelLogoChargers
    break;
    case 'losangelesrams':
    sendImage = panelLogoRams
    break;
    case 'milwaukeebucks':
    sendImage = panelLogoBucks
    break;
    case 'minnesotawild':
    sendImage = panelLogoWild
    break;
    case 'newyorkgiants':
    sendImage = panelLogoGiants
    break;
    case 'newyorkjets':
    sendImage = panelLogoJets
    break;
    case 'pittsburghpenguins':
    sendImage = panelLogoPenguins
    break;
    default:
    sendImage = ''
    break;
  }

  return sendImage
}

function getLocationImage(imageName) {
  let sendImage = ''

  switch (imageName) {
    case 'ship_stop_sample':
      sendImage = shipStopSample
      break
    case 'image-location-1':
      sendImage = imgLocation1
      break
    case 'image-location-2':
      sendImage = imgLocation2
      break
    default:
      sendImage = ''
      break
  }

  return sendImage
}

function lockElements() {
  const htmlElem = document.getElementsByTagName('html')[0]
  const bodyElem = document.getElementsByTagName('body')[0]
  const navElem = document.getElementById('mainNav')

  htmlElem.classList.add('locked')
  bodyElem.classList.add('locked')
  navElem.classList.add('locked')
}

function unLockElements() {
  const htmlElem = document.getElementsByTagName('html')[0]
  const bodyElem = document.getElementsByTagName('body')[0]
  const navElem = document.getElementById('mainNav')

  htmlElem.classList.remove('locked')
  bodyElem.classList.remove('locked')
  navElem.classList.remove('locked')
}

function createLocationsOutput() {
  locationsOutput += '<div class="locevent__wrap">'

  filteredLocations.forEach((loc) => {
    // Console.log(loc)
    if (loc.loctype === 'ship') {
      locationsOutput += '<div class="locevent__item">'
      if (loc.datestart === loc.dateend) {
        locationsOutput += `<div class="locevent__date">${moment(loc.datestart).format(
          'MMMM D'
        )}</div>`
      } else {
        locationsOutput += `<div class="locevent__date">${moment(loc.datestart).format(
          'MMMM D'
        )} - ${moment(loc.dateend).format('MMMM D')}</div>`
      }

      locationsOutput += `<div class="locevent__place">${loc.locaddress}, ${loc.locstate}</div>`
      //LocationsOutput += `<div class="locevent__name">${loc.locname}</div>`

      locationsOutput += '</div>'
    }
  })

  locationsOutput += '</div>'
}

export const setLocations = (loclist) => {
  if (typeof loclist === 'object' && loclist.length > 0) {
    // Set the filtered list
    filteredLocations = loclist
    createLocationsOutput()
  }
}

export const init = (callback, that) => {
  const windowWidth = document.documentElement.clientWidth
  const center = windowWidth < 768 ? [-70.5797656, 37.3125119] : [-96, 37.8]

  mapboxgl.accessToken = token
  map = new mapboxgl.Map({
    container: 'map', // Container ID
    style: 'mapbox://styles/wvisokay/clz2wx9pd00m801pob1cpdixv',
    center: center,
    zoom: 1,
    maxZoom: 4,
    minZoom: 1,
    pitch: 10,
    maxBounds: [
      [-126.791110603, 24.91619],
      [-65.96466, 49.3577635769]
    ],
    attributionControl: false,
    // FitBoundsOptions: { padding: 100}
    cooperativeGestures: true
  })
  if (map) {
    map.dragRotate.disable()
    map.touchZoomRotate.disableRotation()
    map.fitBounds(
      [
        [-126.791110603, 24.91619],
        [-65.96466, 49.3577635769]
      ],
      { padding: { top: 0, bottom: 150, left: 150, right: 150 } }
    )
    map.loadImage(bgRed, (error, image) => {
      if (error) throw error;

      // Add the image to the map style.
      map.addImage("paisley-bg", image);
      map.addSource("louisiana", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            // These coordinates outline louisiana.
            coordinates: [
              [
                [-93.608485, 33.018527],
                [-91.16577, 33.002096],
                [-91.072662, 32.887081],
                [-91.143862, 32.843265],
                [-91.154816, 32.640618],
                [-91.006939, 32.514649],
                [-90.985031, 32.218894],
                [-91.105524, 31.988862],
                [-91.341032, 31.846462],
                [-91.401278, 31.621907],
                [-91.499863, 31.643815],
                [-91.516294, 31.27686],
                [-91.636787, 31.265906],
                [-91.565587, 31.068736],
                [-91.636787, 30.997536],
                [-89.747242, 30.997536],
                [-89.845827, 30.66892],
                [-89.681519, 30.449842],
                [-89.643181, 30.285534],
                [-89.522688, 30.181472],
                [-89.818443, 30.044549],
                [-89.84035, 29.945964],
                [-89.599365, 29.88024],
                [-89.495303, 30.039072],
                [-89.287179, 29.88024],
                [-89.30361, 29.754271],
                [-89.424103, 29.699501],
                [-89.648657, 29.748794],
                [-89.621273, 29.655686],
                [-89.69795, 29.513285],
                [-89.506257, 29.387316],
                [-89.199548, 29.348977],
                [-89.09001, 29.2011],
                [-89.002379, 29.179192],
                [-89.16121, 29.009407],
                [-89.336472, 29.042268],
                [-89.484349, 29.217531],
                [-89.851304, 29.310638],
                [-89.851304, 29.480424],
                [-90.032043, 29.425654],
                [-90.021089, 29.283254],
                [-90.103244, 29.151807],
                [-90.23469, 29.129899],
                [-90.333275, 29.277777],
                [-90.563307, 29.283254],
                [-90.645461, 29.129899],
                [-90.798815, 29.086084],
                [-90.963123, 29.179192],
                [-91.09457, 29.190146],
                [-91.220539, 29.436608],
                [-91.445094, 29.546147],
                [-91.532725, 29.529716],
                [-91.620356, 29.73784],
                [-91.883249, 29.710455],
                [-91.888726, 29.836425],
                [-92.146142, 29.715932],
                [-92.113281, 29.622824],
                [-92.31045, 29.535193],
                [-92.617159, 29.579009],
                [-92.97316, 29.715932],
                [-93.2251, 29.776178],
                [-93.767317, 29.726886],
                [-93.838517, 29.688547],
                [-93.926148, 29.787132],
                [-93.690639, 30.143133],
                [-93.767317, 30.334826],
                [-93.696116, 30.438888],
                [-93.728978, 30.575812],
                [-93.630393, 30.679874],
                [-93.526331, 30.93729],
                [-93.542762, 31.15089],
                [-93.816609, 31.556184],
                [-93.822086, 31.775262],
                [-94.041164, 31.994339],
                [-94.041164, 33.018527],
                [-93.608485, 33.018527],
              ],
            ],
          },
        },
      });

      // Add a new layer to visualize the polygon.
      map.addLayer({
        id: "louisiana",
        type: "fill",
        source: "louisiana", // reference the data source
        layout: {},
        paint: {
          "fill-pattern": "paisley-bg",
        },
      });
      // Add a black outline around the polygon.
      map.addLayer({
        id: "outline",
        type: "line",
        source: "louisiana",
        layout: {},
        paint: {
          "line-color": "#000",
          "line-width": 1,
        },
      });
    });
  }

  if (windowWidth < 768) {
    flyToMarker(32.3125119, -73.5797656, 4)
  }
  console.log('INIT MAP')
  createCargoMarker(windowWidth)
  createSBMarker(callback, that)
}

export const flyToMarker = (lat, lon, zoom) => {
  if (lat && lon) {
    const zoomLevel = zoom ? zoom : 4

    map.flyTo({
      zoom: zoomLevel,
      speed: 2,
      pitch: 10,
      center: [Number(lon), Number(lat)]
    })
  }
}

function zoomFix(marker) {
  map.on('zoom', () => {
    const zoomScale = map.getZoom()
    const svgElement = marker.getElement();
    if(svgElement) {
      if(zoomScale > 5.5) {
        svgElement.classList.add('marker-zoom')
      } else {
        svgElement.classList.remove('marker-zoom')
      }
    }
  });
}
function createCargoMarker (winWidth) {
  const center = winWidth < 768 ? [-69.5797656, 36.5125119] : [-69.5797656, 36.5125119]
  const geojson = {
    type: 'FeatureCollection',
    features: {
      type: 'Feature',
      properties: {
        iconSize: [100, 100]
      },
      geometry: {
        type: 'Point',
        coordinates: center
      }
    }
  }
  // Add markers to map

  const markerIcon = document.createElement('div')

  markerIcon.style.backgroundImage = `url("${pinCaptainCargo}")`
  markerIcon.style.width = `${geojson.features.properties.iconSize[0]}px`
  markerIcon.style.height = `${geojson.features.properties.iconSize[1]}px`
  markerIcon.style.lineHeight = `${geojson.features.properties.iconSize[1]}px`
  markerIcon.style.cursor = 'pointer'
  markerIcon.style.verticalAlign = 'middle'
  markerIcon.style.textAlign = 'center'

  const marker = new mapboxgl.Marker(markerIcon)
    .addClassName('event-cargo')
    .addClassName('event__pin')
    .setLngLat(geojson.features.geometry.coordinates)
    .addTo(map)

  marker.getElement().addEventListener(
    'click',
    (e) => {
      e.stopPropagation()
      window.location.href = '/followthecaptain/something-super'
    },
    false
  )
  markers.push(marker)
  zoomFix(marker)
}
function createSBMarker (callback, that) {
  const geojson = {
    type: 'FeatureCollection',
    features: {
      type: 'Feature',
      properties: {
        iconSize: [50, 50]
      },
      geometry: {
        type: 'Point',
        coordinates: [-91.8092678, 30.8195269]
      }
    }
  }
  // Add markers to map

  const markerIcon = document.createElement('div')

  markerIcon.style.backgroundImage = `url("${pinCaptainSB}")`
  markerIcon.style.width = `${geojson.features.properties.iconSize[0]}px`
  markerIcon.style.height = `${geojson.features.properties.iconSize[1]}px`
  markerIcon.style.lineHeight = `${geojson.features.properties.iconSize[1]}px`
  markerIcon.style.cursor = 'pointer'
  markerIcon.style.verticalAlign = 'middle'
  markerIcon.style.textAlign = 'center'

  let infoWindowTag = ''

  infoWindowTag += `<div class="backtomap"><img src=${btnBack} alt="Back to map" aria-label="Back to map" /><span class="sr-only">< BACK TO MAP</span></div>`
  infoWindowTag+= `<div class="team__wrap locationItem" id="team_rams">`
  
  infoWindowTag += `<div class="team__logo"><img src="${panelLogoCptNfl}" aria-label="Captain Morgan - An official partner of The NFL" /></div>`

  // infoWindowTag += `<div class="team__title locationAddress">${loc.teamname}</div>`
  infoWindowTag += `<div class="team__copy locDescription">Enter for a chance to win two tickets to Super Bowl LIX in New Orleans.</div>`

  infoWindowTag += `<div class="image__wrap">`
  infoWindowTag +=  `<img src="${sbScreen}" aria-hidden="true" />`
  infoWindowTag += '</div>'

  infoWindowTag += '<div class="locCta">'
  infoWindowTag += '<button class="btn__locCta" id="buttonCta" type="button" aria-label="Play the game">'
  infoWindowTag += `<img src="${btnPlay}" aria-hidden="true" />`
  infoWindowTag += '</button>'
  infoWindowTag += '</div>'
  infoWindowTag += '</div>'

  const popup = new mapboxgl.Popup().setHTML(infoWindowTag)
  const marker = new mapboxgl.Marker(markerIcon)
    .addClassName('event-sb')
    .addClassName('event__pin')
    .setLngLat(geojson.features.geometry.coordinates)
    .setPopup(popup)
    .addTo(map)

  marker.getElement().addEventListener(
    'click',
    (e) => {
      const currentPin = e.target
      if (currentPin.classList.contains('event__pin--disabled')) {
        e.stopPropagation()
        // E.preventDefault()
      }
    },
    false
  )
  markers.push(marker)
  zoomFix(marker)
  popup.on('open', () => {
    const mapDiv = document.getElementById('map')
    const nflPModal = document.getElementById('nflPModal')
    const filters = document.getElementById('filter')
    const buttonCta = document.getElementById('buttonCta')

    lockElements()
    $(
      '.mapboxgl-popup-close-button,.mapboxgl-marker.mapboxgl-marker-anchor-center.event__pin, .mapboxgl-canvas'
    ).on('click', () => {
      $('.mapboxgl-canvas-container .event__pin').each(function () {
        if ($(this).attr('aria-expanded') === true || $(this).attr('aria-expanded') === 'true') {
          $(this).attr('aria-expanded', 'false')
        }
      })
    })

    if (buttonCta) {
      buttonCta.addEventListener('click', () => {
        callback(true, that, 'card')
        $('.mapboxgl-popup-close-button').trigger('click')
      })
    }
    setTimeout(() => {
      const popupDiv = document.getElementsByClassName('mapboxgl-popup-content')[0]

      popup.addClassName('slideInOut')

      if (popupDiv) {
        popupDiv.scrollTop = 0
      }
      setTimeout(() => {
        mapDiv.classList.add('mapdiv__map--pushright')
        nflPModal.classList.add('z-top')
        filters.classList.add('z-top')

        flyToMarker(
          geojson.features.geometry.coordinates[1],
          geojson.features.geometry.coordinates[0],
          4
        )
      }, 300)
    }, 300)
  })

  popup.on('close', (e) => {
    const mapDiv = document.getElementById('map')
    const nflPModal = document.getElementById('nflPModal')
    const filters = document.getElementById('filter')
    unLockElements()

    mapDiv.classList.remove('mapdiv__map--pushright')
    nflPModal.classList.remove('z-top')
    filters.classList.remove('z-top')
    flyToMarker(37.8, -96)
  })
}

function createShipMarker(lat, lon, popupcontent, showrsvp, callback, that) {
  const windowWidth = document.documentElement.clientWidth
  const shipCoords = windowWidth < 768 ? [-76.5797656, 32.2125119] : [-76.5797656, 29.5125119]
  const geojson = {
    type: 'FeatureCollection',
    features: {
      type: 'Feature',
      properties: {
        iconSize: [75, 75]
      },
      geometry: {
        type: 'Point',
        coordinates: shipCoords
      }
    }
  }
  // Add markers to map

  const markerIcon = document.createElement('div')

  markerIcon.style.backgroundImage = `url("${pinCaptainShip}")`
  markerIcon.style.width = `${geojson.features.properties.iconSize[0]}px`
  markerIcon.style.height = `${geojson.features.properties.iconSize[1]}px`
  markerIcon.style.lineHeight = `${geojson.features.properties.iconSize[1]}px`
  markerIcon.style.cursor = 'pointer'
  markerIcon.style.verticalAlign = 'middle'
  markerIcon.style.textAlign = 'center'

  if (showrsvp) {
    popupcontent += '<div class="locCta">'
    popupcontent +=
      '<button class="btn__locCta" id="buttonCta" type="button" aria-label="Play the game">'
    popupcontent += `<img src="${btnPrizes}" aria-hidden="true" />`
    popupcontent += '</button>'
    popupcontent += '</div>'
  }

  const popup = new mapboxgl.Popup().setHTML(popupcontent)

  const marker = new mapboxgl.Marker(markerIcon)
    .addClassName('event-captain')
    .addClassName('event__pin')
    .setLngLat(geojson.features.geometry.coordinates)
    .setPopup(popup)
    .addTo(map)

  markers.push(marker)
  zoomFix(marker)
  // Sets the slide in animation for the popup
  popup.on('open', () => {
    const mapDiv = document.getElementById('map')
    const nflPModal = document.getElementById('nflPModal')
    const filters = document.getElementById('filter')
    const buttonCta = document.getElementById('buttonCta')

    if (buttonCta) {
      buttonCta.addEventListener('click', () => {
        callback(true, that, 'card')
        $('.mapboxgl-popup-close-button').trigger('click')
      })
    }
    setTimeout(() => {
      const popupDiv = document.getElementsByClassName('mapboxgl-popup')[0]

      popup.addClassName('slideInOut')

      if (popupDiv) {
        popupDiv.scrollTop = 0
      }
      setTimeout(() => {
        mapDiv.classList.add('mapdiv__map--pushright')
        nflPModal.classList.add('z-top')
        filters.classList.add('z-top')

        initGallery()

        flyToMarker(
          geojson.features.geometry.coordinates[1],
          geojson.features.geometry.coordinates[0],
          4
        )
      }, 300)
    }, 300)
  })

  popup.on('close', (e) => {
    const mapDiv = document.getElementById('map')
    const nflPModal = document.getElementById('nflPModal')
    const filters = document.getElementById('filter')

    mapDiv.classList.remove('mapdiv__map--pushright')
    nflPModal.classList.remove('z-top')
    filters.classList.remove('z-top')
    flyToMarker(37.8, -96)
  })

  const routeLayer = {
    id: 'route',
    type: 'line',
    paint: {
      'line-width': 3,
      'line-color': '#fff',
      'line-dasharray': [2, 2]
    },
    source: {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: [
            shipCoords,
            [-69.5797656, 36.5125119]
          ]
        }
      }
    }
  }

  map.addLayer(routeLayer)
}

export const createTeamMarkers = (loc, numbers, callback, that) => {
  const geojson = {
    type: 'FeatureCollection',
    features: {
      type: 'Feature',
      properties: {
        iconSize: [32, 32]
      },
      geometry: {
        type: 'Point',
        coordinates: [loc.loclon, loc.loclat]
      }
    }
  }
  // Add markers to map

  const markerIcon = document.createElement('div')

  markerIcon.style.backgroundImage = `url("${pinTeam}")`
  markerIcon.style.width = `${geojson.features.properties.iconSize[0]}px`
  markerIcon.style.height = `${geojson.features.properties.iconSize[1]}px`
  markerIcon.style.lineHeight = `${geojson.features.properties.iconSize[1]}px`
  markerIcon.style.cursor = 'pointer'
  markerIcon.style.verticalAlign = 'middle'
  markerIcon.style.textAlign = 'center'

  const panelImage = getTeamImage(loc.logolink)

  let infoWindowTag = ''

  infoWindowTag += `<div class="backtomap"><img src=${btnBack} alt="Back to map" aria-label="Back to map" /><span class="sr-only">< BACK TO MAP</span></div>`
  infoWindowTag+= `<div class="team__wrap locationItem" id="team_rams">`

  if (panelImage !== '') {
    infoWindowTag += `<div class="team__logo"><img src="${panelImage}" aria-label="Captain Morgan - An official partner of The ${loc.teamname}" /></div>`
  }

  infoWindowTag += `<div class="team__title locationAddress">${loc.teamname}</div>`
  infoWindowTag += `<div class="team__copy locDescription">Enter for a chance to win exclusive ${loc.teamname} prizing including:</div>`

  infoWindowTag += `<div class="team__prizes prizes">`

  infoWindowTag += `<div class="prizes__item prizes__item--full">`
  infoWindowTag += `<div class="prizes__icon"><img src=${iconXp} aria-hidden="true" /></div>`
  infoWindowTag += `<div class="prizes__title locationDate">${loc.prize1Title}</div>`
  infoWindowTag += `<div class="prizes__copy team__copy locDescription">${loc.prize1Copy}</div>`
  infoWindowTag += '</div>'

  if (loc.prize2Title !== '' && loc.prize2Copy !== '') {
    infoWindowTag += `<div class="prizes__item prizes__item--twothirds">`
    infoWindowTag += `<div class="prizes__icon"><img src=${iconVip} aria-hidden="true" /></div>`
    infoWindowTag += `<div class="prizes__title locationDate">${loc.prize2Title}</div>`
    infoWindowTag += `<div class="prizes__copy team__copy locDescription">${loc.prize2Copy}</div>`
    infoWindowTag += '</div>'
  }

  infoWindowTag += `<div class="prizes__item ${loc.prize2Title === '' && loc.prize2Copy === '' ? 'prizes__item--full' : 'prizes__item--onethird'}">`
  infoWindowTag += `<div class="prizes__icon"><img src=${iconTeam} aria-hidden="true" /></div>`
  infoWindowTag += `<div class="prizes__title locationDate">${loc.prize3Title}</div>`
  infoWindowTag += `<div class="prizes__copy team__copy locDescription">${loc.prize3Copy}</div>`
  infoWindowTag += '</div>'

  infoWindowTag += '</div>'

  infoWindowTag += `<div class="team__subcopy locSubCopy">Select the ${loc.teamname} from the dropdown to be entered to win</div>`

  infoWindowTag += '<div class="locCta">'
  infoWindowTag +=
    '<button class="btn__locCta" id="buttonCta" type="button" aria-label="Play the game">'
  infoWindowTag += `<img src="${btnPlay}" aria-hidden="true" />`
  infoWindowTag += '</button>'
  infoWindowTag += '</div>'
  infoWindowTag += '</div>'

  const popup = new mapboxgl.Popup().setHTML(infoWindowTag)
  let eventPassed = moment(loc.datestart).isBefore(new Date())

  const marker = new mapboxgl.Marker(markerIcon)
    .addClassName('event-team')
    .addClassName(`event-team--${loc.logolink}`)
    .addClassName('event__pin')
    // .addClassName(`${eventPassed ? 'event__pin--disabled' : 'event__pin--active'}`)
    .setLngLat(geojson.features.geometry.coordinates)
    .setPopup(popup)
    .addTo(map)
  // This prevents the popup from showing on a disabled pin
  marker.getElement().addEventListener(
    'click',
    (e) => {
      const currentPin = e.target
      if (currentPin.classList.contains('event__pin--disabled')) {
        e.stopPropagation()
        // E.preventDefault()
      }
    },
    false
  )
  markers.push(marker)
  zoomFix(marker)
  // Sets the slide in animation for the popup
  popup.on('open', () => {
    const mapDiv = document.getElementById('map')
    const nflPModal = document.getElementById('nflPModal')
    const filters = document.getElementById('filter')
    const buttonCta = document.getElementById('buttonCta')

    lockElements()
    $(
      '.mapboxgl-popup-close-button,.mapboxgl-marker.mapboxgl-marker-anchor-center.event__pin, .mapboxgl-canvas'
    ).on('click', () => {
      $('.mapboxgl-canvas-container .event__pin').each(function () {
        if ($(this).attr('aria-expanded') === true || $(this).attr('aria-expanded') === 'true') {
          $(this).attr('aria-expanded', 'false')
        }
      })
    })

    if (buttonCta) {
      buttonCta.addEventListener('click', () => {
        callback(true, that, 'card')
        $('.mapboxgl-popup-close-button').trigger('click')
      })
    }
    setTimeout(() => {
      const popupDiv = document.getElementsByClassName('mapboxgl-popup-content')[0]

      popup.addClassName('slideInOut')

      if (popupDiv) {
        popupDiv.scrollTop = 0
      }
      setTimeout(() => {
        mapDiv.classList.add('mapdiv__map--pushright')
        nflPModal.classList.add('z-top')
        filters.classList.add('z-top')

        flyToMarker(
          geojson.features.geometry.coordinates[1],
          geojson.features.geometry.coordinates[0],
          4
        )
      }, 300)
    }, 300)
  })

  popup.on('close', (e) => {
    const mapDiv = document.getElementById('map')
    const nflPModal = document.getElementById('nflPModal')
    const filters = document.getElementById('filter')
    unLockElements()

    mapDiv.classList.remove('mapdiv__map--pushright')
    nflPModal.classList.remove('z-top')
    filters.classList.remove('z-top')
    flyToMarker(37.8, -96)
  })
}

export const createMarkers = (loc, numbers, callback, that) => {
  const iconType = loc.loctype || 'ship'

  let useMapPin = pinShip

  if (loc.loctype === 'epic') {
    useMapPin = pinEpic
  }

  const geojson = {
    type: 'FeatureCollection',
    features: {
      type: 'Feature',
      properties: {
        iconSize: [32, 32]
      },
      geometry: {
        type: 'Point',
        coordinates: [Number(loc.loclon), Number(loc.loclat)]
      }
    }
  }

  // Variables for marker popups

  const locName = loc.locname ? loc.locname : ''
  const locAddress = loc.locaddress ? loc.locaddress : ''
  const useImage = loc.locimage && loc.locimage !== '' ? getLocationImage(loc.locimage) : ''
  const locDate = loc.datestart ? loc.datestart : ''
  const useDate = locDate !== '' ? moment(locDate).format('MMMM D, YYYY') : ''
  const useLink = loc.loclink !== '' ? loc.loclink : '/followthecaptain/rsvp'

  const useCopy = loc.loccopy ? loc.loccopy : ''

  const locState = loc.locstate ? loc.locstate : ''

  let dateLine = ''

  let locTitle = loc.locname ? loc.locname : locName

  if (iconType === 'epic') {
    dateLine = `${locAddress}, ${locState} | ${useDate}`
  } else {
    locTitle = `${locAddress}, ${locState}`
    dateLine = useDate
  }

  // Creates inner html for marker popup
  let infoWindowTag = ''

  infoWindowTag += `<div class="backtomap"><img src=${btnBack} alt="Back to map" aria-label="Back to map" /><span class="sr-only">< BACK TO MAP</span></div>`
  infoWindowTag += `<div id="location_${numbers}" class="locationItem" style="cursor: pointer;">`
  infoWindowTag += `<div class="locationAddress">${locTitle}</div>`
  infoWindowTag += `<div class="locationDate">${dateLine}${
    useLink === 'NONE' ? ' - Coming Soon' : ''
  }</div>`

  infoWindowTag += '<div class="locDescription">'
  if (useCopy === '') {
    infoWindowTag += `Legendary adventure awaits when we drop anchor for the ${locTitle} game. <br />`
    infoWindowTag += 'Come out and join the crew.'
  } else {
    infoWindowTag += useCopy
  }

  infoWindowTag += '</div>'

  if (iconType === 'epic') {
    infoWindowTag += `<div class="gallery">`
    infoWindowTag += `<div class="swiper-container gallery-slider">`
    infoWindowTag += `<div class="swiper-wrapper">`
    infoWindowTag += renderGalleryItems('gallery')
    infoWindowTag += `</div>`
    infoWindowTag += `</div>`
    infoWindowTag += `<div class="swiper-container gallery-thumbs">`
    infoWindowTag += `<div class="swiper-wrapper">`
    infoWindowTag += renderGalleryItems('thumbnail')
    infoWindowTag += `</div>`
    infoWindowTag += `<div class="swiper-button-prev"></div>`
    infoWindowTag += `<div class="swiper-button-next"></div>`
    infoWindowTag += `</div>`
    createShipMarker(
      geojson.features.geometry.coordinates[1],
      geojson.features.geometry.coordinates[0],
      infoWindowTag,
      loc.showrsvp,
      callback,
      that
    )
  }

  if (iconType === 'epic') {
    if (loc.showrsvp) {
      infoWindowTag += '<div class="locCta">'
      infoWindowTag += `<button class="btn__locCta btn--ga" id="buttonCta_${loc.id}" type="button" aria-label="Play the game" data-text="prizes" data-url="" data-path="" data-name="map modal">`
      infoWindowTag += `<img src="${btnPrizes}" aria-hidden="true" />`
      infoWindowTag += '</button>'
      infoWindowTag += '</div>'
    }
  } else {
    if (useLink !== 'NONE') {
      infoWindowTag += '<div class="locLink">'
      // InfoWindowTag += `<a class="btn btn--reset" href="${useLink}" target="_blank" rel="noreferrer" title="RSVP NOW">`
      infoWindowTag += `<a class="btn btn--reset btn--ga" href="${useLink}" target="_blank" rel="noreferrer" title="RSVP NOW" data-text="Rsvp now" data-url="https://www.captainmorgan.com${useLink}" data-path="${useLink}" data-name="${locTitle}">`
      infoWindowTag += `<img src=${btnRsvp} aria-label="RSVP now" />`
      infoWindowTag += '<span class="sr-only">RSVP NOW</span></a>'
      infoWindowTag += '</div>'
    }

    if (useImage !== '') {
      infoWindowTag += '<div class="locSubCopy padbot">Come out and join the crew</div>'
      infoWindowTag += `<div class="image__wrap"><img src=${useImage} alt="Come out and join the crew" /></div>`
    }
    infoWindowTag += '<div class="locSubCopy">Everywhere we\'re headed:</div>'
    infoWindowTag += locationsOutput
  }
  infoWindowTag += '</div>'

  // Add markers to map
  const markerIcon = document.createElement('div')

  markerIcon.style.backgroundImage = `url("${useMapPin}")`
  markerIcon.style.width = `${geojson.features.properties.iconSize[0]}px`
  markerIcon.style.height = `${geojson.features.properties.iconSize[1]}px`
  markerIcon.style.lineHeight = `${geojson.features.properties.iconSize[1]}px`
  markerIcon.style.cursor = 'pointer'
  markerIcon.style.verticalAlign = 'middle'
  markerIcon.style.textAlign = 'center'

  const popup = new mapboxgl.Popup().setHTML(infoWindowTag)
  // Push each marker into a global array
  // So that they can can be deleted later on
  let eventPassed = moment(locDate).isBefore(new Date())

  // if (iconType === 'epic') {
  //   eventPassed = false
  // }
  const marker = new mapboxgl.Marker(markerIcon)
    .addClassName(`event-${iconType}`)
    .addClassName('event__pin')
    .addClassName(`${(eventPassed && iconType !== 'epic') ? 'event__pin--disabled' : 'event__pin--active'}`)
    .addClassName(`${eventPassed ? 'event-passed' : 'event-running'}`)
    .setLngLat(geojson.features.geometry.coordinates)
    .setPopup(popup)
    .addTo(map)

  // This prevents the popup from showing on a disabled pin
  marker.getElement().addEventListener(
    'click',
    (e) => {
      const currentPin = e.target

      if (currentPin.classList.contains('event__pin--disabled')) {
        e.stopPropagation()
        // E.preventDefault()
      }
    },
    false
  )
  markers.push(marker)
  zoomFix(marker)
  // Sets the slide in animation for the popup
  popup.on('open', () => {
    const mapDiv = document.getElementById('map')
    const nflPModal = document.getElementById('nflPModal')
    const filters = document.getElementById('filter')
    const buttonCta = document.getElementById(`buttonCta_${loc.id}`)

    lockElements()

    $(
      '.mapboxgl-popup-close-button,.mapboxgl-marker.mapboxgl-marker-anchor-center.event__pin, .mapboxgl-canvas'
    ).on('click', () => {
      $('.mapboxgl-canvas-container .event__pin').each(function () {
        if ($(this).attr('aria-expanded') === true || $(this).attr('aria-expanded') === 'true') {
          $(this).attr('aria-expanded', 'false')
        }
      })
    })

    if (buttonCta) {
      buttonCta.addEventListener('click', () => {
        callback(true, that, 'card')
        $('.mapboxgl-popup-close-button').trigger('click')
      })
    }
    setTimeout(() => {
      const popupDiv = document.getElementsByClassName('mapboxgl-popup')[0]
      popup.addClassName('slideInOut')
      if (popupDiv) {
        popupDiv.scrollTop = 0
      }

      setTimeout(() => {
        mapDiv.classList.add('mapdiv__map--pushright')

        nflPModal.classList.add('z-top')
        filters.classList.add('z-top')

        if (loc.loctype === 'epic') {
          initGallery()
          $('.event-captain').addClass('flag-triggered')
        }

        flyToMarker(
          geojson.features.geometry.coordinates[1],
          geojson.features.geometry.coordinates[0],
          loc.loctype === 'epic' ? 4 : 6
        )
      }, 300)
    }, 300)
  })

  popup.on('close', (e) => {
    const mapDiv = document.getElementById('map')
    const nflPModal = document.getElementById('nflPModal')
    const filters = document.getElementById('filter')

    unLockElements()

    if (loc.loctype === 'epic') {
      $('.event-captain').removeClass('flag-triggered')
    }

    mapDiv.classList.remove('mapdiv__map--pushright')
    nflPModal.classList.remove('z-top')
    filters.classList.remove('z-top')
    flyToMarker(37.8, -96)
  })
}

function initGallery() {
  const gallerySlider = document.getElementsByClassName('gallery-slider')

  if (gallerySlider.length > 0) {
    var thumbs = new Swiper ('.gallery-thumbs', {
      slidesPerView: 'auto',
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      loop: true,
      spaceBetween: 10
    });
    var slider = new Swiper ('.gallery-slider', {
      slidesPerView: 1,
      // centeredSlides: true,
      loop: true,
      loopedSlides: 6,
      navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
      },
      thumbs: {
        swiper: thumbs
      }
    });
  }
}

function renderGalleryItems(type) {
  let items = ''

  if (type === 'gallery') {
    galleryData && galleryData.length > 0 && galleryData.map((item, key) => {
      items += `<div key=${`gallery_item_${key}`}  class="swiper-slide" data-index=${key} data-status=${key === 0 ? 'active' : "unknown"}>
          <div class="content-wrap">
            <img src=${item.image} alt=${item.imagealt} />
          </div>
        </div>`
    })
  } else if (type === 'thumbnail') {
    galleryData && galleryData.length > 0 && galleryData.forEach((item, key) => {
      items += `<div key=${`gallery_thumb_item_${key}`}  class="swiper-slide" data-index=${key} data-status=${key === 0 ? 'active' : "unknown"}>
          <div class="content-wrap">
            <img src=${item.image} alt=${item.imagealt} />
          </div>
        </div>`
    })
  }

  return items
}

export const mapData = [
    {
        "id": 6,
        "locname": "Captain's Cruise with Cruz",
        "locaddress": "New York",
        "locstate": "NY",
        "loczipcode": "",
        "loctype": "epic",
        "locflagcolor": "",
        "loclat": "40.7368248",
        "loclon": "-73.9276993",
        "locimage": "",
        "loccopy": "Captain Morgan set sail with Victor Cruz on an Epic Adventure. Click the photos to view highlights.",
        "loclink": "",
        "datestart": "2024-09-26",
        "dateend": "2024-09-26",
        "active": 1,
        "showrsvp": 0
    },
    {
        "id": 7,
        "locname": "Georgia v Alabama Game",
        "locaddress": "Tuscaloosa",
        "locstate": "AL",
        "loczipcode": "",
        "loctype": "ship",
        "locflagcolor": "",
        "loclat": "33.2869979",
        "loclon": "-87.5345087",
        "locimage": "image-location-2",
        "loccopy": "A legendary adventure is dropping anchor!",
        "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-tuscaloosa-tuscaloosa",
        "datestart": "2024-09-28",
        "dateend": "2024-09-28",
        "active": 1,
        "showrsvp": 1
    },
    {
        "id": 8,
        "locname": "USC v Minnesota Game",
        "locaddress": "Minneapolis",
        "locstate": "MN",
        "loczipcode": "",
        "loctype": "ship",
        "locflagcolor": "",
        "loclat": "44.9707888",
        "loclon": "-93.343878",
        "locimage": "image-location-1",
        "loccopy": "A legendary adventure is dropping anchor!",
        "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-minneapolis-minneapolis",
        "datestart": "2024-10-05",
        "dateend": "2024-10-05",
        "active": 1,
        "showrsvp": 1
    },
    {
        "id": 9,
        "locname": "UCLA v Penn State Game",
        "locaddress": "State College",
        "locstate": "PA",
        "loczipcode": "",
        "loctype": "ship",
        "locflagcolor": "",
        "loclat": "40.7880302",
        "loclon": "-77.8628712",
        "locimage": "image-location-2",
        "loccopy": "A legendary adventure is dropping anchor!",
        "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-penn-state",
        "datestart": "2024-10-05",
        "dateend": "2024-10-05",
        "active": 1,
        "showrsvp": 1
    },
    {
        "id": 10,
        "locname": "Purdue v Illinois Game",
        "locaddress": "Champaign",
        "locstate": "IL",
        "loczipcode": "",
        "loctype": "ship",
        "locflagcolor": "",
        "loclat": "40.1146894",
        "loclon": "-88.3183088",
        "locimage": "image-location-1",
        "loccopy": "A legendary adventure is dropping anchor!",
        "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-champaign-champaign",
        "datestart": "2024-10-12",
        "dateend": "2024-10-12",
        "active": 1,
        "showrsvp": 1
    },
    {
        "id": 11,
        "locname": "Iowa v Michigan St Game",
        "locaddress": "East Lansing",
        "locstate": "MI",
        "loczipcode": "",
        "loctype": "ship",
        "locflagcolor": "",
        "loclat": "42.7087372",
        "loclon": "-84.6006268",
        "locimage": "image-location-2",
        "loccopy": "A legendary adventure is dropping anchor!",
        "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-east-lansing-east-lansing",
        "datestart": "2024-10-19",
        "dateend": "2024-10-19",
        "active": 1,
        "showrsvp": 1
    },
    {
        "id": 12,
        "locname": "Michigan St v Michigan Game",
        "locaddress": "Ann Arbor",
        "locstate": "MI",
        "loczipcode": "",
        "loctype": "ship",
        "locflagcolor": "",
        "loclat": "42.2733765",
        "loclon": "-83.7788887",
        "locimage": "image-location-1",
        "loccopy": "A legendary adventure is dropping anchor!",
        "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-ann-arbor-ann-arbor-7d56eada-65d6-4843-bb5f-8809dc0f2f6c",
        "datestart": "2024-10-26",
        "dateend": "2024-10-26",
        "active": 1,
        "showrsvp": 1
    },
    {
        "id": 14,
        "locname": "Florida v Georgia Game",
        "locaddress": "Jacksonville",
        "locstate": "FL",
        "loczipcode": "",
        "loctype": "ship",
        "locflagcolor": "",
        "loclat": "30.345183",
        "loclon": "-81.8479115",
        "locimage": "image-location-1",
        "loccopy": "A legendary adventure is dropping anchor!",
        "loclink": "/followthecaptain/rsvp#id=copy-of-captain-morgan-tailgate-tour-jacksonville-jacksonville",
        "datestart": "2024-11-02",
        "dateend": "2024-11-02",
        "active": 1,
        "showrsvp": 1
    },
    {
        "id": 15,
        "locname": "Purdue v Ohio St Game",
        "locaddress": "Columbus",
        "locstate": "OH",
        "loczipcode": "",
        "loctype": "ship",
        "locflagcolor": "",
        "loclat": "39.9830978",
        "loclon": "-83.1556326",
        "locimage": "image-location-2",
        "loccopy": "A legendary adventure is dropping anchor!",
        "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-columbus-columbus",
        "datestart": "2024-11-09",
        "dateend": "2024-11-09",
        "active": 1,
        "showrsvp": 1
    },
    {
        "id": 17,
        "locname": "LSU v FL Game",
        "locaddress": "Gainesville",
        "locstate": "FL",
        "loczipcode": "",
        "loctype": "ship",
        "locflagcolor": "",
        "loclat": "29.6818691",
        "loclon": "-82.2760401",
        "locimage": "image-location-1",
        "loccopy": "A legendary adventure is dropping anchor!",
        "loclink": "/followthecaptain/rsvp#id=copy-of-captain-morgan-tailgate-tour-gainesville-gainesville",
        "datestart": "2024-11-16",
        "dateend": "2024-11-16",
        "active": 1,
        "showrsvp": 1
    },
    {
        "id": 18,
        "locname": "Virgina v Notre Dame Game",
        "locaddress": "Holy Cross",
        "locstate": "IN",
        "loczipcode": "",
        "loctype": "ship",
        "locflagcolor": "",
        "loclat": "41.7008923",
        "loclon": "-86.2574938",
        "locimage": "image-location-2",
        "loccopy": "A legendary adventure is dropping anchor!",
        "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-notre-dame-notre-dame",
        "datestart": "2024-11-16",
        "dateend": "2024-11-16",
        "active": 1,
        "showrsvp": 1
    },
    {
        "id": 19,
        "locname": "Texas AM v Auburn Game",
        "locaddress": "Auburn",
        "locstate": "AL",
        "loczipcode": "",
        "loctype": "ship",
        "locflagcolor": "",
        "loclat": "32.6280206",
        "loclon": "-85.5709313",
        "locimage": "image-location-1",
        "loccopy": "A legendary adventure is dropping anchor!",
        "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-auburn-auburn-02c86629-b7cd-4097-a147-b4f4cc11d059",
        "datestart": "2024-11-23",
        "dateend": "2024-11-23",
        "active": 1,
        "showrsvp": 1
    },
    {
        "id": 20,
        "locname": "Texas v Texas AM Game",
        "locaddress": "College Station",
        "locstate": "TX",
        "loczipcode": "",
        "loctype": "ship",
        "locflagcolor": "",
        "loclat": "30.5909183",
        "loclon": "-96.3328604",
        "locimage": "image-location-2",
        "loccopy": "A legendary adventure is dropping anchor!",
        "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-texas-am-college-station",
        "datestart": "2024-11-30",
        "dateend": "2024-11-30",
        "active": 1,
        "showrsvp": 1
    },
    {
        "id": 21,
        "locname": "Arizona St v Arizona Game",
        "locaddress": " Tucson",
        "locstate": "AZ",
        "loczipcode": "",
        "loctype": "ship",
        "locflagcolor": "",
        "loclat": "32.1560286",
        "loclon": "-110.9662048",
        "locimage": "image-location-1",
        "loccopy": "A legendary adventure is dropping anchor!",
        "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-az-tucson",
        "datestart": "2024-11-30",
        "dateend": "2024-11-30",
        "active": 1,
        "showrsvp": 1
    },
    {
        "id": 22,
        "locname": "Gasparilla",
        "locaddress": "Tampa",
        "locstate": "FL (Gasparilla)",
        "loczipcode": "",
        "loctype": "ship",
        "locflagcolor": "",
        "loclat": "27.9946096",
        "loclon": "-82.5366804",
        "locimage": "image-location-2",
        "loccopy": "We're bringing adventure to the legendary pirate's festival, Gasparilla!",
        "loclink": "",
        "datestart": "2025-01-25",
        "dateend": "2025-01-25",
        "active": 1,
        "showrsvp": 1
    },
    {
        "id": 24,
        "locname": "Mardi Gras",
        "locaddress": "New Orleans",
        "locstate": "LA (Mardi Gras)",
        "loczipcode": "",
        "loctype": "ship",
        "locflagcolor": "",
        "loclat": "30.0331194",
        "loclon": "-89.9649633",
        "locimage": "image-location-1",
        "loccopy": "We're bringing a legendary adventure to Mardi Gras!",
        "loclink": "",
        "datestart": "2025-02-27",
        "dateend": "2025-03-04",
        "active": 1,
        "showrsvp": 1
    }
]

export const teamData = [
    {
        "id": 1,
        "teamname": "Los Angeles Rams",
        "logolink": "losangelesrams",
        "prize1Title": "AWAY GAME EXPERIENCE FOR 2",
        "prize1Copy": "Win the ultimate gameday adventure - a trip for two to New York to watch the Rams take on the New York Jets on December 22nd. All accomodations and transportation included.",
        "prize2Title": "Los Angeles Rams Home VIP Gameday",
        "prize2Copy": "Two all-inclusive club tickets and sideline passes to a Rams home game on December 8th.",
        "prize3Title": "TEAM SWAG",
        "prize3Copy": "Los Angeles Rams swag and autographed memorabillia.",
        "datestart": "2024-11-04",
        "loclat": "36.3174899",
        "loclon": "-119.372877",
        "active": 1,
        "showrsvp": 1
    },
    {
        "id": 2,
        "teamname": "Los Angeles Chargers",
        "logolink": "losangeleschargers",
        "prize1Title": "LA Chargers Private SoFi Stadium Experience for 8",
        "prize1Copy": "Win an exclusive fan experience - a private SoFi Stadium tour for eight with exclusive locker room & field access!",
        "prize2Title": "Los Angeles Chargers VIP Gameday",
        "prize2Copy": "Two club tickets and sideline passes to a Chargers home game on December 15th.",
        "prize3Title": "TEAM SWAG",
        "prize3Copy": "Los Angeles Chargers authentic memorabilia and on-field jersey.",
        "datestart": "2024-11-04",
        "loclat": "34.9070649",
        "loclon": "-116.8568383",
        "active": 1,
        "showrsvp": 1
    },
    {
        "id": 3,
        "teamname": "New York Giants",
        "logolink": "newyorkgiants",
        "prize1Title": "New York Giants Facility Experience for 4",
        "prize1Copy": "Win an exclusive fan experience - a behind-the-scenes Giants TV & Facility Tour, plus lunch where the players eat.",
        "prize2Title": "New York Giants Home VIP Gameday",
        "prize2Copy": "Two club tickets and tunnel passes to a Giants home game on December 8th.",
        "prize3Title": "TEAM SWAG",
        "prize3Copy": "New York Giants autographed football, autographed jersey and autographed photo.",
        "datestart": "2024-11-04",
        "loclat": "41.8233065",
        "loclon": "-74.2640706",
        "active": 1,
        "showrsvp": 1
    },
    {
        "id": 4,
        "teamname": "New York Jets",
        "logolink": "newyorkjets",
        "prize1Title": "NY Jets MetLife Stadium Challenge Experience for 15",
        "prize1Copy": "Win an exclusive fan adventure - a Skills Challenge on the field where you and your crew  will be put to the test with multiple fun drills on November 21st!",
        "prize2Title": "New York Jets Home VIP Gameday",
        "prize2Copy": "Two club tickets and endzone access passes to a Jets home game on December 1st.",
        "prize3Title": "TEAM SWAG",
        "prize3Copy": "New York Jets merchandise and autographed memorabilia.",
        "datestart": "2024-11-04",
        "loclat": "40.5960877",
        "loclon": "-74.6412434",
        "active": 1,
        "showrsvp": 1
    },
    {
        "id": 5,
        "teamname": "Dallas Cowboys",
        "logolink": "dallascowboys",
        "prize1Title": "Exclusive meet and greet with a Dallas Cowboy Legend for 4",
        "prize1Copy": "Win a star-studded fan experience - a Happy Hour with a Dallas Cowboy Legend, Tour of the Star, and being picked up in the Official Dallas Cowboys Luxury Bus on December 4th!",
        "prize2Title": "Dallas Cowboys Home VIP Gameday",
        "prize2Copy": "Two club level tickets, passes to a field club in the Stadium, and high five-line access to a Cowboys home game on December 22nd.",
        "prize3Title": "TEAM SWAG",
        "prize3Copy": "Dallas Cowboys signed memorabilia and authentic collectibles.",
        "datestart": "2024-11-04",
        "loclat": "32.7480011",
        "loclon": "-97.0959914",
        "active": 1,
        "showrsvp": 1
    },
    {
        "id": 6,
        "teamname": "Arizona Cardinals ",
        "logolink": "arizonacardinals",
        "prize1Title": "Arizona Cardinals Party Loft Ticket Experience for 6",
        "prize1Copy": "Win the ultimate fan experience - an all-inclusive Party Loft Experience. The perfect area for upscale entertainment on your December 8th gameday.",
        "prize2Title": "Arizona Cardinals Home VIP Gameday",
        "prize2Copy": "Two club tickets to a Cardinals home game on December 8th.",
        "prize3Title": "TEAM SWAG",
        "prize3Copy": "Arizona Cardinals swag and unique memoribilia.",
        "datestart": "2024-11-04",
        "loclat": "33.5276291",
        "loclon": "-112.2651342",
        "active": 1,
        "showrsvp": 1
    },
    {
        "id": 7,
        "teamname": "Milwaukee Bucks",
        "logolink": "milwaukeebucks",
        "prize1Title": "Milwaukee Bucks Post Game Access Experience for 4",
        "prize1Copy": "Win an epic gameday set up - F&B inclusive tickets & post game court access!",
        "prize2Title": "Milwaukee Bucks Pre Game Warm Up Experience for 4",
        "prize2Copy": "Win an epic gameday set up - F&B inclusive tickets with courtside access for pre-game warm-up experience!",
        "prize3Title": "TEAM SWAG",
        "prize3Copy": "Milwaukee Bucks themed basketball, autographed jersey, and retro throwback jersey.",
        "datestart": "2024-12-16",
        "loclat": "44.5307385",
        "loclon": "-89.5921829",
        "active": 1,
        "showrsvp": 1
    },
    {
        "id": 8,
        "teamname": "Chicago Bulls",
        "logolink": "chicagobulls",
        "prize1Title": "Chicago Bulls Player Tunnel Experience for 4",
        "prize1Copy": "Win an iconic fan experience - a chance to experience walking through the Player Tunnel & gameday tickets!",
        "prize2Title": "",
        "prize2Copy": "",
        "prize3Title": "TEAM SWAG",
        "prize3Copy": "Chicago Bulls authentic memorabilia and autographed collectibles.",
        "datestart": "2024-12-16",
        "loclat": "39.7638083",
        "loclon": "-89.7532316",
        "active": 1,
        "showrsvp": 1
    },
    {
        "id": 9,
        "teamname": "Minnesota Wild",
        "logolink": "minnesotawild",
        "prize1Title": "Minnesota Wild Zamboni Ticket Experience for 4",
        "prize1Copy": "Win an epic fan experience - Hop on the Zamboni & receive Custom One Club tickets!",
        "prize2Title": "",
        "prize2Copy": "",
        "prize3Title": "TEAM SWAG",
        "prize3Copy": "Minnesota Wild autographed stick, autographed jersey, and autographed puck.",
        "datestart": "2024-12-16",
        "loclat": "46.1618265",
        "loclon": "-95.0440957",
        "active": 1,
        "showrsvp": 1
    },
    {
        "id": 10,
        "teamname": "Chicago Blackhawks",
        "logolink": "chicagoblackhawks",
        "prize1Title": "Chicago Blackhawks Penalty Box Photo Experience for 4",
        "prize1Copy": "Win an epic fan experience - lower level game tickets & the chance to hang in the Penalty Box for an epic photo!",
        "prize2Title": "",
        "prize2Copy": "",
        "prize3Title": "TEAM SWAG",
        "prize3Copy": "Chicago Blackhawks autographed stick and memorabilia.",
        "datestart": "2024-12-16",
        "loclat": "41.5444",
        "loclon": "-89.1398853",
        "active": 1,
        "showrsvp": 1
    },
    {
        "id": 11,
        "teamname": "Pittsburgh Penguins",
        "logolink": "pittsburghpenguins",
        "prize1Title": "Pittsburgh Penguins Zamboni Ticket Experience for 2",
        "prize1Copy": "Win an epic fan experience - Feburary 27th gameday tickets, take home 2 signed player photos and one guest gets to hop on the Zamboni!",
        "prize2Title": "",
        "prize2Copy": "",
        "prize3Title": "TEAM SWAG",
        "prize3Copy": "Pittsburgh Penguins game used stick, game used puck, and stitched jersey.",
        "datestart": "2024-12-16",
        "loclat": "40.4393138",
        "loclon": "-79.9921278",
        "active": 1,
        "showrsvp": 1
    }
]
