import "./chat-widget.scss";
import React, { useEffect, useState } from "react";
import {Helmet} from 'react-helmet'

import $ from 'jquery' // Import jQuery if not already available


import * as utils from 'global/utils/utils'

import logoWhite from 'global/images/halloween2023/logo_white.png'

const ChatWidget = (props) => {
  const [chatWindowReady, setChatWindowReady] = useState(false)
  const [chatBtnReady, setChatBtnReady] = useState(false)
  const [isCPModalOpen, setCPModalOpen] = useState(false) // Cookie preferences modal
  const [chatWindowOpen, setChatWindowOpen] = useState(false)
  const [chatCloseBtn, setChatCloseBtn] = useState(null)
  const [activeConsentGroups, setActiveConsentGroups] = useState([])
  const [isVCPModalOpen, setVCPModalOpen] = useState(false) // Cookie preferences modal
  const [vcpModalWasShown, setVCPModalWasShown] = useState(false)
  const [previousCookie, setPreviousCookie] = useState(null)

  function toggleWindow(e) {
    console.log('toggle window')
    window.chatWidget.toggle()
  }

  const chatAIPermitted = (activeGroups) => {
    if (!activeGroups) {
      console.log('Onetrust Active Groups are undefined')
      return false
    }
    const hasActiveGroups = activeGroups.includes('C0002') && activeGroups.includes('C0004');
    return hasActiveGroups
  }

  const getOptanonConsentGroups = () => {
    let documentCookie = document.cookie
    if (documentCookie === '') {
      documentCookie = 'diageo-gateway=%7B%22pass%22%3Atrue%2C%22country%22%3A%22US%22%2C%22language%22%3A%22uen%22%2C%22domain%22%3A%22diageoplatform.com%22%2C%22from_sso%22%3Afalse%2C%22remembered%22%3Afalse%7D; _ga=GA1.1.1011946047.1729012672; _gcl_au=1.1.1717852898.1729012673; OptanonAlertBoxClosed=2024-10-15T17:17:54.341Z; _ga_F2T647MNF2=GS1.1.1729012672.1.1.1729012699.0.0.0; _ga_XL9T2Y21E1=GS1.1.1729012672.1.1.1729012713.0.0.0; OptanonConsent=isGpcEnabled=0&datestamp=Tue+Oct+15+2024+13%3A18%3A34+GMT-0400+(Eastern+Daylight+Time)&version=202403.2.0&browserGpcFlag=0&isIABGlobal=false&hosts=&consentId=15feca8c-588b-4fdf-b2bf-f26060e35cc7&interactionCount=2&isAnonUser=1&landingPath=NotLandingPage&groups=C0008%3A1%2CC0004%3A0%2CC0001%3A1%2CC0002%3A0%2CC0003%3A1&intType=6&geolocation=US%3BNJ&AwaitingReconsent=false'
    }
    const optanonConsent = documentCookie
      .split('; ')
      .find((row) => row.startsWith('OptanonConsent='))
      
    if (!optanonConsent) return {}

    const groupsString = optanonConsent.split('; ')[0]
    const groupsArray = groupsString
      .split('&')
      .find((part) => part.startsWith('groups='))
      ?.split('=')[1]
    if (!groupsArray) return {}

    const formattedGroups = groupsArray.split('%2C').map((group) => {
      const [key, value] = group.split('%3A')
      return { key, value: value === '1' ? 'true' : 'false' }
    })

    setActiveConsentGroups(formattedGroups)
  }

  const checkCookie = () => {
    let currentCookie = document.cookie

    if (currentCookie !== previousCookie) {
      getOptanonConsentGroups()
      setPreviousCookie(currentCookie)
    }
  }

  const checkChatPermissions = () => {
    const isChatPermitted = chatAIPermitted(window.OnetrustActiveGroups)
    let isConsented = true
    if (!isChatPermitted) {
      activeConsentGroups.forEach( group => {
        if (group.value === 'false') {
          isConsented = false
        }
      })      
    }
    
    if (!isConsented) {
      setChatBtnReady(false)
    } else {
      setChatBtnReady(true)
    }
  }

  useEffect(() => {
    const wdw = window

    document.addEventListener("load", waitForElem());
    const checkCookieInterval = wdw.setInterval(checkCookie, 500);
    
    return () => clearInterval(checkCookieInterval)
    return () => {
      document.removeEventListener("load", waitForElem());
    }
  }, [])

  const chatButtonClickHandler = (event) => {
    const targetId = event.target.id

    if (targetId === 'chat-button-blocker') {
      setCPModalOpen(true)
    }
  }

  const closeCookieModal = (event) => {
    setCPModalOpen(false)
  }

  const openOneTrustModal = (event) => {
    event.preventDefault()
    closeCookieModal(event)
    document.getElementById("dg_COOKIE_LINK").click()
  }
  const waitForElem = () => {
    const chatBtn = document.getElementsByClassName('chat-button-container')
    if (chatBtn !== null && chatBtn.length > 0) {

      const newDiv = document.createElement("div")
      newDiv.classList.add('chat-button-blocker')
      newDiv.setAttribute('id', 'chat-button-blocker');
      chatBtn[0].prepend(newDiv)

      chatBtn[0].addEventListener('click', (e) => chatButtonClickHandler(e))
      setChatBtnReady(true)
    } else {
      setTimeout( () => { waitForElem(), 500})
    }
  }
  useEffect(() => {
    const chatBtn = document.getElementById('chat-button-blocker')

    if (chatBtn) {
      if (!chatBtnReady) {      
        chatBtn.classList.add('disabled')
      } else {
        chatBtn.classList.remove('disabled')
      }
    }
  }, [chatBtnReady])

  useEffect(() => {
    checkChatPermissions()    
  }, [activeConsentGroups])

  return (
    <>
      <Helmet>
        <script 
         defer
         src="https://captainmorganai.diageoai.com/widgetEntry.js"
         data-use-default-button="true"
         data-is-draggable="true" />
      </Helmet>

      {isCPModalOpen ? (
        <div className="modalCookies background" id="modalCookies" tabIndex="1">
          <div className="modalCookies__container">
            <div className="modalCookies__wrap">
              <button
                aria-label="close"
                className="modalCookies__close btn btn--close"
                onClick={(e) => closeCookieModal(e)}
              />
              <h3 className="modalCookies__title">Update Cookie Preferences</h3>
              <div className="modalCookies__copy">
                Advertising and Analytics cookies are required for the Virtual AI Concierge to function. Would you like to update your cookie preferences?
              </div>
              <div className="modalCookies__cta">
                <button
                  aria-label="Cancel"
                  className="btn btn--modalCookies"
                  onClick={(e) => closeCookieModal(e)}
                >
                  CANCEL
                </button>
                <button
                  aria-label="Update"
                  className="btn btn--modalCookies"                  
                  onClick={(e) => openOneTrustModal(e)}
                >
                  UPDATE
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
        
      {/*<div className='chatWidget'>
        <button className='chatWidget-toggle' onClick={(e) => toggleWindow()}>toggle window</button>
      </div>*/}
    </>
  );
};

export default ChatWidget;
