import "./event-card.scss";
import {getIconName, getPrefix} from "global/utils/fontAwesomeLookup";
import Cta from "../../../components/atoms/cta";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

const getIcon = (icon) => {

    if (!icon) {

        return null;

    }

    const iconName = getIconName(icon);

    return <FontAwesomeIcon
        icon={{
            iconName,
            prefix: getPrefix(iconName),
        }}
    />;

};

const EventCard = (props) => {

    const {
        Title,
        Description,
        FromTime,
        StartDate,
        Location,
        City,
        CtaCollection,
    } = fieldsArrayToKeyValueHash(props.event.fields);

    if (!Title) {

        return false;

    }

    return (
        <article className="event-card">
            <h4 className="event-card__header">
                <span className="event-card__title">
                    {Title}
                </span>
                <span className="event-card__header-summary">
                    {
                        City &&
                        <span className="event-card__city">
                            <span className="event-card__city-icon">
                                {getIcon("map-marker-alt")}
                            </span>
                            <span className="event-card__city-text">
                                {City}
                            </span>
                        </span>
                    }
                    {
                        StartDate &&
                        <span className="event-card__start-date">
                            {StartDate}
                        </span>
                    }
                </span>
            </h4>
            <div className="event-card__content">
                {
                    Description &&
                        <p className="event-card__description">
                            {Description}
                        </p>
                }
                {
                    FromTime &&
                        <p className="event-card__from-time">
                            {FromTime}
                        </p>
                }
                {
                    Location &&
                        <p className="event-card__address">
                            {Location}
                        </p>
                }
            </div>
            {
                CtaCollection &&
                    <div className="event-card__ctas">
                        {
                            CtaCollection.map((cta, index) => <Cta
                                key={`card-${index}`}
                                cta={cta}
                                version="tertiary"
                                varient="ghost"
                            />)
                        }
                    </div>
            }
        </article>

    );

};

export default EventCard;
