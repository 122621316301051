import "./product-list.scss";
import React, {Component} from "react";

export default class Filters extends Component {

    state = {selectedFilter: 0};

    render () {

        if (!this.props.filters) {

            return null;

        }
        const filters = this.props.filters.filter(function(n){return n; })
        return (
            <div className="product-list__filters">
                <ul className="product-list__filters-items">
                    {
                        filters.map((filter, index) => <li
                            key={`product-${index}`}
                            className="product-list__filters-item"
                        >
                            <button
                                className={
                                    index === this.state.selectedFilter
                                        ? "product-list__filters-action--selected"
                                        : "product-list__filters-action"
                                }
                                onClick={
                                    () => {

                                        this.props.updateFilter(index);
                                        this.setState({selectedFilter: index});

                                    }
                                }
                            >
                                {filter}
                            </button>
                        </li>)
                    }
                </ul>
            </div>
        );

    }

}
