import "./recipe-card.scss";
import {BlockingLink} from "global/utils/blocking-link";
import Lazy from "global/utils/lazy";
import React from "react";
import sanitise from "global/utils/sanitise";

const RecipeCard = (props) => {

    const {
        copy,
        heading,
        image,
        url,
        newWindow,
    } = props;

    if (!heading || !url) {

        return null;

    }

    if (global.window) {
        // Not sure why this was here, but MediaMonks complained about it being triggered.
        // Global.window.dataLayer = global.window.dataLayer || [];
        // Global.window.dataLayer.push({
        //     "ecommerce": {
        //         "impressions": [
        //             {
        //                 "brand": "Product Brand",
        //                 "category": "Product Category",
        //                 "id": "ABC123",
        //                 "list": "Product list name",
        //                 "name": heading,
        //                 "position": 0,
        //                 "price": "15.00",
        //             },
        //         ],
        //     },
        //     "event": "ee-productImpression",
        // });

    }
    const altText = props && props.alt ? props.alt : 'Image of ' + props.heading

    return (
        <div className="recipe-card">
            <BlockingLink
                // Target={newWindow && "_blank"}
                to={url}
                className={"recipe-card__link"}
            >
                <Lazy
                    Tag="div"
                    containerClassName="recipe-card__visual"
                >
                    <div
                        className="recipe-card__image"
                        style={{backgroundImage: `url(${image})`}}
                        role="img"
                        aria-label={altText}
                    />
                </Lazy>
                <div className="recipe-card__content">
                    { heading &&
                        <h3 className="recipe-card__heading">
                            {heading}
                        </h3>
                    }
                    { copy &&
                        <div
                            className="recipe-card__copy"
                            dangerouslySetInnerHTML={{__html: sanitise(copy)}}
                        />
                    }
                </div>
            </BlockingLink>
        </div>

    );

};

export default RecipeCard;
