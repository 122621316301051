import React, { useState } from 'react'

const LastName = (props) => {
  const { errorMsg,
  register,
  labelStyle,
  half,
  setValue, } = props

  const [focus, setFocus] = useState(false)
  const [input, setInput] = useState('')

  return (
    <>
      <div className="inputWrapper labeled relative w-full">
        <label
          htmlFor="00N1B00000B8U4K"
          className={`inputlabel inputlabel--empty pointer-events-none absolute ml-3 mt-4 cursor-text uppercase transition-all duration-500 md:mt-0 ${
            focus
              ? `${
                  labelStyle === 'inside'
                    ? '-left-1 -top-3 md:left-0 md:top-0'
                    : 'bg-form -top-5 left-1 md:-top-1 md:left-3'
                } text-[.656rem] text-black md:text-[.7878rem]`
              : `${labelStyle === 'inside' ? 'top-0 md:top-3' : 'top-0 md:top-5'} left-2 text-2xl  text-black/40  ${
                  input !== '' && 'opacity-0'
                }`
          }`}
        >
          Last name*
        </label>
        <input
          id="00N1B00000B8U4K"
          aria-describedby="lastNameError"
          className={`formInput input font-futura w-full border-2 border-black py-3 px-5 text-2xl uppercase text-black outline-[#F7941D] ${
            labelStyle === 'inside' ? 'bg-formInput py-3 md:py-4' : 'bg-form py-2 md:py-3'
          }`}
          onFocus={() => {
            setFocus(true)
          }}
          onBlurCapture={() => {
            setFocus(false)
          }}
          {...register}
          onChange={(e) => {
            setInput(e.target.value)
            setValue('lastName', e.target.value)
          }}
          value={input}
          type='text'
        />
      </div>
      {
        <span id="lastNameError" role="alert" className="w-full text-center text-sm text-red-600">
          {errorMsg}
        </span>
      }
    </>
  )
}

export default LastName
