/* eslint-disable no-magic-numbers */
/* eslint-disable max-statements */
/* eslint-disable no-console */
import "./page-default.scss";
import React, {Component} from "react";
import {Helmet} from 'react-helmet'
import AppError from "../../organisms/app-error";
import GameEnterCode from "../../organisms/GameEnterCode";
import GameForm from "../../organisms/GameForm";
import GameHeader from "../../organisms/GameHeader";
import GameWrapper from "../../organisms/GameWrapper";
import GameTerms from "../../organisms/GameTerms";
import GameComplete from "../../organisms/GameComplete";
import buildPage from "../page-builder";

import * as routeList from "global/utils/routing";


export default class PageContainer extends Component {

    state = {
        "isLoaded": false,
        "setRoutes": false,
    };

    constructor (props) {
        super(props);
        this.fetchData();
    }

    getPageName () {

        const path = this.props.location.pathname
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'>/g, "&#x27;");

        if (path.endsWith("/")) {

            return path;

        }

        return `${path}/`;

    }

    fetchData () {

        const pageName = this.getPageName();

        if (!this.props.data[pageName]) {

            // No! Lots wrong here:
            // - whatabout fetching data?
            // - Transition should be blocked before
            //   We have the data to avoid rendering noop
            this.props.fetchData(
                {name: pageName},
                false
            );

        }

    }

    checkRouteCode () {
      const routes = routeList.getRouteListCode();
      let currentRoute = this.getPageName();

      if(routes.includes(currentRoute)) {
        return true;
      }else{
        return false;
      }
    }

    checkRoutePlay () {
      const routes = routeList.getRouteListPlay();
      let currentRoute = this.getPageName();

      if(routes.includes(currentRoute)) {
        return true;
      }else{
        return false;
      }
    }

    checkRouteForm () {
      const routes = routeList.getRouteListForm();
      let currentRoute = this.getPageName();

      if(routes.includes(currentRoute)) {
        return true;
      }else{
        return false;
      }
    }

    checkRouteTerms () {
      const routes = routeList.getRouteListTerms();
      let currentRoute = this.getPageName();

      if(routes.includes(currentRoute)) {
        return true;
      }else{
        return false;
      }
    }

    checkRouteComplete () {
      const routes = routeList.getRouteListComplete();
      let currentRoute = this.getPageName();

      if(routes.includes(currentRoute)) {
        return true;
      }else{
        return false;
      }
    }

    componentDidUpdate (prevProps) {

        const pageName = this.getPageName();
        const newLocation = this.props.location.pathname;
        const {data} = this.props;

        if (prevProps.location.pathname !== newLocation && !data[pageName]) {

            this.props.fetchData(
                {name: newLocation},
                false
            );

        }

    }

    render () {

        const {data} = this.props;

        const pageName = this.getPageName();
       

        if (!data[pageName] || data[pageName].isFetching) {

            // In future, avoid this!
            return null;

        }

        const page = buildPage(data, pageName);

        // IF the API is unreachable
        // OR the page response is not as expected
        // BuildPage will return null
        // Without this the App will error server side and bring down the node server
        if (page === null) {

            return (
                <div className="page">
                    <AppError
                        message="This site is currently unavailable - Come back soon."
                    />
                </div>
            );
        }

        if (this.checkRouteCode()) {

            page.headerComponents[0].props.fields[0].value = "Enter Game Code | Captain Morgan";
            page.headerComponents[0].props.fields[4].value = "200";

        } else if (this.checkRoutePlay()) {

            page.headerComponents[0].props.fields[0].value = "Play | Captain Morgan";
            page.headerComponents[0].props.fields[4].value = "200";

        } else if (this.checkRouteForm()) {

            page.headerComponents[0].props.fields[0].value = "Sign Up | Captain Morgan";
            page.headerComponents[0].props.fields[4].value = "200";

        }else if (this.checkRouteTerms()) {

            page.headerComponents[0].props.fields[0].value = "Terms & Conditions | Captain Morgan";
            page.headerComponents[0].props.fields[4].value = "200";

        }
        else if (this.checkRouteComplete()) {

            page.headerComponents[0].props.fields[0].value = "Game Complete | Captain Morgan";
            page.headerComponents[0].props.fields[4].value = "200";

        }

        return (
            <div className="page cmgame">
                <Helmet>
                 {pageName.includes('germany') && 
                 <script>var dg_locale="de-de"</script>
                 }
                {pageName.includes('poland') && 
                 <script>var dg_locale="pl-pl"</script>
                 }
                {pageName.includes('escapegamenetherlands') && 
                 <script>var dg_locale="nl-nl"</script>
                 }
                 {pageName.includes('escapegamebelgiumdt') && 
                 <script>var dg_locale="nl-be"</script>
                 }
                  {pageName.includes('escapegamebelgiumfr') && 
                 <script>var dg_locale="fb-be"</script>
                 }
                  {pageName.includes('escapegameslovakia') && 
                 <script>dg_locale="sk-sk"</script>
                 }
                 </Helmet>
                {/* {page.headerComponents} */}

                <main className="page__content" id="main" role="main">
                    {this.checkRouteCode() &&
                        <>
                            <GameHeader loc='start' location={this.props.location} />
                            <GameEnterCode location={this.props.location} />
                        </>
                    }
                    {this.checkRoutePlay() &&
                        <>
                            {/* <GameHeader loc='play' location={this.props.location} /> */}
                            <GameWrapper location={this.props.location} />
                        </>
                    }
                    {this.checkRouteForm() &&
                        <>
                            <GameHeader location={this.props.location} />
                            <GameForm location={this.props.location} />
                        </>
                    }
                    {this.checkRouteTerms() &&
                        <>
                            <GameHeader loc='terms' location={this.props.location} />
                            <GameTerms location={this.props.location} />
                        </>
                    }
                     {this.checkRouteComplete() &&
                        <>
                            <GameHeader loc='complete' location={this.props.location} />
                            <GameComplete location={this.props.location} />
                        </>
                    }
                    
                </main>

                {/* <footer className="page__footer" role="contentinfo">
                    {page.footerComponents}
                </footer> */}
            </div>
        );

    }

}
