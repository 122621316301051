import "./shopalyst.scss";
import * as Actions from "actions/content";
import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import scriptLoader from "react-async-script-loader";

const Empty = () => null;

const withShopalyst = (WrappedComponent, ShopalystUrl) => scriptLoader(ShopalystUrl)((props) => <WrappedComponent {...props}/>); // eslint-disable-line max-len

class Shopalyst extends Component {

    onScriptLoaded = () => {

        const {

            ShopalystCampaignCode,
            ShopalystPlacementId,
            ShopalystPublisherId,

        } = fieldsArrayToKeyValueHash(this.props.fields);

        if (ShopalystPublisherId && ShopalystPlacementId && ShopalystCampaignCode) {

            // eslint-disable-next-line no-underscore-dangle
            if (global.window) {

                global.window._shopalyst.init( // eslint-disable-line no-underscore-dangle
                    ShopalystPublisherId,
                    ShopalystPlacementId,
                    ShopalystCampaignCode,
                    true
                );
                this.props.actions.setShopalystStatus({active: true});

            }

        } else {

            this.props.setShopalystStatus({active: false});

        }

    }

    render () {

        const {

            ShopalystUrl,
            ShopalystCampaignCode,
            ShopalystPlacementId,
            ShopalystPublisherId,

        } = fieldsArrayToKeyValueHash(this.props.fields);

        const ShopalystComponent = global.window
            ? withShopalyst(Empty, ShopalystUrl)
            : Empty;

        return (
            <ShopalystComponent
                onScriptLoaded={this.onScriptLoaded}
                publisherId={ShopalystPublisherId}
                placementId={ShopalystPlacementId}
                campaignId={ShopalystCampaignCode}
            />
        );

    }

}

const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators(Actions, dispatch)});

export default connect(
    null,
    mapDispatchToProps,
)(Shopalyst);
