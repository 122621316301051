/* eslint-disable no-empty-function */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable max-len, max-lines, no-console, no-return-assign, class-methods-use-this, max-statements */
/* globals mapboxgl, MapboxGeocoder */
import "./style.scss";
import React from "react";
import * as storage from 'global/utils/storage'
import * as routeList from "global/utils/routing";

class GameWrapper extends React.Component {

    constructor (props) {

        super(props);
        this.findLink = "";
        this.gameAssets = routeList.getSectionAssets(this.props.location.pathname)
    }

    checkStorage = () => {
      const claimed = storage.getClaimed()
        // console.log("Clamined >>>>>", claimed)
      //checks if user has actually went through claim page.
      // if false, redirects to claim page
      // if true, resets claim storage val to false and user must claim again.
      const enterPath = this.gameAssets && this.gameAssets.gamewrapper && this.gameAssets.gamewrapper.enterpath ? this.gameAssets.gamewrapper.enterpath : ''
        // console.log('ENTER PATH ', enterPath)
        if(claimed === 'false' || claimed === false) {
          window.location.href = enterPath
        }else{
          storage.setHasClaimed(false)
        }
    }

    componentDidMount () {
      //JS goes here
      const completePath = this.gameAssets && this.gameAssets.gamewrapper && this.gameAssets.gamewrapper.completepath ? this.gameAssets.gamewrapper.completepath : ''
      console.log("did mount")
      // window.addEventListener('load', this.checkStorage());
      window.gameEnd = function () {
        console.log('IN WINDOW GAME END ')
        window.location.href=completePath
      }
    }

    render () {
      console.log("render")
      const gamePath = this.gameAssets && this.gameAssets.gamewrapper && this.gameAssets.gamewrapper.gamepath ? this.gameAssets.gamewrapper.gamepath : ''
      return (
          <section className="GameWrapper" >
              <div className="container">
                <iframe src={gamePath} frameBorder="0" title="Play the game" aria-label="Play the game"></iframe>
              </div>
          </section>
      );

    }

}

export default GameWrapper;
