import "./style.scss";
import React from "react";
import sanitise from "global/utils/sanitise";

const Heading = (props) => {

    const {
        eyebrow,
        subtext,
        image,
        imageMobile,
        imagealt,
        title,
        srText,
        copy,
        justify,
        align,
        text,
       padBottom,
    } = props;

    const altText = props && props.imagealt ? props.imagealt : props.copy
    const useImage = props && props.image ? props.image : ''
    const imgMobile = props && props.imageMobile ? props.imageMobile : useImage
    const imgSize = props && props.imageSize ? `heading__image--${props.imageSize}` : ''
   
    return (
      <div className={`flex ${justify ? justify : 'justify-center'} ${align ? align : 'align-center'} heading`}>
        <h1 className={`heading__title text--white ${text ? text : 'text--center'}`}>
          {eyebrow &&
            <span className="eyebrow"><strong>{eyebrow}</strong></span>
          }
          {srText && 
            <span className="sr-only">{srText}</span>
          }
          {image &&
            <>
              <img  alt={altText} className={`heading__image heading__image--desktop ${imgSize}`} src={useImage} width="1200px" height="150px" loading="lazy" />
              <img  alt={altText} className="heading__image heading__image--mobile" src={imgMobile} width="845px" height="390px" loading="lazy" />
            </>
          }
          {subtext &&
            <span className="subtext"><strong>{subtext}</strong></span>
          }
          {title &&
            title
          }
        </h1>
        <div className={`heading__copy text--white ${text ? text : 'text--center'} ${padBottom ? "heading__copy--padBottom" : ''}`} dangerouslySetInnerHTML={{__html: sanitise(copy)}} />
      </div>
    );

};

export default Heading;
