export const getPageName = (route) => {
    const path = route
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'>/g, "&#x27;");

    if (path.endsWith("/")) {
        return path;
    }

    return `${path}/`;
}

export const getSectionAssets = (currentLocation) => {
  // console.log('IN GET SECTION ASSETS >>>> ', currentLocation)
  const assets = getAssets(currentLocation);

  return assets
}

export const getAssets = (route) => {
    let assets = {}
    const routeSplit = route.split('/');
    assets = getRouteData(routeSplit);
    return assets;
};


export const getRouteData = (route) => {
  let routeData = {}
  
  if(route.length > 0 && route.includes('jamaicaqueens')) {
    routeData = {
      rsvp: {
        formdate: '6/28',
        formlineloc: 'JAMAICA',
        formloc: 'JAMAICA QUEENS',
        formsubloc: 'New York',
        formdisclaimer: '<strong>notice</strong><br /><br />this event is being photographed<br /><br />By attending this event you (1) agree to be photographed and (2) consent to the use of your name, image and/or likeness in photographs or video from this event for publicity purposes including, without limitation, on the internet and in digital media.<br /><br />By attending this event you (3) release diageo americas inc., its affiliates, parents, subsidiaries, employees, contractors, agents, representatives, transferees, assigns and licensees ("diageo") from, and waive, any and all claims arising from, related to or in connection with the use of your name, image and/or likeness in the photographs from this event and (4) agree to receive no compensation for any rights here granted by you, and (5) confirm that you have the right to grant the above consents and waivers.',
        formtitle: 'RSVP now ',
        formcopy: 'For one night, Vita Coco Spiked with Captain Morgan will make Jamaica, Queens feel like the other Jamaica—the vacation one. A tropical party with all the tropicalities you would expect, from palm trees, hammocks, and food to incredible musical acts featuring one of the world’s top talents. RSVP now and meet us there.',
        listid: 'R45pCE',
        locationval: 'jamaicaqueens',
        formterms: "I accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_uen.html?locale=uen-us' target='_blank'>Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_uen.html?locale=uen-us' target='_blank'>Privacy and Cookie Notice</a>. *",
        newslettercopy: "Yes, would like to receive special offers and news from Captain Morgan by email. You can unsubscribe at any time.",
        thebarcopy: "Yes, I would like to receive promotions and special offers for gifting spirits, cocktail kits and barware from TheBar.com.",
        fulltitle: "Venue is full!",
        fullcopy: "Sorry! We have reached max capacity for the venue. Try catching the next event.",
        thankyoutitle: "Thank you!",
        thankyoucopy: "Event details will be emailed to you following your RSVP. Space will be limited the day of event so be sure to show up to the location on time to ensure entry."
      }
    }
  }else if(route.length > 0 && route.includes('sanjuancapistrano')) {
    routeData = {
      rsvp: {
        formdate: '8/4',
        formlineloc: 'SAN JUAN',
        formloc: 'SAN JUAN CAPISTRANO',
        formsubloc: 'California',
        formdisclaimer: '<strong>notice</strong><br /><br />this event is being photographed<br /><br />By attending this event you (1) agree to be photographed and (2) consent to the use of your name, image and/or likeness in photographs or video from this even for publicity purposes including, without limitation, on the internet and in digital media.<br /><br />By attending this event you (3) release diageo americas inc., its affiliates, parents, subsidiaries, employees, contractors, agents, representatives, transferees, assigns and licensees ("diageo") from, and waive, any and all claims arising from, related to or in connection with the use of your name, image and/or likeness in the photographs from this event and (4) agree to receive no compensation for any rights here granted by you, and (5) confirm that you have the right to grant the above consents and waivers.',
        formtitle: 'sign up now',
        formcopy: 'For one night, Vita Coco Spiked with Captain Morgan will make San Juan feel like the other San Juan—the Puerto Rico one. A tropical party with all the tropicalities you would expect, from palm trees, and food to incredible musical acts featuring one of the world’s top talents. Sign up now for your chance to attend.',
        listid: 'RKpsg9',
        locationval: 'sanjuancapistrano',
        formterms: "I accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_uen.html?locale=uen-us' target='_blank'>Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_uen.html?locale=uen-us' target='_blank'>Privacy and Cookie Notice</a>. *",
        newslettercopy: "Yes, would like to receive special offers and news from Captain Morgan by email. You can unsubscribe at any time.",
        thebarcopy: "Yes, I would like to receive promotions and special offers for gifting spirits, cocktail kits and barware from TheBar.com.",
        fulltitle: "Venue is full!",
        fullcopy: "Sorry! We have reached max capacity for the venue. Try catching the next event.",
        thankyoutitle: "Thank you!",
        thankyoucopy: "Keep your eyes on your email to see if you’ve been selected to attend!<br /><br />We are collecting sign ups and will send an email on 8/2 announcing event details to those invited."
      }
    }
  }else if(route.length > 0 && route.includes('jamaicaplain')) {
    routeData = {
      rsvp: {
        formdate: '9/14',
        formlineloc: 'Jamaica',
        formloc: 'JAMAICA PLAIN',
        formsubloc: 'BOSTON',
        formdisclaimer: '<strong>notice</strong><br /><br />this event is being photographed<br /><br />By attending this event you (1) agree to be photographed and (2) consent to the use of your name, image and/or likeness in photographs or video from this even for publicity purposes including, without limitation, on the internet and in digital media.<br /><br />By attending this event you (3) release diageo americas inc., its affiliates, parents, subsidiaries, employees, contractors, agents, representatives, transferees, assigns and licensees ("diageo") from, and waive, any and all claims arising from, related to or in connection with the use of your name, image and/or likeness in the photographs from this event and (4) agree to receive no compensation for any rights here granted by you, and (5) confirm that you have the right to grant the above consents and waivers.',
        formtitle: 'sign up now',
        formcopy: 'For one night, Vita Coco Spiked with Captain Morgan will make Jamaica Plains feel like the other Jamaica—the tropical island one. A party with all the tropicalities you would expect, from palm trees, and food to incredible musical acts featuring one of the world’s top talents. Sign up now for your chance to attend.',
        listid: 'X2C6k3',
        locationval: 'jamaicaplain',
        formterms: "I accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_uen.html?locale=uen-us' target='_blank'>Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_uen.html?locale=uen-us' target='_blank'>Privacy and Cookie Notice</a>. *",
        newslettercopy: "Yes, would like to receive special offers and news from Captain Morgan by email. You can unsubscribe at any time.",
        thebarcopy: "Yes, I would like to receive promotions and special offers for gifting spirits, cocktail kits and barware from TheBar.com.",
        fulltitle: "Venue is full!",
        fullcopy: "Sorry! We have reached max capacity for the venue. Try catching the next event.",
        thankyoutitle: "Thank you!",
        thankyoucopy: "Keep your eyes on your email to see if you’ve been selected to attend! We are collecting sign ups and will send an email on 9/12 announcing event details to those invited."
      }
    }
  }
  return routeData
}
