import {BlockingNavLink} from "global/utils/blocking-link";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

export default ({fields, index, ...rest}) => {

    const {Title, Url} = fieldsArrayToKeyValueHash(fields);
    // console.log('NAV FIELDS >>> ', fields)
    let addClass = ''
    if(Title.toLowerCase() === 'follow the captain') {
      addClass = 'text--red'
    }
    return (
        <li
            role="none"
            className={`navigation__nav-item navigation__nav-item--${index + 1} ${addClass}`}
        >
            <BlockingNavLink
                to={Url}
                prefetch
                className="navigation__nav-link"
                activeClassName="navigation__nav-link--active"
                role="menuitem"
                {...rest}
            >
                {Title}
            </BlockingNavLink>
        </li>
    );

};
