import * as Actions from "actions/content";
import {Link, NavLink} from "react-router-dom";
import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import filterObjectKeys from "./filter-object-keys";
import isExternal from "is-url-external";
import isServerSide from "./is-server-side";
import withConfig from "hoc/with-config";
import {withRouter} from "react-router";

/*
 * Blocking links
 *  - Unusually, how to render the entire page is described by the CMS
 *  - If we don't have the data, the entire page janks.
 *
 *  This blocks the rendering of the next page until the data required
 *  to load the page is present. Optionally, you can pass it prefetch
 *  and it will acquire the data to load the next page when the link is rendered
 *
 *  This will also determine if a link is external and render an <a tag if
 *  is it
 *
 *  Any click handlers passed down will be respected and executed after the
 *  data has been fetched
 *
 *  TODO: fetching may tag aggeess, so update the UI if rendering is taking longer than
 *  0.5 s or some default
 */

const withBlockingAndPrefetch = (WrappedComponent) => class extends Component {

    componentDidMount () {

        if (this.props.prefetch && !this.props.data[this.props.to]) {

            this.fetchData(this.props.to);

        }

    }

    componentDidUpdate (prevProps) {

        if (!prevProps.data[this.props.to] && this.props.data[this.props.to] && this.navigateAfterFetching) {

            this.navigate(this.props.to);
            this.navigateAfterFetching = false;

        }

    }

   cleanUrl = (url) => url.replace(new RegExp('https:/www.drinkiq.com/en-us/', 'g'),"").replace(new RegExp('https:/www.where-to-buy.com/','g'),"").replace(new RegExp('https:/captainmorganstore.com/store.php/product-detail/captain-morgan-custom-label','g'),"").replace(new RegExp('https:/www.captainmorganstore.com/','g'),"");


    fetchData = (page) => {


        const url = this.cleanUrl(`${page.toLowerCase()}`).replace(/\/+$/, "") + '.json';

        this.props.actions.fetchContent({
            apiUrl: this.props.config.apiUrl,
            page: {name: page},
            url,
        });

    };

    navigate = (page) => {

        this.props.history.push(page);

        if (this.props.onClick) {

            // The parent component wants to do something
            this.props.onClick();

        }

    };

    handleClick (evt) {

        evt.preventDefault();

        if (isExternal(this.props.to) || isServerSide()) {

            return;

        }

        if (this.props.data[this.props.to]) {

            this.navigate(this.props.to);

        } else {

            this.fetchData(this.props.to);
            this.navigateAfterFetching = true;

        }

    }

    render () {

        const filteredProps = filterObjectKeys(this.props, [
            "data",
            "history",
            "match",
            "prefetch",
            "location",
            "children",
            "staticContext",
            "actions",
        ]);

        if (global.window && isExternal(this.props.to)) {

            return (
                <a href={this.props.to} rel="noopener noreferrer" {...filteredProps} >
                    {this.props.children}
                </a>
            );

        }

        return (
            <WrappedComponent {...filteredProps} onClick={(evt) => this.handleClick(evt)} >
                {this.props.children}
            </WrappedComponent>
        );

    }

};

const blockingNavLink = withBlockingAndPrefetch(NavLink);
const blockingLink = withBlockingAndPrefetch(Link);

const mapStateToProps = (state) => ({data: state.content.pages || {}});

const mapDispatchToProps = (dispatch) => ({actions: bindActionCreators(Actions, dispatch)});

const BlockingNavLink = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(withConfig(blockingNavLink)));

const BlockingLink = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(withConfig(blockingLink)));

export {
    BlockingNavLink,
    BlockingLink,
};
