import "./product-list.scss";
import ProductCard from "../../molecules/product-card";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

export default class ProductGrid extends React.Component {

    render () {

        return (
            <ul className="product-list__listing">
                {
                    this.props.products.map((product, index) => {

                        const {
                            BackgroundColour,
                            CardTitle,
                            CardImage, Tags,
                            Url,
                        } = product;

                        if (!CardTitle || !CardImage) {

                            return null;

                        }

                        const imageProps = fieldsArrayToKeyValueHash(CardImage);

                        return (
                            <li key={`product-${index}`} className="product-list__listing-item">
                                <ProductCard
                                    heading={CardTitle}
                                    url={Url}
                                    image={imageProps.Url}
                                    tags={Tags}
                                    background={BackgroundColour}
                                />
                            </li>
                        );

                    })
                }
            </ul>
        );

    }

}
