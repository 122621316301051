import "./style.scss";
import Cta from "../../atoms/cta";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import sanitise from "global/utils/sanitise";

import iconChatbot from 'global/images/chatbot/icon--chatbot.svg'


const cn = require('classnames')

const TwoColumnBlock = (props) => {
  const getImage = (image, size) => {
    const imgSize = size ? size : '1920'
    let useImage = ''

    if(image && image[0].value !== '') {
      if(image[0].value.includes('mode=crop')) {
        useImage = `${image[0].value.split("?")[0]}?mode=max&width=${imgSize}`
      }else{
        useImage = image[0].value
      }
    }

    return useImage
  }

  const toggleWindow = (e) => {
    window.chatWidget.toggle()
  }

  if (props && props.fields) {
    const {
      BottomPadding,
      ChatbotCtatext,
      ContentCopy,
      ContentCopyAlignment,
      ContentImage,
      ContentImagePosition,
      ContentTitle,
      CtaCollection,
      EnableChatbot,
      PaddedImage,
      TopPadding,
    } = fieldsArrayToKeyValueHash(props.fields);
    // const data = fieldsArrayToKeyValueHash(props.fields);

    const contentImg = getImage(ContentImage, 700)
    const imageOrient = ContentImagePosition && ContentImagePosition !== '' ? ContentImagePosition : ''
    const botPad = BottomPadding && BottomPadding !== '' ? BottomPadding : ''
    const topPad = TopPadding && TopPadding !== '' ? TopPadding : ''

    return (
      <section className={`twocolblock ${topPad !== '' ? `twocolblock--top${topPad}` : ''} ${botPad !== '' ? `twocolblock--bot${botPad}` : ''}`}>
        <div className="container container--1600">
          <div className="twocolblock__wrap">
            <div className={`image ${imageOrient === 'right' ? 'order-1' : ''} ${PaddedImage ? 'image--padded' : ''}`}>
              {contentImg !== '' ? (
                  <>
                    <img src={contentImg} className="content__image" aria-hidden="true" loading="lazy" />
                  </>
                ) : null
              }
            </div>
            <div className={`content ${imageOrient === 'right' ? 'order-0' : ''} ${ContentCopyAlignment && ContentCopyAlignment!== '' ? `content--${ContentCopyAlignment.toLowerCase()}` : 'content--left'}`}>
              {ContentTitle ? (
                  <>
                    <h2 className={`content__title`}>
                      {ContentTitle}
                    </h2>
                  </>
                ) : null
              }
              
              <div className={`content__copy`} dangerouslySetInnerHTML={{__html: ContentCopy}} />
              {(!EnableChatbot && CtaCollection) ? (
                <div className="content__cta">
                  {CtaCollection && CtaCollection.map((ctaInstance, index) => <Cta
                      key={`cta-${index}`}
                      cta={ctaInstance}
                      version="primary"
                      varient={index && "ghost"}
                  />)}
                </div>
              ) : null}

              {EnableChatbot ? (
                <div className="content__cta">
                  <button
                    className="cta cta-primary"
                    onClick={(e) => toggleWindow()}
                  >
                    <span className="cta__content">{ChatbotCtatext && ChatbotCtatext !== '' ? ChatbotCtatext : 'Ask Us Anything'}</span>
                  </button>
                </div>
                ) : null}
            </div>
            
          </div>
        </div>
      </section>
    );

  }

  return false;
};

export default TwoColumnBlock;
