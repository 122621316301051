import "./hero-background.scss";
import HeroImage from "./image";
import HeroVideo from "./video";
import React from "react";

export default (props) => {

    const {image, video} = props;

    if (video) {

        return <HeroVideo video={video} image={image} />;

    } else if (image) {

        return <HeroImage image={image} />;

    }

    return <div className="hero__background" />;

};
