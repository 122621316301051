import "./style.scss";
import React, { useEffect,useState } from "react";
import fetch from "isomorphic-fetch";
import moment from "moment";
import $ from 'jquery'

import bottle from 'global/images/ftc2024/bottle_outline.png'
import bottleP1 from 'global/images/ftc2024/bottle_part_1.png'
import bottleP2 from 'global/images/ftc2024/bottle_part_2.png'
import bottleP3 from 'global/images/ftc2024/bottle_part_3.png'
import bottleP4 from 'global/images/ftc2024/bottle_part_4.png'
import bottleP5 from 'global/images/ftc2024/bottle_part_5.png'

const BottleGame = (props) => {
  const [userWin, setUserWin] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [gameTotal, setGameTotal] = useState(0);
  const [correct, setCorrect] = useState(0);
  const winMax = 5  
  
  const gameInit = () => {
    if(window.DragDropTouch) {
      DragDropTouch.enable()
    }
    const draggableElements = document.querySelectorAll(".draggable");
    const droppableElements = document.querySelectorAll(".droppable");

    if(draggableElements && draggableElements.length > 0) {
      draggableElements.forEach(elem => {
        elem.addEventListener("dragstart", dragStart);
      });
    }
    
    if(droppableElements && droppableElements.length > 0) {
      droppableElements.forEach(elem => {
        elem.addEventListener("dragenter", dragEnter);
        elem.addEventListener("dragover", dragOver);
        elem.addEventListener("dragleave", dragLeave);
        elem.addEventListener("drop", drop);
      });
    }
  }

  const dragStart = (event) =>  {
    const useTarget = getActualTarget(event.target, event)
    event.dataTransfer.setData("text", useTarget.id); // or "text/plain"
  }

  const getActualTarget = (currentTarget, event) => {
    let useTarget

    if (currentTarget.nodeName == 'IMG') {
      useTarget = event.target.parentNode
    } else {
      useTarget = event.target
    }
    return useTarget
  }

  const dragEnter = (event) => {
    const useTarget = getActualTarget(event.target, event)

    if(useTarget.classList && useTarget.classList.contains("droppable") && !useTarget.classList.contains("dropped")) {
      useTarget.classList.add("droppable-hover");
    }
  }

  const dragOver = (event) => {
    const useTarget = getActualTarget(event.target, event)

    if(useTarget.classList && useTarget.classList.contains("droppable") && !useTarget.classList.contains("dropped")) {
      event.preventDefault();
    }
  }

  const dragLeave = (event) => {
    const useTarget = getActualTarget(event.target, event)

    if(useTarget.classList && useTarget.classList.contains("droppable") && !useTarget.classList.contains("dropped")) {
      useTarget.classList.remove("droppable-hover");
    }
  }

  const drop = (event) => {
    const useTarget = getActualTarget(event.target, event)

    event.preventDefault();
    useTarget.classList.remove("droppable-hover");
    const draggableElementBrand = event.dataTransfer.getData("text");
    const droppableElementBrand = useTarget.getAttribute("data-brand");
    const isCorrectMatching = draggableElementBrand===droppableElementBrand;
    const newTotal = gameTotal + 1
    setGameTotal(newTotal)
    if(isCorrectMatching) {
      const draggableElement = document.getElementById(draggableElementBrand);
      useTarget.classList.add("dropped");
      draggableElement.classList.add("dragged");
      draggableElement.setAttribute("draggable", "false");

      if (draggableElement.hasChildNodes()) {
        const draggableChildren = draggableElement.childNodes;
        for (const node of draggableChildren) {
          node.setAttribute("draggable", "false");
        }
      }

      if (useTarget.hasChildNodes()) {
        const children = useTarget.childNodes;
        for (const node of children) {
          node.setAttribute("draggable", "false");
        }
      }
      const newScore = correct + 1
      setCorrect(newScore)
    }
  }

  const initAnyroad = () => {
    (function () {
      window.anyroad = new AnyRoad ({
        container: '#iframe_wrapper',
        plugin: { 
          id: 'captainmorgan_followthecaptain' 
        }
      });
    })();
  }

  useEffect(() => {
    if (correct === winMax) {
      setTimeout( () => {
        $('#bottlegameWrap').addClass('fadeOut')
        setTimeout( () => {
          setUserWin(true)
          setTimeout( () => {
            $('#winwrap').removeClass('fadeIn')
          }, 250)
        }, 300)
      }, 1300)
    }
  }, [gameTotal, correct])

  useEffect(() => {
    if (userWin) {
      setTimeout( () => {
        initAnyroad()
      }, 500)
    }
  }, [userWin])
  useEffect(() => {
    document.addEventListener("load", gameInit());
     let params = new URL(window.location.href).searchParams
      let skipEpicGame = params.get('skipEpicGame')
      if(skipEpicGame) {
        setUserWin(true)
      }

    return () => {
      document.removeEventListener("load", gameInit());
    }
  })

  return (
    <div className="bottlegame" id="bottlegame">
     {userWin &&
        <div className="bottlegame__winwrap text--white win-wrap winwrap" id="winwrap">
          <div className="winwrap__heading">
            {/* <h2 className="winwrap__title text--center">
              YOU SOLVED IT
            </h2> */}
            <div className="winwrap__headingcopy text--copy">
              Enter your info below, and we'll let you know if you have a spot in the crew.
            </div>
          </div>
          <div className="winwrap__content wincontent flex">
            <div id="iframe_wrapper"></div>
            <a
              href="https://www.anyroad.com?utm_source=captainmorgan_followthecaptain&utm_medium=PBA&utm_campaign=PLUGIN"
              id="powered_by_anyroad_link"
              style={{
                fontSize: '14px',
                display: 'block',
                textAlign: 'center',
              }}
              target="_blank"
            >
              Powered by AnyRoad
            </a>
          </div>
        </div>
      }
      {!userWin &&
        <div className="bottlegame__wrap" id="bottlegameWrap">       
          <div className="bottlegame__canvas canvas flex">
            <div className="matching-pairs order-2">
              <img src={bottle} aria-hidden="true" />
              <span className="droppable droppable-1" data-brand="bottlepart-1">
                <img src={bottleP1} aria-hidden="true" />
              </span>
              <span className="droppable droppable-2" data-brand="bottlepart-2">
                <img src={bottleP2} aria-hidden="true" />
              </span>
              <span className="droppable droppable-3" data-brand="bottlepart-3">
                <img src={bottleP3} aria-hidden="true" />
              </span>
              <span className="droppable droppable-4" data-brand="bottlepart-4">
                <img src={bottleP4} aria-hidden="true" />
              </span>
              <span className="droppable droppable-5" data-brand="bottlepart-5">
                <img src={bottleP5} aria-hidden="true" />
              </span>
            </div>
            <div className="spacer" />
            <div className="draggable-items order-1">
              <span className="bottlepart bottlepart-3 draggable" draggable="true" id="bottlepart-3">
                <img src={bottleP3} aria-hidden="true" />
              </span>
              <span className="bottlepart bottlepart-2 draggable" draggable="true" id="bottlepart-2">
                <img src={bottleP2} aria-hidden="true" />
              </span>
            </div>

            <div className="draggable-items order-3 draggable-items--wide">
              <div className="wrap flex order-3">
                <span className="bottlepart bottlepart-4 draggable order-2" draggable="true" id="bottlepart-4">
                  <img src={bottleP4} aria-hidden="true" />
                </span>
                <span className="bottlepart bottlepart-5 draggable order-1" draggable="true" id="bottlepart-5">
                  <img src={bottleP5} aria-hidden="true" />
                </span>
              </div>
              <div className="wrap flex order-1">
                <span className="bottlepart bottlepart-1 draggable order-1" draggable="true" id="bottlepart-1">
                  <img src={bottleP1} aria-hidden="true" />
                </span>
              </div>
            </div>
          </div>
          <div className="bottlegame__content">
            <h2 className="bottlegame__title">Piece together the bottle<br/> to join the crew</h2>
            <div className="bottlegame__copy">
              <div className="bottlegame__text text--white text--copy">
                We're breaking a bottle on the bow as the Captain's Cruise with Cruz sets sail. 
                Piece together the bottle to sign up and join the Captain and Giant's legend Victor Cruz in NYC.
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default BottleGame;
