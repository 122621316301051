import "./form-contact.scss";
import fetch from "isomorphic-fetch";
import React, { useEffect,useState } from "react";

import { useForm } from 'react-hook-form'
import $ from 'jquery'
// @ts-ignore
// import MultiSelect from 'react-multiple-select-dropdown-lite'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import signupSchema from '../../../validations/registrationValidation'
import moment from 'moment'
import {load} from 'recaptcha-v3'

import FirstName from "./FirstName";
import LastName from './LastName'
import EmailField from './EmailField'
import FormInput from './FormInput'
import City from './City'
import State from './State'
import DOB from './DOB'
import FormSelect from './FormSelect'
import FormTextArea from './FormTextArea'
import PhoneNumber from './PhoneNumber'
import FormPicklist from './FormPicklist'

const FormContact = (props) => {
  const [inquiry, setInquiry] = useState('None')
  const [pickList, setPickList] = useState(false)
  const [phone, setPhone] = useState(false)
  const [orderNumber, setOrderNumber] = useState(false)
  const [mediaOutlet, setMediaOutlet] = useState(false)
  const [storeName, setStoreName] = useState(false)
  const [storeAddress, setStoreAddress] = useState(false)
  const [futureField1, setFutureField1] = useState(false)
  const [futureField2, setFutureField2] = useState(false)
  const [futureField3, setFutureField3] = useState(false)
  const [merchText, setMerchText] = useState('')
  const [windowLocale, setWindowLocale] = useState('')
  const [windowHref, setWindowHref] = useState('')
  const [hasRecaptchaErr, setHasRecaptchaErr] = useState(false)
  const [success, setSuccess] = useState(false)

  const [firstNameInput, setFirstNameInput] = useState('')
  const [lastNameInput, setLastNameInput] = useState('')
  const [phoneInput, setPhoneInput] = useState('')
  const [productSelected, setProductSelected] = useState('')

  const productOptions = [
    { 
      label: 'Captain Morgan Original Spiced Rum', 
      value: 'Captain Morgan Original Spiced Rum' 
    },
    {
      label: 'Captain Morgan Silver Spiced Rum',
      value: 'Captain Morgan Silver Spiced Rum'      
    },
    {
      label: 'Captain Morgan White Rum',
      value: 'Captain Morgan White Rum',
    },
    {
      label: 'Captain Morgan Private Stock',
      value: 'Captain Morgan Private Stock',
    },
    {
      label: 'Captain Morgan Black Spiced Rum',
      value: 'Captain Morgan Black Spiced Rum',
    },
    {
      label: 'Captain Morgan 100 Proof Rum',
      value: 'Captain Morgan 100 Proof Rum',
    },
    {
      label: 'Captain Morgan Sliced Apple',
      value: 'Captain Morgan Sliced Apple',
    },
    {
      label: 'Captain Morgan Cherry Vanilla',
      value: 'Captain Morgan Cherry Vanilla',
    },
    {
      label: 'Captain Morgan Coconut Rum',
      value: 'Captain Morgan Coconut Rum',
    },
    {
      label: 'Captain Morgan Pineapple Rum',
      value: 'Captain Morgan Pineapple Rum',
    },
    {
      label: 'Captain Morgan Mai Tai',
      value: 'Captain Morgan Mai Tai',
    },
    {
      label: 'Captain Morgan Long Island Ice Tea',
      value: 'Captain Morgan Long Island Ice Tea',
    },
    {
      label: 'Captain Morgan Tropical Punch',
      value: 'Captain Morgan Tropical Punch',
    },
    {
      label: 'Vita Coco with Captain Morgan - Strawberry Daquiri',
      value: 'Vita Coco with Captain Morgan - Strawberry Daquiri',
    },
    {
      label: 'Vita Coco with Captain Morgan - Pina Colada',
      value: 'Vita Coco with Captain Morgan - Pina Colada',
    },
    {
      label: 'Vita Coco with Captain Morgan - Lime Mojito',
      value: 'Vita Coco with Captain Morgan - Lime Mojito',
    },
  ]

  useEffect(() => {
    setWindowLocale(window.location.origin)
    setValue('00N1B00000BDRwq', productSelected)

    return () => {}
  }, [inquiry, productSelected])

  function onSubmit() {
    
    let form = document.querySelector('#cidbForm')
    let grep = document.getElementById('g-recaptcha-response')

    if (!grep || grep.value === '') {
      setHasRecaptchaErr(true)
    } else {
      setHasRecaptchaErr(false)

      if (form) {
        let captchaSettings = document.getElementsByName('captcha_settings')[0]
        captchaSettings.value = JSON.stringify({
          keyname: 'Diageo_NAM_Webform_reCAPTCHA',
          fallback: 'true',
          orgId: '00D30000000pmHl',
          ts: new Date().getTime(),
        })
        let date = document.getElementById('00N4v00000BqSVA')
        date.type = 'text'
        setValue('00N4v00000BqSVA', moment(date.value).format('MM/DD/YYYY'), {
          shouldDirty: true,
        })

        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'customEvent',
          eventCategory: 'Submission',
          eventAction: 'Submit Button Clicked',
          eventLabel: 'Data Form Submit Button Clicked',
        })

        form.submit()
      }
    }
  }

  const handleMultiChange = (value) => {
    setProductSelected(value)
  }

  const handleInquiryChange = (e) => {
    setInquiry(e.target.value)
    setReasonConditionals('None')
    delete errors['00N4v00000C2h5k']
  }

  const handleReasonChange = (e) => {
    setReasonConditionals(e.target.value)
    setMerchText(e.target.value)
    delete errors['00N4v00000C2h5p']
  }

  const handleFirstNameChange = (e) => {
    let value = ''
    for (let i = 0; i < e.target.value.length; i++) {
      if (e.target.value[i].match(/[a-zA-Z]|\-/g)) value += e.target.value[i]
    }
    if (e.target.value.length > 0) {
      value = e.target.value.toUpperCase()
      delete errors['00N1B00000B8U4J']
    }
    setFirstNameInput(value)
  }
  const handleLastNameChange = (e) => {
    let value = ''
    for (let i = 0; i < e.target.value.length; i++) {
      if (e.target.value[i].match(/[a-zA-Z]|\-/g)) value += e.target.value[i]
    }

    if (e.target.value.length > 0) {
      value = e.target.value.toUpperCase()
      delete errors['00N1B00000B8U4K']
    }
    setLastNameInput(value)
  }

  const handlePhoneChange = (e) => {
    let value = ''
    for (let i = 0; i < e.target.value.length; i++) {
      if ((Number(e.target.value[i]) || e.target.value[i] === '0') && i <= 12) value += e.target.value[i]
    }
    if (value.length > 6) value = `(${value.slice(0, 3)})${value.slice(3, 6)}-${value.slice(6)}`
    setPhoneInput(value)
    delete errors['00N4v00000C2nYc']
  }

  const setReasonConditionals = (reason) => {
    if (reason === 'Where Can I Buy Captain Morgan?') {
      setPickList(false)
      setPhone(false)
      setOrderNumber(false)
      setMediaOutlet(false)
      setStoreName(false)
      setStoreAddress(false)
    } else if (reason === 'Ingredient/Allergen Inquiry') {
      setPickList(true)
      setPhone(false)
      setOrderNumber(false)
      setMediaOutlet(false)
      setStoreName(false)
      setStoreAddress(false)
    } else if (reason === 'Nutritional Facts') {
      setPickList(true)
      setPhone(false)
      setOrderNumber(false)
      setMediaOutlet(false)
      setStoreName(false)
      setStoreAddress(false)
    } else if (reason === 'Product concern') {
      setPickList(true)
      setPhone(true)
      setOrderNumber(false)
      setMediaOutlet(false)
      setStoreName(false)
      setStoreAddress(false)
    } else if (reason === 'Merch Shop Order Inquiry') {
      setPickList(false)
      setPhone(true)
      setOrderNumber(true)
      setMediaOutlet(false)
      setStoreName(false)
      setStoreAddress(false)
    } else if (reason === 'Give us some praise?') {
      setPickList(false)
      setPhone(false)
      setOrderNumber(false)
      setMediaOutlet(false)
      setStoreName(false)
      setStoreAddress(false)
    } else if (reason === 'Distributor Inquiry') {
      setPickList(false)
      setPhone(true)
      setOrderNumber(false)
      setMediaOutlet(false)
      setStoreName(true)
      setStoreAddress(true)
    } else if (reason === 'Media/Press Inquiry') {
      setPickList(false)
      setPhone(true)
      setOrderNumber(false)
      setMediaOutlet(true)
      setStoreName(false)
      setStoreAddress(false)
    } else if (reason === 'Sponsorship') {
      setPickList(false)
      setPhone(false)
      setOrderNumber(false)
      setMediaOutlet(false)
      setStoreName(false)
      setStoreAddress(false)
    } else {
      setPickList(false)
      setPhone(false)
      setOrderNumber(false)
      setMediaOutlet(false)
      setStoreName(false)
      setStoreAddress(false)
    }
  }
  const {
    register,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(signupSchema),
  })

  const ProjectLabelStyle = 'inside'
  const captchaLoad = () => {
    load("6Lf2_uoaAAAAAHmbdTSGUgSTIT2whOY1sDXARlO7", {
        explicitRenderParameters: {
            container: "recaptcha",
        },
    });
  }
  useEffect(() => {    
    const winLocation = window.location.href
    const winLocale = window.location.pathname
    const locSplit = winLocale.split('/')
    setWindowHref(winLocation)
    if(locSplit.length > 1) {
      setWindowLocale(locSplit[1])
    }
    $(function () {
      // need to remove newsletter modal, it's conflicting with the form
      if($('#newsletterModal')) {
        $("#newsletterModal").remove()
      }

      $("input.input, select.input, textarea.input").on("focusin", function () {
       
        $(this).siblings(".inputlabel").removeClass("inputlabel--empty");
      });

      $("input.input, select.input, textarea.input").on("focusout change", function () {
        if ($(this).val() === "") {
          $(this).siblings(".inputlabel").addClass("inputlabel--empty");
        }else{
          $(this).siblings(".inputlabel").removeClass("inputlabel--empty");
        }
      });
    })
  })

  useEffect(() => {
    let params = new URL(window.location.href).searchParams
    let successParam = params.get('success')
    
    if (successParam) {
      setSuccess(true)
    }

    window.addEventListener("load", captchaLoad());
    return () => {
      window.removeEventListener("load", captchaLoad());
    }
  }, [])
  return (
    <section className="formcontact">
      <div className="container container--1440">
        {!success && (
          <form
            id="cidbForm"
            className="form w-full"
            onSubmit={handleSubmit(onSubmit)}
            action="https://webto.salesforce.com/servlet/servlet.WebToCase?sencoding=UTF-8"
            method="POST"
            autoComplete="off"
          >
          <input type="hidden" className="hidden" name="captcha_settings" />
          <input type="hidden" name="orgid" value="00D30000000pmHl" />
          <input
            type="hidden"
            name="retURL"
            value={`${windowHref}?success=true`}
            id="retURL"
            className=" focusAttached"
          />
          {/* <input type="hidden" name="debug" value="1" />
                <input type="hidden" name="debugEmail" value="wvisokay@thoriumdigital.com" /> */}
          <input
            id="00N4v00000C2nYV"
            maxLength={5}
            name="00N4v00000C2nYV"
            type="hidden"
            value="US"
            className="focusAttached"
          />
          
          <input id="00N4v00000BqS6F" name="00N4v00000BqS6F" type="hidden" value="https://www.captainmorgan.com/en-us/" />
          <div className="row mx-2 mb-4 flex flex-col items-stretch justify-center gap-x-4 lg:mx-0 lg:flex-row">
            {/* first name */}
            <div className="name-wrapper mb-4 flex w-full flex-col lg:ml-2 lg:mb-0">
              <FirstName
                register={{ ...register('00N1B00000B8U4J') }}
                errorMsg={errors['00N1B00000B8U4J']?.message}
                labelStyle={ProjectLabelStyle}
                half
                setValue={setValue}
              />
            </div>
            {/* last name */}
            <div className="name-wrapper flex w-full flex-col lg:mr-2">
              <LastName
                register={{ ...register('00N1B00000B8U4K') }}
                errorMsg={errors['00N1B00000B8U4K']?.message}
                labelStyle={ProjectLabelStyle}
                half
                setValue={setValue}
              />
            </div>
          </div>

          <div className="row mx-2 mb-4 flex flex-col items-stretch justify-center gap-x-4 lg:mx-0 lg:flex-row">
            <EmailField
              register={{
                ...register('00N4v00000C2nYW', {
                  onChange: (e) => {
                    trigger(['00N4v00000C2nYb', '00N4v00000C2nYW'])
                  },
                }),
              }}
              errorMsg={errors['00N4v00000C2nYW']?.message}
              labelStyle={ProjectLabelStyle}
              setValue={setValue}
            />
          </div>
          {/* verifyEmail */}
          <div className="row mx-2 mb-4 flex flex-col items-stretch justify-center gap-x-4 lg:mx-0 lg:flex-row">
            <FormInput
              register={{ ...register('00N4v00000C2nYb') }}
              errorMsg={errors['00N4v00000C2nYb']?.message}
              labelStyle={ProjectLabelStyle}
              setValue={setValue}
              id="verifyEmail"
              registeredId="00N4v00000C2nYb"
              label="Verify Email*"
              type='email'
            />
          </div>

          <div className="row mx-2 mb-4 flex flex-col items-stretch justify-center gap-x-4 lg:mx-0 lg:flex-row">
            <div className="city-wrapper mb-4 flex w-full flex-col  lg:ml-2 lg:mb-0">
              {/* city */}
              <City
                register={{ ...register('00N4v00000C2nYU') }}
                errorMsg={errors['00N4v00000C2nYU']?.message}
                labelStyle={ProjectLabelStyle}
                half
                setValue={setValue}
              />
            </div>
            <div className="state-wrapper flex w-full flex-col lg:mr-2">
              <State
                register={{ ...register('00N4v00000C2nYa') }}
                errorMsg={errors['00N4v00000C2nYa']?.message}
                labelStyle={ProjectLabelStyle}
                half
                setValue={setValue}
              />
            </div>
          </div>

          <div className="row mx-2 mb-4 flex flex-col items-stretch justify-center gap-x-4 lg:mx-0 lg:flex-row">
            {/* dob */}
            <DOB
              register={{ ...register('00N4v00000BqSVA') }}
              errorMsg={errors['00N4v00000BqSVA']?.message}
              labelStyle={ProjectLabelStyle}
              setValue={setValue}
            />
          </div>
          <div className="row mx-2 mb-4 flex flex-col items-stretch justify-center gap-x-4 lg:mx-0 lg:flex-row">
            <FormSelect
              register={{ ...register('00N4v00000C2h5k') }}
              errorMsg={errors['00N4v00000C2h5k']?.message}
              labelStyle={ProjectLabelStyle}
              setValue={setValue}
              id="inquiryType"
              registeredId="00N4v00000C2h5k"
              label="Inquiry Type*"
              onChange={handleInquiryChange}
              optionKey={'inqiryType'}
              options={[
                {value: 'None', text: ''},
                {value: 'Consumer', text: 'Consumer'},
                {value: 'Retailer', text: 'Retailer'},
                {value: 'Merch', text: 'Merch'},
                {value: 'Other', text: 'Other'},
              ]}
            >
              <option value="None"></option>
              <option value="Consumer">Consumer</option>
              <option value="Retailer">Retailer</option>
              <option value="Other">Other</option>
            </FormSelect>
          </div>
            {inquiry !== 'None' && (
              <div className="row mx-2 mb-4 flex flex-col items-stretch justify-center gap-x-4 lg:mx-0 lg:flex-row">
                {/* contactReason */}
                {inquiry !== 'None' && (
                  <FormSelect
                    register={{ ...register('00N4v00000C2h5p') }}
                    errorMsg={errors['00N4v00000C2h5p']?.message}
                    labelStyle={ProjectLabelStyle}
                    setValue={setValue}
                    id="reasonForContact"
                    registeredId="00N4v00000C2h5p"
                    label="Reason for Contact*"
                    onChange={handleReasonChange}
                  >
                    <option value="none"></option>
                    {inquiry === 'Consumer' && (
                      <>
                        <option value="Where Can I Buy Captain Morgan?">Where Can I Buy Captain Morgan?</option>
                        <option value="Ingredient/Allergen Inquiry">Ingredient/Allergen Inquiry</option>
                        <option value="Nutritional Facts">Nutritional Facts</option>
                        <option value="Product concern">Product concern</option>
                        <option value="Give us some praise?">Compliments for the Captain!</option>
                      </>
                    )}
                    {inquiry === 'Retailer' && (
                      <>
                        <option value="Product concern">Product concern</option>
                        <option value="Distributor Inquiry">Distributor Inquiry</option>
                      </>
                    )}
                    {inquiry === 'Merch' && (
                      <>
                        <option value="General Inquiry">General Inquiry</option>
                        <option value="Order Status">Order Status</option>
                        <option value="Cancel/Edit Order">Cancel/Edit Order</option>
                        <option value="Return Policy">Return Policy</option>
                        <option value="Report a Concern">Report a Concern</option>
                      </>
                    )}
                    {inquiry === 'Other' && (
                      <>
                        <option value="Media/Press Inquiry">Media/Press Inquiry</option>
                        <option value="Sponsorship">Sponsorship</option>
                      </>
                    )}
                  </FormSelect>
                )}
              </div>
            )}
            {/* {merchText === 'General Inquiry' && (
              <div className="mx-2 mb-4">
                <p>
                  Have questions? Our customer service hours are Monday – Saturday between 10:00AM and 8:00PM Eastern
                  Time. Holiday Hours may vary. Emails received outside of these service hours will be responded to on
                  the following business day.
                </p>
              </div>
            )}
            {merchText === 'Order Status' && (
              <div className="mx-2 mb-4">
                <p>
                  <a
                    className="text-pink underline"
                    href="https://shop.21seeds.com/pages/order-status"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Check your order status here
                  </a>{' '}
                  with your order number and email address. Your order number was emailed to you immediately after you
                  placed your order.
                </p>
              </div>
            )}
            {merchText === 'Cancel/Edit Order' && (
              <div className="mx-2 mb-4">
                <p>
                  Please check out our Return Policy{' '}
                  <a
                    className="text-pink underline"
                    href="https://shop.21seeds.com/pages/return-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                  !
                </p>
              </div>
            )}
            {merchText === 'Return Policy' && (
              <div className="mx-2 mb-4">
                <p>
                  Please check out our Return Policy{' '}
                  <a
                    className="text-pink underline"
                    href="https://shop.21seeds.com/pages/return-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                  !
                </p>
              </div>
            )} */}

            {phone && (
              <div className="row mx-2 mb-4 flex flex-col items-stretch justify-center gap-x-4 lg:mx-0 lg:flex-row">
                {/* phone */}
                <PhoneNumber
                  register={{ ...register('00N4v00000C2nYc') }}
                  errorMsg={errors['00N4v00000C2nYc']?.message}
                  labelStyle={ProjectLabelStyle}
                  onChange={handlePhoneChange}
                  value={phoneInput}
                />
              </div>
            )}
            {mediaOutlet && (
              <div className="row mx-2 mb-4 flex flex-col items-stretch justify-center gap-x-8 uppercase lg:mx-0 lg:flex-row">
                {/* mediaPublicatonName */}
                <FormInput
                  register={{ ...register('00N4v00000BqS6K') }}
                  errorMsg={errors['00N4v00000BqS6K']?.message}
                  labelStyle={ProjectLabelStyle}
                  setValue={setValue}
                  id="MediaOutlet"
                  registeredId="00N4v00000BqS6K"
                  label="Media Outlet/Publication Name*"
                  type='text'
                />
              </div>
            )}
            {/* productPicklist */}
            {pickList && (
              <div className="row row--column row--picklist mx-2 mb-4 flex flex-col items-stretch justify-center gap-x-4 lg:mx-0 lg:flex-row">
                <FormPicklist
                  register={{ ...register('00N1B00000BDRwq') }}
                  errorMsg={errors['00N1B00000BDRwq']?.message}
                  labelStyle={ProjectLabelStyle}
                  setValue={setValue}
                  id="productPicklist"
                  registeredId="00N1B00000BDRwq"
                  label="Product Picklist*"
                  options={productOptions}
                />
              </div>
            )}
            {storeName && (
              <div className="row mx-2 mb-4 flex flex-col items-stretch justify-center gap-x-4 lg:mx-0 lg:flex-row">
                {/* storeName */}
                <FormInput
                  register={{ ...register('00N3000000AY5Wa') }}
                  errorMsg={errors['00N3000000AY5Wa']?.message}
                  labelStyle={ProjectLabelStyle}
                  setValue={setValue}
                  id="storeName"
                  registeredId="00N3000000AY5Wa"
                  label="Store Name*"
                  type='text'
                />
              </div>
            )}
            {storeAddress && (
              <div className="row mx-2 mb-4 flex flex-col items-stretch justify-center gap-x-4 lg:mx-0 lg:flex-row">
                {/* storeAddress */}
                <FormInput
                  register={{ ...register('00N3000000AYgQ9') }}
                  errorMsg={errors['00N3000000AYgQ9']?.message}
                  labelStyle={ProjectLabelStyle}
                  setValue={setValue}
                  id="storeAddress"
                  registeredId="00N3000000AYgQ9"
                  label="Store Address*"
                  type='text'
                />
              </div>
            )}

            {orderNumber && (
              <div className="row mx-2 mb-4 flex flex-col items-stretch justify-center gap-x-4 lg:mx-0 lg:flex-row">
                {/* orderNumber */}
                <FormInput
                  register={{ ...register('00N4v00000C2nYY') }}
                  errorMsg={errors['00N4v00000C2nYY']?.message}
                  labelStyle={ProjectLabelStyle}
                  setValue={setValue}
                  id="orderNumber"
                  registeredId="00N4v00000C2nYY"
                  label="Order Number*"
                  type='text'
                />
                {/* <div className="orderNumber-wrapper flex w-full flex-col">
                    <label
                      htmlFor="00N4v00000C2nYY"
                      className="mx-8 hidden text-black"
                    >
                      Order Number
                    </label>
                    <div className="formCell">
                      <input
                        id="00N4v00000C2nYY"
                        className="form-input mx-auto mt-8 w-full sm:w-[calc(50% - 1.25em)] border-2 border-none bg-[#fbf8ed] px-[0.5em] py-[0.5em] font-avenir-book uppercase text-[#282828] outline-none"
                        placeholder="Order Number"
                        {...register("00N4v00000C2nYY")}
                      />
                      <span className="w-full text-center text-sm text-red-600">
                        {errors["00N4v00000C2nYY"]?.message}
                      </span>
                    </div>
                  </div> */}
              </div>
            )}
            <div className="row mx-2 flex flex-col items-stretch justify-center gap-x-8 lg:mx-0 lg:flex-row">
              {/* inquiryDescription */}
              <FormTextArea
                register={{ ...register('00N4v00000C2nYX') }}
                errorMsg={errors['00N4v00000C2nYX']?.message}
                labelStyle={ProjectLabelStyle}
                setValue={setValue}
                id="inquiryDescription"
                registeredId="00N4v00000C2nYX"
                label="Inquiry Description*"
              />
            </div>
            

            {/* Terms */}
            <div className="row row--terms mb-0 flex flex-col items-stretch justify-center bg-white px-5 pt-5 lg:px-0">
              {/*<div className="mb-0 w-full bg-white px-5 text-sm"></div>*/}
              <div className="terms-wrapper inputWrapper checkbox flex w-full items-center justify-start">
                <input
                  id="00N1B00000BDRxP"
                  className=" conditions font-futura mr-4 w-7 border-2 border-black text-2xl capitalize text-black outline-[#F7941D]"
                  type="checkbox"
                  value="1"
                  {...register('00N1B00000BDRxP')}
                />
                <label className="flex w-full flex-wrap px-[10px] text-black" htmlFor="00N1B00000BDRxP">                  
                  I ACCEPT DIAGEO&apos;S{' '}
                  <a
                    className="px-2 text-pink underline"
                    href={`https://footer.diageohorizon.com/dfs/assets/${windowLocale.replace(
                      /^https?:\/\//,
                      '',
                    )}/TnC_en.html?locale=en-us
                    `}
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    {' '}
                    CONDITIONS OF USE
                  </a>{' '}
                  AND ACKNOWLEDGE THE {' '}
                  <a
                    className="px-2 text-pink underline"
                    href={`https://footer.diageohorizon.com/dfs/assets/${windowLocale.replace(
                      /^https?:\/\//,
                      '',
                    )}/PrivacyPolicy_en.html?locale=en-us`}
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    PRIVACY AND COOKIE
                  </a>{' '}
                  NOTICE
                </label>
              </div>
              <span className="w-full bg-white text-left text-sm text-red-600">
                {errors['00N1B00000BDRxP']?.message}
              </span>
            </div>
            <div className="bg-white">
              <input type="hidden" id="external" name="external" value="1" />
            </div>
            <div className="row row--terms mb-0 flex flex-col items-stretch justify-center bg-white px-5 pt-5 lg:px-0">
                <div id="recaptcha"></div>
            </div>
            {/*<div
              className="g-recaptcha row bg-white px-5 py-10 lg:px-[10px]"
              data-sitekey="6Lf2_uoaAAAAAHmbdTSGUgSTIT2whOY1sDXARlO7"
              data-callback="recaptchaWorks"
            ></div>*/}
            {hasRecaptchaErr && (
              <div className="row row--terms mb-0 flex flex-col items-stretch justify-center bg-white px-5 pt-5 lg:px-0">
                <p className="w-full bg-white text-left text-sm text-red-600">Please confirm that you are not a robot.</p>
              </div>
            )}

            {/* Submit */}
            <div className="form_cell submit-wrapper mx-[22px] flex justify-center sm:mx-8">
              <input
                className="hover-400 w-full max-w-[534px] cursor-pointer bg-yellowColor py-3 px-[10px] font-organetto text-[36px] font-normal uppercase tracking-[.2em] text-white  outline outline-1 outline-yellowColor hover:bg-white hover:text-yellowColor"
                type="submit"
                value="Submit"
              />
            </div>
          </form>
        )}
        {success && (
          <div className="flex min-h-[400px] flex-col items-center justify-center bg-white">
            <h2 className="bg-white text-center font-organetto text-6xl font-bold uppercase tracking-[.2em] text-red sm:text-8xl">
              THANK YOU
            </h2>
          </div>
        )}
      </div>
    </section>
  )
}

export default FormContact

