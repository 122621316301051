import {FooterComponents, HeaderComponents} from "global/services/components";
import renderElement from "global/services/render-element";

const buildChildElements = (childElements) => {

    if (childElements) {

        return childElements.map((child, index) => renderElement(child.contentName, {fields: child.fields}, index));

    }

    return [];

};

const groupRecipe = (contents) => {

    const recipeComponents = [
        "Ingredients",
        "Video",
        "Method",
    ];

    const recipeHero = contents.find((component) => component.contentName === "RecipeHero");

    if (!recipeHero) {

        return contents;

    }

    recipeHero.children = contents.filter((component) => recipeComponents.includes(component.contentName));

    return contents.filter((component) => !recipeComponents.includes(component.contentName));

};

const groupComponents = (contents) => groupRecipe(contents);


const buildPage = (data, pageName) => {

    const emptyPage = {
        bodyComponents: [],
        footerComponents: [],
        headerComponents: [],
    };

    if (!data[pageName] || !data[pageName].contents) {

        return null;

    }

    const pageComponents = data[pageName].contents
        .filter((content) => content.contentType === "component")
        .filter((content) => content.enabled);

    const reshuffledPageContents = groupComponents(pageComponents);

    return reshuffledPageContents
        .reduce((page, content, index) => {

            const componentName = content.contentName;
            const {fields} = content;

            const component = renderElement(componentName, {
                children: buildChildElements(content.children),
                fields,
            }, index);

            if (componentName in HeaderComponents) {

                page.headerComponents.push(component);

            } else if (componentName in FooterComponents) {

                page.footerComponents.push(component);

            } else {

                page.bodyComponents.push(component);

            }

            return page;

        }, emptyPage);

};

export default buildPage;
