import {BlockingNavLink} from "global/utils/blocking-link";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

export default ({fields, index}) => {

    const {title, url, inactive, newTab, track, path} = fields;
    return (
        <li
            role="none"
            className={`navigation__nav-item navigation__nav-item--${index + 1}`}
        >
            <a
              className={`navigation__nav-link ${inactive && inactive === true ? 'navigation__nav-link--inactive' : ''} ${track && track === true ? 'btn--ga' : ''}`}
               data-text={title}
              data-name={title}
              data-path={path || ''}
              data-section='navigation'
              data-url={url}
              href={url}
              role="menuitem"
              target={newTab? '_blank':undefined}
            >
              {title}
            </a>
        </li>
    );

};
