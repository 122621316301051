
export const getScrollTop = () => document.body.scrollTop || document.documentElement.scrollTop;

export const elementFocusWithoutScroll = (element) => {

    const position = getScrollTop();

    element.focus();
    window.scrollTo(0, position);

};

const getElementHeightBySelector = (selector) => {

    const topElement = document.querySelector(selector);

    if (topElement) {

        const topElementBounds = topElement.getBoundingClientRect();


        return topElementBounds.height;

    }

    return 0;

};

// Compensate for scrolling with header/navigation offset
export const calculateElementOffsets = (topElementSelectors) => {

    let offset = 0;

    topElementSelectors.forEach((selector) => {

        offset += getElementHeightBySelector(selector);

    });

    return offset;

};
