import React, { useState, ReactNode } from "react"

const FormSelect = (props) => {
  const { errorMsg,
  register,
  labelStyle,
  half,
  setValue,
  id,
  registeredId,
  label,
  children,
  onChange, } = props

  const [select, setSelect] = useState("")
  const [focus, setFocus] = useState(false)
  return (
    <div className="fieldwrap w-full">
      <div className="inputWrapper labeled relative w-full lg:mx-2">
        <div>
          <label
            htmlFor={id}
            className={`inputlabel inputlabel--empty absolute pointer-events-none uppercase cursor-text ml-3 mt-4 md:mt-0 transition-all duration-500 ${
              focus
                ? `${
                    labelStyle === "inside"
                      ? "-left-1 -top-3 md:left-0 md:top-0"
                      : "bg-form -top-5 left-1 md:-top-1 md:left-3"
                  } text-[.656rem] text-black md:text-[.7878rem]`
                : `${
                    labelStyle === "inside" ? "top-0 md:top-3" : "top-0 md:top-5"
                  } left-2 text-2xl  text-black/40  ${
                  select !== "" && select !== "none" && "opacity-0"
                }`
          }`}
        >
            {label}
          </label>
          <select
            id={id}
            aria-describedby="stateError"
            className={`formInput input webkit-none font-futura w-full border-2 border-black py-3 px-5 text-2xl uppercase text-black outline-[#F7941D] ${
              labelStyle === "inside"
                ? "bg-formInput py-3 md:py-4"
                : "bg-form py-2 md:py-3"
            } w-full rounded-none`}
            onFocus={() => {
              setFocus(true)
            }}
            onBlurCapture={() => {
              setFocus(false)
            }}
            {...register}
            onChange={(e) => {
              onChange(e)
              setSelect(e.target.value)
              setValue(registeredId, e.target.value)
            }}
          >
            {children}
          </select>
        </div>        
      </div>
      <span
          id={`${id}Error`}
          role="alert"
          className="mx-auto w-full text-center text-sm text-red-600"
        >
        {errorMsg}
      </span>
    </div>
  )
}

export default FormSelect
