/* eslint-disable max-lines */
/* eslint-disable consistent-return */
/* eslint-disable id-length */
/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-empty-function */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable max-len, max-lines, no-console, no-return-assign, class-methods-use-this, max-statements */
/* globals mapboxgl, MapboxGeocoder */
import "./style.scss";
import $ from "jquery";
import React from "react";
import fetch from "isomorphic-fetch";
import {load} from "recaptcha-v3";

import moment from "moment";
import * as routeList from "global/utils/routing";

const cn = require("classnames");

class GameForm extends React.Component {

    constructor (props) {
      super(props);
      this.findLink = "";

      this.state = {
          dobErr: false,
          errorMessage: "",
          formError: false,
      };
    }

    updateErrorMessage (message) {

      const errorDiv = document.getElementById("errorMessage");

      errorDiv.innerHTML = message;
      this.state.errorMessage = message;
    }

    checkAge (dob) {

      /* [SEARCH-MINIMUM-AGE] */
      const birthday = moment(dob);

      if (moment().diff(birthday, "years", false) < 18) {
          this.state.dobErr = true;
          $("#CP_DOB").addClass("error");
          $("#dob__error").addClass("showError");
      } else {
          this.state.dobErr = false;
      }
    }

    // eslint-disable-next-line complexity
    async registerUser (evnt) {
        $('#cidbSubmit').prop('disabled', true).addClass('disabled')
        // eslint-disable-next-line func-names
        $(".error").each(function () {
            // eslint-disable-next-line babel/no-invalid-this
            $(this).removeClass("error");
        });

        $("#dob__error").removeClass("showError");
        evnt.preventDefault();

        console.log("form submit");
        const fname = $("#CP_FirstName").val();
        const lname = $("#CP_LastName").val();
        const email = $("#CP_EmailId").val();
        const country = $("#PR_4_92_1").val();
        const dob = $("#CP_DOB").val();
        const tc = $("#tc").prop("checked");
        const checkboxFields = $("#PR_1_64_1").prop("checked");

        let captcha = "";

        this.updateErrorMessage("");
        this.state.formError = false;
        const data = new FormData(document.getElementById("formEnterCode"));
        // Console.log('IN REGISTER USER >>>> data ', data)

        if (!checkboxFields) {
          data.append("PR_1_64_1", "99");
        }
        //Converts formdata to json
        const send = {};

        data.forEach((value, key) => {

          if (key === "HD_PR_1_64_1_Brand" || key === "PR_4_92_1") {
              // eslint-disable-next-line radix
              send[key] = parseInt(value);
          } else if (key === "PR_4_92_1" || key === "tc") {
            if (value === "on") {
                send[key] = "1";
            }
          } else {
            send[key] = value;
          }

        });

        send.CP_WebsiteUrl = window.location.href;
        const json = JSON.stringify(send);

        captcha = send["g-recaptcha-response"];

        if (fname.trim() === "" || lname.trim() === "" || email.trim() === "" || country === null || dob.trim() === "" || tc === false || captcha === "") {

          if (fname.trim() === "") {
            $("#CP_FirstName").addClass("error");
          }

          if (lname.trim() === "") {
            $("#CP_LastName").addClass("error");
          }

          if (email.trim() === "") {
            $("#CP_EmailId").addClass("error");
          }

          if (country === null) {
            $("#PR_4_92_1").addClass("error");
          }

          if (dob.trim() === "") {
            $("#CP_DOB").addClass("error");
          }
          this.state.formError = true;
        }
        console.log("IN REGISTER USER >>>> send ", send);

        this.checkAge(dob);
        //Do post request to api here
        // eslint-disable-next-line no-negated-condition
        if (!this.state.formError) {
          try {
            const res = await fetch("/api/register/add", {
                body: json,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",

                },
                method: "POST",
            // eslint-disable-next-line no-shadow
            }).then((res) => res.json());

            if (res.success) {

              console.log("SUCCESS Add ANS", res);
              $('#cidbSubmit').prop('disabled', false).removeClass('disabled')
              // Redirect if needed
              // Window.location.href = '/de-de/fluchtspiel/abspielen/'

              // In this case, just delete the form and show the success message
              $("#formEnterCode").remove();
              $("#formWrapper").append("<div id=\"thankyou\"><h1 class=\"lead__title\">Thank you!</h1><div class=\"thankyou__copy\">Keep an eye out for exclusive content and the very latest updates from us at Captain Morgan!</div></div>");
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
              'event':'pageView',
              'pagePath': window.location.href +'/thank-you'
              });
                window.dataLayer.push({
                "event": "customEvent",
                "eventCategory": "Opt-in",
                "eventAction": "static",
                "eventLabel": "success",
                "eventInteraction": "true",
              })
            } else {
              const {message} = res;

              console.log("ERROR SUCCESS ANS", res);
              console.log("ERROR SUCCESS ANS", message);
              this.updateErrorMessage(`<div>${message}</div>`);
              this.state.formError = true;
              $('#cidbSubmit').prop('disabled', false).removeClass('disabled')
              // Return res
            }
          } catch (error) {
            console.log("ERROR ERROR ANS", error);
            $('#cidbSubmit').prop('disabled', false).removeClass('disabled')
            return 0;
          }

        } else {

          let errorMsg = "";

          if (fname === "" || lname === "" || email === "" || country === "" || dob === "") {
            errorMsg = "<div>A required field is missing.</div>";
          }

          if (tc === false) {
            errorMsg += "<div>Please accept the terms and conditions and Privacy & Cookie notice.</div>";
          }

          if (captcha === "") {
            errorMsg += "<div>Please answer the Captcha.</div>";
          }
          $('#cidbSubmit').prop('disabled', false).removeClass('disabled')
          this.updateErrorMessage(errorMsg);
        }
    }

    captchaLoad = () => {
      load("6Lf5-30cAAAAAAhYAb4XHcLZFh-8sj_OHetPhcvt", {
          explicitRenderParameters: {
              container: "recaptcha",
          },
      });
    }

    componentDidMount () {
      //JS goes here
      window.addEventListener("load", this.captchaLoad());
    }

    render () {
      const gameAssets = routeList.getSectionAssets(this.props.location.pathname)
      const formCopy = gameAssets && gameAssets.gameform && gameAssets.gameform.copy ? gameAssets.gameform.copy : ''
      const formTitle = gameAssets && gameAssets.gameform && gameAssets.gameform.title ? gameAssets.gameform.title : ''
      const formTermsCopy = gameAssets && gameAssets.gameform && gameAssets.gameform.termscopy ? gameAssets.gameform.termscopy : "I accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_de.html?locale=de-de' target='_blank'>Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_de.html?locale=de-de' target='_blank'>Privacy and Cookie Notice</a>. *"
      const formNewsCopy = gameAssets && gameAssets.gameform && gameAssets.gameform.newslettercopy ? gameAssets.gameform.newslettercopy : 'Click here if you would like to receive special offers and news from Captain Morgan by email. You can unsubscribe at any time'
      const promoCode = gameAssets && gameAssets.gameform && gameAssets.gameform.promocode ? gameAssets.gameform.promocode : 'DWE028SMI0721WC0005'
      const countryVal = gameAssets && gameAssets.gameform && gameAssets.gameform.countryval ? gameAssets.gameform.countryval : 0
      
      return (
      	<>
      		<section className="GameForm" >

    		    <div className="leftPanel">
  		        <div className="inner">

		            <div className="heading" dangerouslySetInnerHTML={{
                  __html: `${formTitle}`
                  }}
                />

		            <div className="copy" dangerouslySetInnerHTML={{
                  __html: `${formCopy}`
                  }}
                />

  		        </div>
    		    </div>

    		    <div className="rightPanel">
  		        <div className="inner">
  		            <div className="formWrapper" id="formWrapper">
  		                <form id="formEnterCode" runat="server" action="/api/CIDBapi_Form">
  		                    <input id="PR_4_367_1_freetext" name="PR_4_367_1_freetext" type="hidden" placeholder="" runat="server"value="" />
  		                    <input id="HD_PromoCode" name="HD_PromoCode" runat="server" type="hidden" value={promoCode} />

  		                    <input id="HD_Consumerid" name="HD_Consumerid" runat="server" type="hidden" value="" />

  		                  <div className="inputWrapper half">
  		                    <label htmlFor="CP_FirstName">FIRST NAME*</label>
  		                  	<input id="CP_FirstName" name="CP_FirstName" type="text" required />
  		                  </div>

  		                  <div className="inputWrapper half">
  		                    <label htmlFor="CP_LastName">LAST NAME*</label>
  		                  	<input id="CP_LastName" name="CP_LastName" type="text" required />
  		                  </div>

  		                  <div className="inputWrapper half">
  		                    <label htmlFor="CP_EmailId">EMAIL*</label>
  		                  	<input id="CP_EmailId" name="CP_EmailId" type="email" required />
  		                  </div>

  		                  <div className="inputWrapper half">
  		                  	<label htmlFor="CP_DOB">DATE OF BIRTH*</label>
                          <input id="CP_DOB" name="CP_DOB" type="date" required />
                              <div id="dob__error" className="dob__error">
                            Please enter a valid birthdate.
                              </div>
  		                  </div>

  		                  <div className="inputWrapper checkbox">
  		                  	<input id="tc" name="tc" type="checkbox" required />
  		                  	<label htmlFor="tc" dangerouslySetInnerHTML={{
                            __html: `${formTermsCopy}`
                            }}
                          />
  		                  </div>

  		                  <div className="inputWrapper checkbox">
  		                  	<input id="PR_1_64_1" name="PR_1_64_1" type="checkbox" required />
  		                  	<label htmlFor="PR_1_64_1" dangerouslySetInnerHTML={{
                            __html: `${formNewsCopy}`
                            }}
                          />
  		                  </div>

  		                    <div className="inputWrapper">
  		                        <div id="recaptcha"></div>
  		                    </div>

  		                    <div className="inputWrapper">
  		                        <div className="errorMessage" id="errorMessage"></div>
  		                    </div>

  		                  <div className="inputWrapper">
                          <input id="PR_4_92_1" name="PR_4_92_1" runat="server" type="hidden" value={countryVal} />
	                        <input id="HD_IsDefaultFlag" name="HD_IsDefaultFlag" runat="server" type="hidden" value="1" />
	                        <input id="HD_PR_1_64_1_Brand" name="HD_PR_1_64_1_Brand" runat="server" type="hidden" value="4" />
	                        <input id="HD_PR_1_64_1_Comm" name="HD_PR_1_64_1_Comm" runat="server" type="hidden" value="1" />
	                        <input id="HD_AddressType" name="HD_AddressType" runat="server" type="hidden" value="1" />

  		                  	<input className="submit" type="submit" value="SUBMIT" id='cidbSubmit' onClick={(evnt) => this.registerUser(evnt)} />
  		                  </div>


  		                </form>
  		            </div>

  		        </div>
    		    </div>

      		</section>
      	</>
      );

    }

}

export default GameForm;
