import './App.scss'
import * as Actions from 'actions/content'
import React, { Component } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import {
  faComment,
  faEnvelope,
  faMapMarkerAlt,
  faThumbsUp
} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebookF,
  faInstagram,
  faPinterestP,
  faTwitter,
  faWhatsapp,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'
import GamePage from './pages/page-game'
import NewsletterModal from './organisms/NewsletterModal'
import ChatWidget from './organisms/chat-widget'
import PageContainer from './pages/page-default'
import PageContainerNewsletter from './pages/page-newsletter'
import PageContainerDECustom from './pages/page-de-custom'
import PageContainerUSCustom from './pages/page-us-custom'
import PageContainerEnjoySlow from './pages/page-enjoyslow'
import PageContainerWTB from './pages/page-where-to-buy'
import PageNfl2023 from './pages/page-nfl-2023'
import PageMap from './pages/page-map'
import PageContainerDrinksWorks from './pages/page-drinks-works'
import PageContactUs from './pages/page-contact-us'
import PreviewPage from './pages/pages-preview'

/*** FTC 2024 ***/
import FTC2024Landing from './pages/ftc-2024/page-landing/'
import FTC2024About from './pages/ftc-2024/page-about/'
import FTC2024Merch from './pages/ftc-2024/page-merch/'
import FTC2024Foty from './pages/ftc-2024/page-foty/'
import FTC2024RSVP from './pages/ftc-2024/page-rsvp/'

import ScrollToTop from 'global/utils/ScrollToTop'
import * as routeList from 'global/utils/routing'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { faSadTear } from '@fortawesome/free-regular-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import withConfig from 'hoc/with-config'

class App extends Component {
  cleanUrl = (url) =>
    url
      .replace(new RegExp('https:/www.drinkiq.com/en-us/', 'g'), '')
      .replace(new RegExp('https:/www.where-to-buy.com/', 'g'), '')
      .replace(
        new RegExp(
          'https:/captainmorganstore.com/store.php/product-detail/captain-morgan-custom-label',
          'g'
        ),
        ''
      )
      .replace(new RegExp('https:/www.captainmorganstore.com/', 'g'), '')

  fetchData = (page) => {
    if (!page.name) {
      return
    }

    const url = `${this.cleanUrl(`${this.props.config.apiUrl}${page.name.toLowerCase()}`).replace(
      /\/+$/,
      ''
    )}.json`

    this.props.actions.fetchContent({
      apiUrl: this.props.config.apiUrl,
      page,
      url
    })
  }

  getChatbotSetting (content, currentPath) {
    let usePath = ''
 
    if(currentPath){
    if (currentPath.substring(0, 1) !== '/') {
      usePath = `/${currentPath}`
    }else{
      usePath = currentPath
    }

    if (currentPath.substring(currentPath.length - 1) !== '/') {
      usePath = `${usePath}/`
    }}

    if (usePath === '') {
      return false
    } else {
      const useLocale = `${usePath}`

      if (content.hasOwnProperty(useLocale)) {
        let useSetting = false
        if(content[useLocale]?.contents?.filter( x => x.contentName === 'EnableChatbot')[0]) {
          const cSetting = content[useLocale]?.contents?.filter( x => x.contentName === 'EnableChatbot')[0]

          if (cSetting.enabled && cSetting.enabled === true) {
            useSetting = cSetting?.fields.length > 0 ? cSetting?.fields[0]?.value : false
          }else{
            useSetting = false
          }
        } else {
          useSetting = false
        }
        return useSetting
      }
    }
  }

  render() {
    const { content } = this.props
    const { defaultMarket, currentPath } = this.props.config
    const gameRoutes = routeList.getRouteList()
    let chatBotEnabled = false
    
    // Define FontAwesome library once here and consume later

    library.add(
      faFacebookF,
      faPinterestP,
      faTwitter,
      faWhatsapp,
      faInstagram,
      faYoutube,
      faComment,
      faEnvelope,
      faSadTear,
      faThumbsUp,
      faMapMarkerAlt
    )
    const isServer = typeof window === 'undefined'
    chatBotEnabled = this.getChatbotSetting(content, currentPath)

    return (
      <ScrollToTop>
        <div className="app-inner">
          {!isServer && (
            <>
              {window.location.pathname.includes('en-us') &&
                !window.location.pathname.includes('newsletter') && <NewsletterModal />}
            </>
          )}
          {!isServer && (
            <>
              {chatBotEnabled === true ? (
                  <>
                    <ChatWidget />
                  </>
                ) : null
              }
            </>
          )}
          <Switch>
            <Route
              path={'/en-gb/enjoyslow'}
              render={(props) => (
                <PageContainerEnjoySlow
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                />
              )}
              key={'en-gb-enjoyslow'}
            />
            <Route
              path={'/en-za/enjoyslow'}
              render={(props) => (
                <PageContainerEnjoySlow
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                />
              )}
              key={'en-za-enjoy-slow'}
            />
            <Route
              path={'/de-de/was-ist-dein-spice'}
              render={(props) => (
                <PageContainerDECustom
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                />
              )}
              key={'de-de-was-ist-dein-spice'}
            />

            {/* DRINKS WORKS */}
            <Route
              exact
              path={'/de-de/drinksworks'}
              render={(props) => (
                <PageContainerDrinksWorks
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                />
              )}
              key={'de-de-drinksworks'}
            />

            <Route
              path={'/en-us/a-taste-of-the-tropics'}
              render={(props) => (
                <PageContainerUSCustom
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                />
              )}
              key={'en-us-taste-of-the-tropics'}
            />
            {/*<Route
                path={"/en-us/a-taste-of-the-tropics/jamaicaqueens"}
                render={(props) => <PageContainerUSCustom
                    data={content}
                    fetchData={this.fetchData}
                    location={props.location}
                />}
            />
            <Route
                path={"/en-us/a-taste-of-the-tropics/sanjuancapistrano"}
                render={(props) => <PageContainerUSCustom
                    data={content}
                    fetchData={this.fetchData}
                    location={props.location}
                />}
            />*/}
            <Route
              path={'/en-us/a-taste-of-the-tropics/jamaicaplain'}
              render={(props) => (
                <PageContainerUSCustom
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                />
              )}
              key={'en-us-taste-jamaicaplain'}
            />
            <Route
              path={'/en-us/newsletter'}
              render={(props) => (
                <PageContainerNewsletter
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                />
              )}
              key={'en-us-newsletter'}
            />
            <Route
              path={'/en-us/where-to-buy/shop-online'}
              render={(props) => (
                <PageContainerWTB
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                />
              )}
              key={'en-us-wtb-shop-online'}
            />
            <Route
              path={'/en-us/where-to-buy/find-near-me'}
              render={(props) => (
                <PageContainerWTB
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                />
              )}
              key='en-us-wtb-find-near-me'
            />
            <Route
              exact
              path="/"
              render={() => <Redirect exact from="/" to={`/${defaultMarket}/`} />}
              key={`market-${defaultMarket}`}
            />

            <Route
              exact
              path="/preview"
              render={(props) => (
                <PreviewPage data={content} fetchData={this.fetchData} location={props.location} />
              )}
              key={'preview'}
            />
            {/*<Route
              exact
              path="/followthecaptain"
              render={(props) => (
                <PageNfl2023 data={content} fetchData={this.fetchData} location={props.location} />
              )}
              key={'nfl-2023'}
            />*/}
            <Route
              exact
              path="/followthecaptain"
              render={(props) => (
                <FTC2024Landing data={content} fetchData={this.fetchData} location={props.location} />
              )}
              key={'ftc-2024'}
            />
            <Route
              exact
              path="/followthecaptain/about"
              render={(props) => (
                <FTC2024About data={content} fetchData={this.fetchData} location={props.location} />
              )}
              key={'ftc-2024-about'}
            />
            <Route
              exact
              path="/followthecaptain/nfl-fan-of-the-year"
              render={(props) => (
                <FTC2024Foty data={content} fetchData={this.fetchData} location={props.location} />
              )}
              key={'ftc-2024-foty'}
            />
            <Route
              exact
              path="/followthecaptain/something-super"
              render={(props) => (
                <FTC2024Merch data={content} fetchData={this.fetchData} location={props.location} />
              )}
              key={'ftc-2024-something-super'}
            />
            <Route
              exact
              path="/followthecaptain/rsvp"
              render={(props) => (
                <FTC2024RSVP data={content} fetchData={this.fetchData} location={props.location} />
              )}
              key={'ftc-2024-foty'}
            />
            <Route
              exact
              path="/followthecaptain/gasparilla"
              render={(props) => (
                <PageMap data={content} fetchData={this.fetchData} location={props.location} />
              )}
              key={'gasparilla'}
            />

            {/*** CONTACT US ***/}
            <Route
              exact
              path={'/en-us/contact-us'}
              render={(props) => (
                <PageContactUs
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                />
              )}
              key={'contact-us-en-us'}
            />

            {gameRoutes &&
              gameRoutes.length > 0 &&
              gameRoutes.map((gameRoute, i) => (
                <Route
                  path={gameRoute}
                  render={(props) => (
                    <GamePage
                      data={content}
                      fetchData={this.fetchData}
                      location={props.location}
                      key={`gameRoute_${i}`}
                    />
                  )}
                />
              ))}

            <Route
              path={'/'}
              render={(props) => (
                <PageContainer
                  data={content}
                  fetchData={this.fetchData}
                  location={props.location}
                />
              )}
              key={'page'}
            />
          </Switch>
        </div>
      </ScrollToTop>
    )
  }
}

const mapStateToProps = (state) => ({ content: state.content.pages || {} })

const mapDispatchToProps = (dispatch) => ({ actions: bindActionCreators(Actions, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(withConfig(App))
