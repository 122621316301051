import "./recipe-promo.scss";
import {BlockingLink} from "global/utils/blocking-link";
import Cta from "../../atoms/cta";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import sanitise from "global/utils/sanitise";

const RecipeCard = (props) => {

    const Card = props.fields.find((field) => field.title === "ContentCard");
    const Intro = props.fields.find((field) => field.title === "Intro");

    const {
        CardImage,
        CardTitle,
        CardSummary,
        CardCtaText,
        Url,
        NewWindow,
    } = fieldsArrayToKeyValueHash(Card.fields);

    const cardImageProps = fieldsArrayToKeyValueHash(CardImage);

    if (!CardTitle || !CardCtaText || !Url) {

        return false;

    }

    const cta = {
        fields: [
            {
                title: "Link",
                value: Url,
            },
            {
                title: "Caption",
                value: CardCtaText,
            },
            {
                title: "NewWindow",
                value: NewWindow,
            },
        ],
    };

    return (
        <div className="recipe-promo">
            <div className="recipe-promo__inner">
                <div className="recipe-promo__item">
                    <div className="recipe-promo__visual">
                        {
                            <BlockingLink to={Url} className="recipe-promo__visual-link" title={CardTitle}>
                                <div className="recipe-promo__visual-image"
                                    style={{backgroundImage: `url(${cardImageProps.Url})`}}
                                />
                            </BlockingLink>
                        }
                    </div>
                    <div className="recipe-promo__content">
                        <h2 className="recipe-promo__heading">
                            <span className="recipe-promo__heading-pre-title">
                                {Intro.value}
                            </span>
                            <span className="recipe-promo__heading-title">
                                {CardTitle}
                            </span>
                        </h2>
                        {
                            CardSummary &&
                                <div className="recipe-promo__copy"
                                    dangerouslySetInnerHTML={{__html: sanitise(CardSummary)}}
                                />
                        }
                        <Cta cta={cta} />
                    </div>
                </div>
            </div>
        </div>
    );

};

export default RecipeCard;
