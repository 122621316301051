import "./style.scss";
import React from "react";

import treasure from 'global/images/ftc2024/treasure_popup.png'
import btnBack from 'global/images/ftc2024/btn__backtomap.png'
import btnPlay from 'global/images/ftc2024/btn__play.png'

const PopupLayer = (props) => {
  return (
    <div className="popuplayer" tabIndex="1" id="popupLayer">
      <div className="popuplayer__container">
        <button
          className="btn backtomap"
          onClick={(e) => props.closeHandler(e)}
        >
          <img src={btnBack} alt="Back to map" aria-label="Back to map" />
          <span className="sr-only">{`<`} BACK TO MAP</span>
        </button>
        <div className="popuplayer__wrap">
          <div className="popuplayer__image">
            <img src={treasure} aria-hidden="true" />
          </div>
          <div className="popuplayer__title">
            Are you following the Captain?
          </div>
          <div className="popuplayer__copy">
            Enter for a chance to win legendary prizes like Exclusive Tickets, Team Swag, Unique Experiences and a chance to win tickets to <strong>Super Bowl LIX!</strong>
          </div>
          <div className="popuplayer__cta">
            <button 
              className="btn btn--reset btn--play"
              id="plEnterNow"
              onClick={(e) => props.closeHandler(e)}
            >
              <img src={btnPlay} alt="Enter Now" aria-label="Enter Now" id="plEnterNow--image" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupLayer;
