/* eslint-disable require-jsdoc, func-style */
import {
    FETCH_CONTENT_REQUEST,
    contentError,
    contentResponse,
} from "actions/content";
import {call, put, takeEvery} from "redux-saga/effects";
import {fetchContent} from "services/content";

function *requestContent (action) {

    const {apiUrl, url, page} = action.payload;

    let contentUrl = url;

    if (!(/^https?:\/\//).test(contentUrl)) {

        contentUrl = apiUrl + contentUrl;

    }

    if (page.isPreview) {

        contentUrl += "?mode=preview";

    }

    try {

        const response = yield call(fetchContent, contentUrl);

        yield put(contentResponse({
            page,
            ...response,
        }));

    } catch (error) {

        yield put(contentError({
            error,
            page,
        }));

    }

}

export function *watchContentRequest () {

    yield takeEvery(FETCH_CONTENT_REQUEST, requestContent);

}
