import "./event-list.scss";
import EventCard from "../../molecules/event-card";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import sanitise from "global/utils/sanitise";

const EventList = (props) => {

    const {
        NoEventsAvailableText,
        Subtitle,
        Title,
    } = fieldsArrayToKeyValueHash(props.fields);
    const EventGroups = props.fields.filter((group) => group.title === "Group");

    const renderEventGroup = (eventGroup, position) => {

        const {Key} = fieldsArrayToKeyValueHash(eventGroup.fields);
        const EventGroupEvents = eventGroup.fields.find((group) => group.title === "EventList");

        let singleEventClass = "";

        if (!EventGroupEvents) {

            return null;

        }

        if (EventGroupEvents.fields.length === 1) {

            singleEventClass = "event-list__group-listing--single";

        }

        return (
            <section key={`event-group-${position}`} className="event-list__group">
                {
                    Key &&
                        <header className="event-list__group-header">
                            <h3 className="event-list__group-title">
                                {Key}
                            </h3>
                        </header>
                }
                <ul className={`event-list__group-listing ${singleEventClass}`}>
                    {
                        EventGroupEvents.fields.map((event, index) => <li
                            key={`event-${index}`}
                            className="event-list__group-listing-item"
                        >
                            <EventCard event={event} />
                        </li>)
                    }
                </ul>
            </section>
        );

    };

    const renderEventGroupsOrError = () => {

        if (EventGroups.length > 0) {

            return EventGroups.map((eventGroup, index) => renderEventGroup(eventGroup, index));

        }

        return <div
            className="event-list__empty"
            dangerouslySetInnerHTML={{__html: sanitise(NoEventsAvailableText)}}
        />;

    };

    return (
        <section className="event-list">
            <div className="event-list__inner">
                {
                    Title && Subtitle &&
                        <header className="event-list__header">
                            {
                                Title &&
                                    <h2 className="event-list__title">{Title}</h2>
                            }
                            {
                                Subtitle &&
                                    <div
                                        className="event-list__summary"
                                        dangerouslySetInnerHTML={{__html: sanitise(Subtitle)}}
                                    />
                            }
                        </header>
                }
                {
                    renderEventGroupsOrError()
                }
            </div>
        </section>
    );

};

export default EventList;
