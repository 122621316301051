import "./style.scss";
import Cta from "../../atoms/cta";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import sanitise from "global/utils/sanitise";

import iconChatbot from 'global/images/chatbot/icon--chatbot.svg'


const cn = require('classnames')

const HeadingWithContent = (props) => {
  if (props && props.fields) {
    const {
      BottomPadding,
      CtaCollection,
      ContentCopy,
      ContentTitle,
      TopPadding,
    } = fieldsArrayToKeyValueHash(props.fields);
    const data = fieldsArrayToKeyValueHash(props.fields);
    const botPad = BottomPadding && BottomPadding !== '' ? BottomPadding : ''
    const topPad = TopPadding && TopPadding !== '' ? TopPadding : ''

    return (
      <section className={`hwcblock ${topPad !== '' ? `hwcblock--top${topPad}` : ''} ${botPad !== '' ? `hwcblock--bot${botPad}` : ''}`}>
        <div className="container container--1600">
          <div className="bannerblock__wrap">
            <div className={`content`}>
              {ContentTitle ? (
                  <>
                    <h2 className={`content__title`}>                      
                      {ContentTitle}
                    </h2>
                  </>
                ) : null
              }
              
              <div className={`content__copy`} dangerouslySetInnerHTML={{__html: `${ContentCopy}`}} />
              <div className="content__cta">
                {CtaCollection && CtaCollection.map((ctaInstance, index) => <Cta
                    key={`cta-${index}`}
                    cta={ctaInstance}
                    version="primary"
                    varient={index && "ghost"}
                />)}
              </div>
            </div>
          </div>
        </div>
      </section>
    );

  }

  return false;
};

export default HeadingWithContent;
