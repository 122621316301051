/* eslint-disable max-lines */
/* eslint-disable consistent-return */
/* eslint-disable id-length */
/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-empty-function */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable max-len, max-lines, no-console, no-return-assign, class-methods-use-this, max-statements */
/* globals mapboxgl, MapboxGeocoder */
import "./style.scss";
import $ from "jquery";
import React from "react";
import TermsBg from 'global/images/de_de_comp_game/enter_middle_bg_desktop.jpg'
import * as routeList from "global/utils/routing";

const cn = require("classnames");

class GameTerms extends React.Component {

    constructor (props) {
      super(props);
    }

    renderFaqItem (rule, index) {
      const title = rule && rule.faqheading && rule.faqheading !== '' ? rule.faqheading : ''
      const copy = rule && rule.faqcopy && rule.faqcopy !== '' ? rule.faqcopy : ''

      return (         
        <li key={`faqlistitem_${index}`}>
          <h3 className='faq__title'>
            {title}
          </h3>
          <div className='faq__copy' dangerouslySetInnerHTML={{
                    __html:`${copy}`  }} />
        </li>
      )
    }

    componentDidMount () {
      //JS goes here
    }

    render () {
      const gameAssets = routeList.getSectionAssets(this.props.location.pathname)
      const termsHeader = gameAssets && gameAssets.gameterms && gameAssets.gameterms.heading ? gameAssets.gameterms.heading : 'Captain Morgan Escape Room Game'
      const termsCopy = gameAssets && gameAssets.gameterms && gameAssets.gameterms.headingcopy ? gameAssets.gameterms.headingcopy : ''
      const termsList = gameAssets && gameAssets.gameterms && gameAssets.gameterms.listcopy ? gameAssets.gameterms.listcopy : []
      const faqTitle = gameAssets && gameAssets.gameterms && gameAssets.gameterms.faqtitle ? gameAssets.gameterms.faqtitle : '[ FAQ ]'
      const faqList = gameAssets && gameAssets.gameterms && gameAssets.gameterms.faqlist ? gameAssets.gameterms.faqlist : []
      const termsBg = {
        backgroundImage: `url(${TermsBg})`
      }
      
      return (
      	<>
      		<section className="gametnc" style={termsBg}>
		        <div className="container">
              <div className="inner">

  	            <div className="entry__wrap">
                  <h1 dangerouslySetInnerHTML={{
                    __html: `${termsHeader}`
                    }}
                  />
                </div>

                <div className="custom_hr" role="presentation"></div>

                <div className="terms__copy">
                  <div dangerouslySetInnerHTML={{
                    __html: `${termsCopy}`
                    }}
                  />
                  <br />
                  <ol>
                    {termsList && termsList.length > 0 && termsList.map((item, i) =>
                      <li key={`listitem${i}`} dangerouslySetInnerHTML={{
                        __html: `${item}`
                        }}
                      />
                    )}
                  </ol>
                </div>

                <div className="entry__wrap">
                  <h1 dangerouslySetInnerHTML={{
                    __html: `${faqTitle}`
                    }}
                  />
                </div>

                <div className="custom_hr" role="presentation"></div>

                <div className="terms__copy">                  
                  <ul>
                    {faqList && faqList.length > 0 && faqList.map((item, i) =>
                      this.renderFaqItem(item, i)
                    )}
                  </ul>
                </div>
  		        </div>
            </div>
      		</section>
      	</>
      );

    }

}

export default GameTerms;
