/* eslint-disable no-magic-numbers */
/* eslint-disable max-statements */
/* eslint-disable no-console */
import "./page-default.scss";
import $ from 'jquery'
import React, {Component} from "react";
import {Helmet} from 'react-helmet'
import AppError from "../../organisms/app-error";
import sanitise from "global/utils/sanitise";
import buildPage from "../page-builder";
import heroImage from 'global/images/drink-slow/drink-slow-header.jpg'
import heroImageGB from 'global/images/drink-slow/drink-slow-header-gb.jpg'
import watermark from 'global/images/de_de_comp_game/cm-watermark.png'
import redBackground from 'global/images/backgrounds/german-red-background.jpg'
import ballroomVideo from 'global/images/de_de_comp_game/ballroom-video.mp4'
import playButton from 'global/images/drink-slow/play-button.png'
import videoPlaceholder from 'global/images/drink-slow/video-placeholder.jpg'
import videoSplash from 'global/images/drink-slow/watch-the-video.png'
import btsPlaceholder from 'global/images/drink-slow/bts-placeholder.jpg'
import btsSplash from 'global/images/drink-slow/watch-the-bts.png'

export default class PageContainer extends Component {

    state = {
        "isLoaded": false,
        "setRoutes": false,
        "video":""
    };

    constructor (props) {

        super(props);
        this.fetchData();

    }

    getPageName () {

        const path = this.props.location.pathname
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'>/g, "&#x27;");

        if (path.endsWith("/")) {

            return path;

        }

        return `${path}/`;

    }

    fetchData () {

        const pageName = this.getPageName();

        if (!this.props.data[pageName]) {

            // No! Lots wrong here:
            // - whatabout fetching data?
            // - Transition should be blocked before
            //   We have the data to avoid rendering noop
            this.props.fetchData(
                {name: pageName},
                false
            );

        }

    }

    openVideoModal (partner) {
        $("#buyNowModal").addClass("active");
        state.video = partner
        console.log(state.video)
    }

    componentDidUpdate (prevProps) {

        const pageName = this.getPageName();
        const newLocation = this.props.location.pathname;
        const {data} = this.props;

        if (prevProps.location.pathname !== newLocation && !data[pageName]) {

            this.props.fetchData(
                {name: newLocation},
                false
            );

        }

    }
      
    render () {

        const {data} = this.props;

        const pageName = this.getPageName();



        if (!data[pageName] || data[pageName].isFetching) {

            // In future, avoid this!
            return null;

        }

        const page = buildPage(data, pageName);

        // IF the API is unreachable
        // OR the page response is not as expected
        // BuildPage will return null
        // Without this the App will error server side and bring down the node server
        if (page === null) {

            return (
                <div className="page">
                    <AppError
                        message="This site is currently unavailable - Come back soon."
                    />
                </div>
            );


        }
     
        const playVideo = () =>{
            
            var videoPlayer = new Vimeo.Player('slowVideo');
            videoPlayer.play().then(() => {
                    document.getElementById("slowVideoPlaceholder").style.display="none"
                    document.getElementById("slowVideoPlay").style.display="none"
                    document.getElementById("slowVideoSplash").style.display="none"
            });          
           
        }
          const playBts = () =>{
            
            var videoPlayer = new Vimeo.Player('slowBts');
            videoPlayer.play().then(() => {
                    document.getElementById("slowBtsPlaceholder").style.display="none"
                    document.getElementById("slowBtsPlay").style.display="none"
                    document.getElementById("slowBtsSplash").style.display="none"
            });          
           
        }
        let locale
        if(this.props.location.pathname.includes("en-gb")){
          locale="en-gb"
        } else {
              locale="en-za"
        }
        page.headerComponents[0].props.fields[0].value = "Enjoy Slooow and Drink Responsibly | Captain Morgan";
        page.headerComponents[0].props.fields[4].value = "200";
         
        return (
            <div className="page-slow">
                 <Helmet>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                 </Helmet>
                 {page.headerComponents}
                <header className="hero">
              
                <div className="hero__background" style={{backgroundImage: `url(${locale==="en-gb"? heroImageGB :heroImage})`}} />
                <div className="hero__content">
                    <h1 className="hero__title gold_font">enjoy slooow and <br/> drink responsibly</h1>
                            <div
                                className="hero__summary"
                                dangerouslySetInnerHTML={{__html: sanitise("Enjoy Slow is the new campaign from Captain Morgan encouraging you to sip at your own pace. In collaboration with modern day icon Bree Runway, we urge you to drink responsibly and find your own rhythm of the night. Like Bree says: \"less alcohol doesn’t mean less fun!\"")}}
                            />
                    </div>
                </header>
              
                <main className="page__content" id="main" role="main">
                     <section  style={{backgroundImage: `url(${redBackground})`}} className="slow" >
                        <div className="gift-row">
                            <div className="left">
                                <h2>Enjoy Slooow</h2>
                                 <p>At Captain Morgan, we want you to have more fun. By slowing down. Peer pressure and excessive alcohol consumption? No thanks! We want to contribute to a positive drinking culture where moderation is the norm. So sipping at your own pace, staying true to your vibe and enjoying the moment, are all that matter. We call it: "Enjoy Slow". Yep, we’re slowing it all the way down. Our new campaign with Bree Runway is about embracing all of your spice and enjoying moderation by taking it nice and slooow. Not being afraid to say no to a drink, not getting pulled into having another round, but drinking at your own pace. Because however you spice, spice on!</p>
                            </div>
                            <div className="right">
                                  <div className="vimeo-video">
                                      <img id="slowVideoPlaceholder" src={videoPlaceholder} className="video-placeholder" alt="Video placeholder image" />
                                      <img id="slowVideoPlay" src={playButton} className="play-button" onClick={()=>playVideo()} alt="Play Button" role="button" />
                                    <iframe id="slowVideo" allow="autoplay" title="Enjoy Slooow video" aria-label="Enjoy Slooow video" src={locale==="en-gb"? "https://player.vimeo.com/video/821661552?h=cd84276c0d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&byline=0&title=0&portrait=0" :"https://player.vimeo.com/video/807926259?h=e485e0e2e9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&byline=0&title=0&portrait=0"}  />
                                    <img id="slowVideoSplash" src={videoSplash} className="video-splash" aria-hidden="true" />
                                    </div>
                            </div>
                            
                        </div>
                         <img src={watermark} className="watermark" aria-hidden="true" />
                   </section>
                   <section className="video-section" >
                    <h2>Tips and Tricks <br/> on Moderation</h2>
                        <div className="video-row">
                            <a className="partner" target={"_blank"} href={`https://www.drinkiq.com/${locale}/drinking-and-your-mind/what-does-it-mean-to-be-a-mindful-drinker/`}>
                                <h2>Mindful <br/> <span className="gold_font">drinking</span></h2>
                                <p>Being mindful doesn’t only mean yoga and meditation. Mindful drinking is about being thoughtful around the drink you’re having in the present, rather than thinking ahead to the next one. </p>
                                                            
                            </a>
                             <a className="partner" target={"_blank"}   href={`https://www.drinkiq.com/${locale}/drinking-and-your-mind/how-does-drinking-affect-mood-and-behaviour/`}>
                                <h2>It's all about <br/> <span className="gold_font">balance</span></h2>
                                <p> Drinking moderately can fit within a balanced lifestyle. It gives you the opportunity to enjoy, celebrate and have fun with friends and family while still freely doing your thing!</p>
                            </a>
                             <a className="partner" target={"_blank"}   href={`https://www.drinkiq.com/${locale}/drinking-and-your-mind/when-it-comes-to-drinking-what-is-normal/`}>
                                <h2>Follow your own <br/> <span className="gold_font">rhythm</span></h2>
                                <p>If you’re around friends or in environments where people are drinking more than you are…Never be afraid to say no to a drink or another round. Remember to follow the rhythm of your own night.</p>
                            </a>
                            
                        </div>
                        <div className="drinkiq">
                            <h3>Visit DrinkIQ to find out more.</h3>
                            <a className="cta" href="https://www.drinkiq.com" target="_blank">Find Out More</a>
                        </div>
                       
                   </section>
                    <section  style={{backgroundImage: `url(${redBackground})`, overflow: "hidden"}} className="" >
                        <div className="gift-row">
                            <div className="left">
                               <div className="vimeo-video">
                                 <img id="slowBtsPlaceholder"  src={btsPlaceholder} className="video-placeholder" alt="Video placeholder image" />
                                  <img id="slowBtsPlay" src={playButton} className="play-button" onClick={()=>playBts()} alt="Play Video" role="button" />
                                <iframe id="slowBts" allow="autoplay" title="Behind the scenes video" aria-label="Behind the scenes video" src={locale==="en-gb"?"https://player.vimeo.com/video/807593662?h=f0096433b6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&&byline=0&title=0&portrait=0":"https://player.vimeo.com/video/807926679?h=5c962a685d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&byline=0&title=0&portrait=0" } />
                                <img id="slowBtsSplash" src={btsSplash} className="bts-splash" aria-hidden="true" />
                                </div>
                            </div>
                            <div className="right">
                                <h2>Behind the Scenes</h2>
                                <p>Here’s an exclusive look behind the scenes of all that went down on set shooting our Enjoy Slow campaign with the iconic Bree Runway. Get acquainted with Bree as she speaks about moderation, how her DIY route into music inspired her remix of the classic song, and more. </p>
                               
                            </div>
                            
                        </div>
                        
                   </section>
                  
            
                </main>

             
            </div>
        );

    }

}
