import "./recipe-hero.scss";
import AnchorObserver from "global/utils/anchor-observer";
import BackgroundHero from "../../molecules/hero-background";
import BuyBanner from "../../organisms/buy-banner";
import React from "react";
import SubNav from "./subnav";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import sanitise from "global/utils/sanitise";

const RecipeHero = (props) => {

    const subNavigationCtas = props.fields.find((field) => field.title === "CtaCollection");

    const {
        HeroTitle,
        HeroSummary,
        Image,
        VideoMedia,
    } = fieldsArrayToKeyValueHash(props.fields);

    const stickyElementList = [
        ".navigation",
        "#recipe__navigation",
    ];

    const anchorLinks = subNavigationCtas.fields.map((cta) => {

        const {Link} = fieldsArrayToKeyValueHash(cta.fields);

        return Link;

    });

    return (
        <>
        <section className="recipe">
            <AnchorObserver anchors={anchorLinks} stickyElementList={stickyElementList}>
                <header className="recipe-hero">
                    <BackgroundHero image={Image} video={VideoMedia} />
                    <div className="recipe-hero__shim" />
                </header>

                <SubNav links={subNavigationCtas.fields}/>

                {
                    HeroTitle &&
                    <header className="recipe-overview" tabIndex="-1" id="overview">
                        <div className="recipe-overview__inner">
                            <h1 className="recipe-overview__title ">{HeroTitle}</h1>
                            {
                                HeroSummary &&
                                    <div
                                        className="recipe-overview__summary"
                                        dangerouslySetInnerHTML={{__html: sanitise(HeroSummary)}} />
                            }
                        </div>

                    </header>
                }

                {props.children}

            </AnchorObserver>
        </section>
        <BuyBanner/>
        </>
    );

};

export default RecipeHero;
