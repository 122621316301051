import GameHeroDE from 'global/images/de_de_comp_game/german-game-header.webm'
import GameHeroMP4DE from 'global/images/de_de_comp_game/german-game-header.mp4'
import GameVideoMP4DE from 'global/images/de_de_comp_game/game_cm_landing_de.mp4'
import MintCoolerWebMDE from 'global/images/de_de_comp_game/german-mint-cooler.webm'
import MintCoolerMP4DE from 'global/images/de_de_comp_game/german-mint-cooler.mp4'
import MintCoolerMP4EG from 'global/images/de_de_comp_game/english-mint-cooler.mp4'
import MintCoolerMP4FR from 'global/images/de_de_comp_game/french-mint-cooler.mp4'
import MintCoolerMP4DT from 'global/images/de_de_comp_game/dutch-mint-cooler.mp4'
import MintCoolerMP4PL from 'global/images/de_de_comp_game/poland-mint-cooler.mp4'
import MintCoolerPosterDE from 'global/images/de_de_comp_game/german-mint-cooler-poster.jpg'
import EnterCodeHeaderBgDE from 'global/images/de_de_comp_game/enter_header_bg.jpg'
import EnterCodeHeaderTimeDE from 'global/images/de_de_comp_game/enter_header_time.png'
import EnterCodeHeaderHandDE from 'global/images/de_de_comp_game/enter_header_hand.png'
import HeaderAnimationDE from 'global/images/de_de_comp_game/header_animation.gif'
import HeaderAnimationEN from 'global/images/de_de_comp_game/header_animation_en.gif'
import HeaderAnimationDT from 'global/images/de_de_comp_game/header_animation_dt.gif'
import HeaderAnimationFR from 'global/images/de_de_comp_game/header_animation_fr.gif'
import HeaderAnimationPL from 'global/images/de_de_comp_game/header_animation_pl.gif'
import CodeHeadingImageDE from 'global/images/de_de_comp_game/welcome-aboard.png'
import CodeHeadingImageEN from 'global/images/de_de_comp_game/welcome-aboard_en.png'
import CodeHeadingImageSV from 'global/images/de_de_comp_game/welcome-aboard_sv.png'
import CodeHeadingImagePL from 'global/images/de_de_comp_game/welcome-poland.png'
import RulesBuyDE from 'global/images/de_de_comp_game/middle_buy_here.png'
import RulesBuyFR from 'global/images/de_de_comp_game/french-buy.png'
import RulesBuyEN from 'global/images/de_de_comp_game/middle_buy_here_en.png'
import RulesBuySV from 'global/images/de_de_comp_game/middle_buy_here_sv.png'
import RulesBuyDT from 'global/images/de_de_comp_game/dutch-buy.png'
import RulesBuyPL from 'global/images/de_de_comp_game/poland-buy.png'
import UmbrellaGifDE from 'global/images/de_de_comp_game/umbrella_gif_de.gif'
import UmbrellaGifEG from 'global/images/de_de_comp_game/mint-cooler-english-static.png'
import UmbrellaGifSV from 'global/images/de_de_comp_game/mint-cooler-slovak-static.png'
import UmbrellaGifDutch from 'global/images/de_de_comp_game/dutch-umbrella.gif'
import UmbrellaGifFR from 'global/images/de_de_comp_game/french-umbrella.gif'
import UmbrellaGifPL from 'global/images/de_de_comp_game/poland-umbrella.gif'
import CompleteHeading from 'global/images/de_de_comp_game/complete_title_de.png'
import CompleteHeadingFT from 'global/images/de_de_comp_game/complete_title_fr.png'
import CompleteHeadingDT from 'global/images/de_de_comp_game/complete_title_dt.png'
import CompleteHeadingPL from 'global/images/de_de_comp_game/complete_title_pl.png'
import CompleteHeadingSV from 'global/images/de_de_comp_game/complete_title_sv.png'
import CompleteGifUrlDE from 'global/images/de_de_comp_game/complete_gifholder_de.gif'
import Icons1 from 'global/images/de_de_comp_game/enter_icons_1.png'
import Icons2 from 'global/images/de_de_comp_game/enter_icons_2.png'
import Icons3 from 'global/images/de_de_comp_game/enter_icons_3.png'
import BuyLogoCora from 'global/images/de_de_comp_game/cora.png'
import BuyLogoMatch from 'global/images/de_de_comp_game/match.png'
import BuyLogoDelhaize from 'global/images/de_de_comp_game/delhaize.png'
import BuyLogoIntermarche from 'global/images/de_de_comp_game/intermarche.png'
import BuyLogoMakro from 'global/images/de_de_comp_game/makro.png'
import BuyLogoGall from 'global/images/de_de_comp_game/gall-gall.png'
import BuyHoogvliet from 'global/images/de_de_comp_game/hoogvliet.png'
import BuyJumbo from 'global/images/de_de_comp_game/jumbo.png'
import BuyMirta from 'global/images/de_de_comp_game/mitra-drankenspeciaalzaken.png'
import BuyDirck from 'global/images/de_de_comp_game/dirck3.jpg'
import BuyLogoIntermarchePL from 'global/images/de_de_comp_game/intermarche-pl.png'
import BuyLogoAuchan from 'global/images/de_de_comp_game/auchan.png'
import BuyLogoCarrefour from 'global/images/de_de_comp_game/carrefour.png'
import BuyLogoKaufland from 'global/images/de_de_comp_game/kaufland.png'
import BuyLogoSelgros from 'global/images/de_de_comp_game/selgros.jpg'
import ComputerDT from 'global/images/de_de_comp_game/computer-dt.png'
import ComputerFR from 'global/images/de_de_comp_game/computer-fr.png'
import ComputerPL from 'global/images/de_de_comp_game/computer-pl.png'

export const getPageName = (route) => {
    const path = route
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'>/g, "&#x27;");

    if (path.endsWith("/")) {
        return path;
    }

    return `${path}/`;
}

export const getRouteList = () => {

    const routes = [
      "/escapegamegermany/eintreten/",
      "/escapegamebelgiumfr/enter-code/",
      "/escapegamebelgiumdt/enter-code/",
      "/escapegamenetherlands/enter-code/",
      "/escapegamepoland/enter-code/",
      "/escapegameenglish/enter-code/",
      "/escapegameslovakia/enter-code/",
      "/escapegamegermany/abspielen/",
      "/escapegamebelgiumfr/play/",
      "/escapegamebelgiumdt/play/",
      "/escapegamenetherlands/play/",
      "/escapegamepoland/play/",
      "/escapegameenglish/play/",
      "/escapegameslovakia/play/",
      "/escapegamegermany/registrieren/",
      "/escapegamebelgiumdt/register/",
      "/escapegamebelgiumfr/register/",
      "/escapegamenetherlands/register/",
      "/escapegamepoland/register/",
      "/escapegameenglish/register/",
      "/escapegameslovakia/register/",
      "/escapegamegermany/terms/",
      "/escapegamebelgiumdt/terms/",
      "/escapegamebelgiumfr/terms/",
      "/escapegamenetherlands/terms/",
      "/escapegamepoland/terms/",
      "/escapegameenglish/terms/",
      "/escapegameslovakia/terms/",
      "/escapegamegermany/complete/",
      "/escapegamebelgiumdt/complete/",
      "/escapegamebelgiumfr/complete/",
      "/escapegamenetherlands/complete/",
      "/escapegamepoland/complete/",
      "/escapegameenglish/complete/",
      "/escapegameslovakia/complete/"
    ];

    return routes;
};

export const getRouteCountry = (route) => {
  let routeData = {}
  const routes = [
      {
        country: 'germany',
        list: [
          "/escapegamegermany/eintreten/",
          "/escapegamegermany/abspielen/",
          "/escapegamegermany/registrieren/",
          "/escapegamegermany/terms/",
           "/escapegamegermany/complete/"
        ]
      },
      {
        country: 'belgiumfr',
        list: [
          "/escapegamebelgiumfr/enter-code/",
          "/escapegamebelgiumfr/play/",
          "/escapegamebelgiumfr/register/",
          "/escapegamebelgiumfr/terms/",
          "/escapegamebelgiumfr/complete/",
        ]
      },
      {
        country: 'belgiumdt',
        list: [
          "/escapegamebelgiumdt/enter-code/",
          "/escapegamebelgiumdt/play/",
          "/escapegamebelgiumdt/register/",
          "/escapegamebelgiumdt/terms/",
          "/escapegamebelgiumdt/complete/",
        ]
      },
      {
        country: 'netherlands',
        list: [
          "/escapegamenetherlands/enter-code/",
          "/escapegamenetherlands/play/",
          "/escapegamenetherlands/register/",
          "/escapegamenetherlands/terms/",
          "/escapegamenetherlands/complete/"

        ]
      },
      {
        country: 'poland',
        list: [
          "/escapegamepoland/enter-code/",
          "/escapegamepoland/play/",
          "/escapegamepoland/register/",
          "/escapegamepoland/terms/",
          "/escapegamepoland/complete/"
        ]
      },
      {
        country: 'germanyen',
        list: [
          "/escapegameenglish/enter-code/",
          "/escapegameenglish/play/",
          "/escapegameenglish/register/",
          "/escapegameenglish/terms/",
          "/escapegameenglish/complete/"
        ]
      },
      {
        country: 'slovakia',
        list: [
          "/escapegameslovakia/enter-code/",
          "/escapegameslovakia/play/",
          "/escapegameslovakia/register/",
          "/escapegameslovakia/terms/",
          "/escapegameslovakia/complete/"
        ]
      }
    ];

    routes.map( (routeObj) => {
      if(routeObj.list && routeObj.list.length > 0) {
        if(routeObj.list.includes(route)) {
          routeData = routeObj
        }
      }
    })

    return routeData;
}

export const getRouteListCode = () => {

    const routes = [
      "/escapegamegermany/eintreten/",
      "/escapegamebelgiumfr/enter-code/",
      "/escapegamebelgiumdt/enter-code/",
      "/escapegamenetherlands/enter-code/",
      "/escapegamepoland/enter-code/",
      "/escapegameenglish/enter-code/",
      "/escapegameslovakia/enter-code/"
    ];

    return routes;
};

export const getRouteListPlay = () => {

    const routes = [
      "/escapegamegermany/abspielen/",
      "/escapegamebelgiumfr/play/",
      "/escapegamebelgiumdt/play/",
      "/escapegamenetherlands/play/",
      "/escapegamepoland/play/",
      "/escapegameenglish/play/",
      "/escapegameslovakia/play/"
    ];

    return routes;
};

export const getRouteListForm = () => {

    const routes = [
      "/escapegamegermany/registrieren/",
      "/escapegamebelgiumdt/register/",
      "/escapegamebelgiumfr/register/",
      "/escapegamenetherlands/register/",
      "/escapegamepoland/register/",
      "/escapegameenglish/register/",
      "/escapegameslovakia/register/"
    ];

    return routes;
};

export const getRouteListTerms = () => {

    const routes = [
      "/escapegamegermany/terms/",
      "/escapegamebelgiumdt/terms/",
      "/escapegamebelgiumfr/terms/",
      "/escapegamenetherlands/terms/",
      "/escapegamepoland/terms/",
      "/escapegameenglish/terms/",
      "/escapegameslovakia/terms/"
    ];

    return routes;
};

export const getRouteListComplete = () => {

    const routes = [
      "/escapegamegermany/complete/",
      "/escapegamebelgiumdt/complete/",
      "/escapegamebelgiumfr/complete/",
      "/escapegamenetherlands/complete/",
      "/escapegamepoland/complete/",
      "/escapegameenglish/complete/",
      "/escapegameslovakia/complete/"
    ];

    return routes;
};
export const getSectionAssets = (currentLocation) => {
  // console.log('IN GET SECTION ASSETS >>>> ', currentLocation)
  const routes = getRouteListCode();
  let currentRoute = getPageName(currentLocation);
  const assets = getAssets(currentRoute);

  return assets
}

export const getAssets = (route) => {
    let assets = {}
    let currentRouteData = {}

    currentRouteData = getRouteCountry(route);
    for(const prop in currentRouteData) {
        if(currentRouteData.hasOwnProperty(prop)) {
          assets = getRouteData(currentRouteData.country)
        }
    }
    return assets;
};


export const getRouteData = (route) => {
  let routeData = {}
  console.log('IN GETROUTE DATA >>>> ', route)
  if(route !== '' && route === 'germany') {
    routeData = {
      gameheader: {
        gamehero: GameHeroDE,
        gameherovid: GameHeroMP4DE,
        headerbg: EnterCodeHeaderBgDE,
        headertime: EnterCodeHeaderTimeDE,
        headerhand: EnterCodeHeaderHandDE,
        headercopy: 'KANNST DU DEN REKORD DES CAPTAINS BRECHEN',
        headerentercopy: 'GIB HIER DEINEN CODE EIN:',
        headercodeplaceholdertext: '8-STELLIGER CODE *',
        headerentersubmittext: 'SENDEN',
        headersubcopy: 'Um gemeinsam mit bis zu fünf deiner Freunde zu spielen, teile deinen einzigartigen code mit ihnen.',
        headertnccopy: 'Terms & FAQs',
        headertncurl: '/escapegamegermany/terms/',
        headernextimage: EnterCodeHeaderHandDE,
        headernextcopy: 'DU HAST NOCH KEINEN CODE?',
        headerfaqurl: '/escapegamegermany/terms/',
        headerfaqtext: '/escapegamegermany/terms/',
        headeranimation: HeaderAnimationDE,
        formterms: "* Ich akzeptiere die <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_de.html?locale=de-de' target='_blank'>Allgemeinen Geschäftsbedingungen</a> und <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_de.html?locale=de-de' target='_blank'>Datenatenschutz- und Cookie-Enstellunge</a>."
      },
      gamecode:{
        headingimage: CodeHeadingImageDE,
        heading: '<h3>Ahoi, Captain!</h3><h3>Schnapp dir deine Crew und setz die Segel... äh, den Desktop... um dich für ein Abenteuer bereit zu machen, das es so zuvor noch nie gegeben hat!</h3>',
        copy: '<p>Teile diese Website und den Code mit bis zu fünf Freunden, von denen du denkst, dass sie das Zeug haben, dir in Captain Morgans erstem digitalen Escape Room zur Seite zu stehen.<br/><br/>Er wurde extra für euch zusammen mit DJ Psaiko.Dino kreiert, der im Spiel sogar an deiner Seite steht. Aber Vorsicht – wähle deine Crew mit Bedacht, denn du willst mit Sicherheit nur die Besten der Besten in deinem Team haben, wenn ihr gemeinsam die Rätsel löst und den Tag rettet.<br /><br />Traut ihr euch, dieses Abenteuer gemeinsam anzutreten? Ahahaha, was für eine Frage, ein „Nein“ wird sowieso nicht akzeptiert, denn ein echter Captain kennt keine Angst!<br /><br />Und zu guter Letzt einfach den Code ins Feld eingeben und auf „Los“ klicken. Ich wünsche dir und deiner Crew viel Glück!<br /><br /></p><h4>Zusatzinfo:</h4><p>Du hast noch keinen Code? Folge einfach diesen Schritten:</p><ol><li>Kaufe eine Promo-Flasche Captain Morgan Original Spiced Gold in deinem örtlichen Supermarkt oder online (Link).</li><li>Scanne den QR-Code und gib den einzigartigen Zugangscode vom Neckhanger auf der Captain Morgan-Website ein.</li><li>Stelle deine Crew zusammen und rettet gemeinsam den Tag. Einfach.</li></ol>',
        copyposter: MintCoolerPosterDE,
        copyimg: MintCoolerWebMDE,
        copyvideo: MintCoolerMP4DE,
        formterms: "I accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_de.html?locale=de-de' target='_blank'>Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_de.html?locale=de-de' target='_blank'>Privacy and Cookie Notice</a>. *",
        formcopybot: 'Du willst auf dem Laufenden bleiben? Klicken Sie unten, um sich für unseren Newsletter anzumelden und zukünftige Updates zu erhalten.',
        formregctatext: 'Sign up',
        formregctaurl: '/escapegamegermany/registrieren/',
        formregtermsctatext: 'agb',
        formregtermsctaurl: '/escapegamegermany/terms/',
        redirectUrl: '/game_assets/germany/',
        rulestitle: '',
        rulessubtitle:'DU HAST NOCH KEINEN CODE?',
        rulesbuyhereimg: RulesBuyDE,
        rulesbuyhereurl: 'https://www.amazon.de/Captain-Morgan-9-CM-006-35-Captain-Morgan-Original-Rumverschnitt/dp/B004K1D0BG?ref_=ast_sto_dp',
        ruleslist: [
          {
            copy: 'HOL DIR EINE CAPTAIN MORGAN PROMO-FLASCHE.',
            image: Icons1
          },
          {
            copy: 'SCANNE DEN QR-CODE UND GIB DEN EINZIGARTIGEN CODE VOM NECKHANGER OBEN AUF DIESER WEBISTE EIN.',
            image: Icons2
          },
          {
            copy: 'VERSAMMLE DEINE CREW, TEILE DEINEN CODE MIT IHNEN, MIXT EUCH EINEN MINT COOLER UND RETTET DEN TAG. EINFACH.',
            image: Icons3
          }
        ],
        rulesfollowtext: '',
        rulesfollowurl: 'https://www.instagram.com/captainmorgande/',
        rulesFollowUrlFacebook:'https://www.facebook.com/CaptainMorganDE',
        rulesFollowUrlTwitter:'https://twitter.com/captainmorgande',
        rulestrailerurl: '',
        rulestnctext: '',
        rulestncurl: '',
        videomp4: GameVideoMP4DE,
        videourl:'https://www.instagram.com/p/CRWkPZDDZ35/?hl=de',
        umbrellagif: UmbrellaGifDE
      },
      gamewrapper: {
        gamepath: '/game_assets/germany/',    
        completepath:  "/escapegamegermany/complete/",
        enterpath:  "/escapegamegermany/eintreten/",
      },
      gameform: {
        title: '<h2>Lorem ipsum</h2>',
        copy: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>',
        termscopy: "I accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_de.html?locale=de-de' target='_blank'>Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_de.html?locale=de-de' target='_blank'>Privacy and Cookie Notice</a>. *",
        newslettercopy: 'Click here if you would like to receive special offers and news from Captain Morgan by email. You can unsubscribe at any time.',
        promocode: 'DWE028SMI0721WC0005',
        countryval: 54
      },
      gameterms: {
        heading: '[Captain Morgan Escape Room Game]',
        headingcopy: 'Beim Kauf von Captain Morgan Original Spiced Gold mit Flaschenanhänger oder 2 Captain Morgan Getränken in teilnehmenden Gastronomiebetrieben berechtigt der darin enthaltene individuelle Code zur einmaligen Teilnahme an einem digitalen Escape Room, der gemeinsam mit bis zu 5 weiteren erwachsenen Mitspielern gespielt werden kann. Dabei gilt es, gemeinsam spielerisch Aufgaben zu bewältigen, d.h. Rätsel und Puzzles zu lösen, sowie Fragen zu beantworten. Der Weg ist das Ziel.<br /><br />Für die Inanspruchnahme gelten die folgenden Bedingungen:',
        listcopy: [
          'Jeder Flaschenanhänger trägt einen individuellen Code zur einmaligen Verwendung. Dieser befindet sich unter dem Rubbelfeld auf der Innenseite des Anhängers. Das Einlösen der Codes setzt den vorherigen Kauf von Captain Morgan Original Spiced Gold voraus.',
          'Die Verwendung des individuellen Codes zur Teilnahme am digitalen Escape Room setzt ein digitales Endgerät (Computer, Smartphone, Tablet etc.) und eine Internetverbindung voraus.',
          'Zum Einlösen des individuellen Codes kann der unter dem Rubbelfeld freigelegte individuelle Code auf dieser Internetseite eingegeben werden. Diese Seite kann als Adresse im Webbrowser eingegeben werden; alternativ kann die Seite geöffnet werden, indem der QR-Codes auf dem Flaschenanhänger gescannt wird. Im Anschluss können bis zu 5 weitere Mitspieler eingeladen werden, indem der individuelle Code mit ihnen geteilt wird. <strong>Jeder Spieler muss Einwohner aus Deutschland mit einem Mindestalter von 18 Jahren sein; die Teilnahme Minderjähriger ist ausgeschlossen.</strong>',
          'Jeder Code kann nur einmalig eingelöst werden. Der Escape Room ist nach dem Einlösen eine Woche lang erreichbar, wobei jeder Spieler in dieser Woche maximal zweimal einloggen kann.',
          'Anbieter des Escape Rooms ist die DIAGEO Germany GmbH mit Hauptgeschäftssitz: DIAGEO Germany GmbH, Reeperbahn 1, 20359 Hamburg. Tel.: +49 (0)40 236486-0, Handelsregister: AG Hamburg (HRB 41802), Registernummer: HRB 20145, Ust-IdNr.: DE 118539398s, Geschäftsführerin: Soraya Zoueihed Benchikh.',
          'Die Diageo GmbH haftet nicht für technische Probleme der Internetverbindung oder der digitalen Endgeräte, es sei denn, dass diese durch eine vorsätzliche oder grob fahrlässige Pflichtverletzung der Diageo GmbH eingetreten sind. Dies gilt auch zugunsten der persönlichen Haftung der gesetzlichen Vertreter, leitenden Angestellten und einfachen Erfüllungsgehilfen der Diageo GmbH.',
          'Diese Promotion und diese Bedingungen unterliegen deutschem Recht und der ausschließlichen Gerichtsbarkeit der deutschen Gerichte. Der Rechtsweg ist ausgeschlossen.'
        ],
        faqtitle: '',
        faqlist: [
          {
            faqheading: 'Was ist ein Online Escape Room?',
            faqcopy: 'Unser Captain Morgan Online Escape Room ist ein virtuelles Escape Game, dass ihr von zu Hause aus allein oder mit Freunden spielen könnt. Ihr werdet Teil einer spannenden Geschichte rund um das Thema Zeitreise, die voller Rätsel steckt, welche ihr gemeinsam lösen müsst. Das Spiel dauert in der Regel 45 bis 90 Minuten und ist mit bis zu 6 Personen gut spielbar.'
          },
          {
            faqheading: 'Wie funktioniert der Captain Morgan Online Escape Room?',
            faqcopy: 'Um unseren Online Escape Room zu spielen, müsst ihr die eigenen vier Wände nicht verlassen. Ihr benötigt lediglich einen PC, Laptop oder ein Smartphone mit Internetzugang, sowie ein Telefon. Zudem empfehlen wir euch, ein Blatt Papier und einen Stift bereit zu halten.<br/> <br/>Falls ihr und eure Freunde euch an mehreren Orten befindet, könnt ihr euch über z. B. Zoom, Skype oder Hangouts verbinden, um miteinander zu kommunizieren.'
          },
          {
            faqheading: 'Ab wie viel Jahren kann man den Captain Morgan Online Escape Room spielen?',
            faqcopy: 'Um unseren Online Escape Room zu spielen, muss man mindestens 18 Jahre alt sein.'
          },
          {
            faqheading: 'Welche Voraussetzungen sind nötig, um den Captain Morgan Online Escape Room spielen zu können?',
            faqcopy: 'Für ein problemloses Spielerlebnis empfehlen wir euch einen Computer mit Browser und Internetverbindung, sowie ein Telefon. Es ist aber auch möglich, mit einem Smartphone zu spielen. Zudem benötigt ihr Stift und Papier, um Notizen zu machen.'
          },
          {
            faqheading: 'Wie spielt man den Captain Morgan Online Escape Room mit mehreren Personen?',
            faqcopy: 'Alles, was du dazu tun musst, ist deinen Freunden (bis zu fünf Personen) die Website URL des Spiels und deinen persönlichen Code mitzuteilen. Damit haben dann alle Zugang zum Spiel. Jeder Spieler kann sich bis zu zwei Mal einloggen. Ihr habt eine Woche Zeit das Spiel zu lösen, bevor der Zugang verfällt. <br/> <br/> Wenn du und deine Freunde euch an unterschiedlichen Orten befindet, könnt ihr euch einfach digital vernetzen und Dienste, wie Hangouts, WhatsApp, Skype oder Zoom nutzen, um miteinander zu kommunizieren und euch auszutauschen. Ihr müsst also nicht den gleichen Computer benutzen, sondern könnt von überall auf der Welt zusammen spielen. Alternativ kannst du deine Freunde auch zu dir nach Hause einladen und ihr spielt einfach zusammen an deinem Computer.'
          },
           {
            faqheading: 'Gibt es die Online Escape Games nur auf Deutsch?',
            faqcopy: 'Erst mal nur auf Deutsch, aber bald ist unserer Online Escape Room auch auf Englisch spielbar. '
          },
           {
            faqheading: 'Gibt es Hinweise für das Online Escape Game?',
            faqcopy: 'Ihr könnt jederzeit Hinweise aufrufen, falls ihr nicht weiterkommen solltet. Dabei wird euch jedoch nicht sofort die Lösung verraten, sondern nur hilfreiche Tipps und Denkanstöße gegeben.'
          },
           {
            faqheading: 'Müssen alle Spieler im selben Raum sein bzw. an einem Ort?',
            faqcopy: 'Da unser Online Escape Room per Internetbrowser oder am Smartphone gespielt wird, müssen sich die Spieler nicht am gleichen Ort befinden. Ihr könnt euch auch per Internet digital miteinander verbinden und mithilfe von Zoom, Hangouts oder ähnlichen Diensten miteinander kommunizieren. Somit ist das Spielen von jedem Standort aus möglich.'
          },
          {
            faqheading: 'An wen kann ich mich bei Fragen zum Captain Morgan Online Escape Room wenden?',
            faqcopy: 'Ihr könnt uns am besten über unsere E-Mail Adresse cmsupport@diageo.com kontaktieren. Wir werden uns bemühen euch innerhalb von 2 Werktagen eine Rückmeldung zu geben.'
          }
        ]
      },
      gamecomplete: {
        spotifyLink: "https://www.spotify.com",
        heading: CompleteHeading,
        subcopy: 'Du hast das musikfestival gerettet',
        yturl: 'https://youtu.be/qCbvemLQKoM',
        ytcopy: '<p>hol dir den 90er-vibe mit unserer 25 min musikauswahl!</p><p>die perfekte playlist für eine hausparty mit deiner crew!</p>',
        gifurl: CompleteGifUrlDE
      }
    }
  }else if(route !== '' && route === 'belgiumfr'){
    routeData = {
      gameheader: {
        gamehero: GameHeroDE,
        gameherovid: GameHeroMP4DE,
        headerbg: EnterCodeHeaderBgDE,
        headertime: EnterCodeHeaderTimeDE,
        headerhand: EnterCodeHeaderHandDE,
        headercopy: "CAN YOU BREAK THE CAPTAIN'S RECORD",
        headerentercopy: 'SAISISSEZ VOTRE CODE ICI:',
        headercodeplaceholdertext: '*CODE A 8 CHIFFRES',
        headerentersubmittext: 'ENVOYEZ',
        headersubcopy: 'PARTAGEZ VOTRE CODE UNIQUE AVEC UN MAXIMUM DE 5 AMIS AVEC QUI VOUS VOULEZ JOUER.',
        headertnccopy: 'TERMES ET FAQ',
        headertncurl: '/escapegamebelgiumfr/terms/',
        headernextimage: EnterCodeHeaderHandDE,
        headernextcopy: "VOUS N'AVEZ PAS ENCORE DE CODE?",
        headerfaqurl: '/escapegamebelgiumfr/terms/',
        headerfaqtext: '/escapegamebelgiumfr/terms/',
        headeranimation: HeaderAnimationFR,
        formterms: "* J’accepte les  <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_de.html?locale=de-de' target='_blank'>Cconditions générales</a> ainsi que les <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_de.html?locale=de-de' target='_blank'>politiques de confidentialité et paramètres de cookies</a>. "
      },
      gamecode:{
        headingimage: CodeHeadingImageEN,
        heading: '<h3>Ahoy, Captain!</h3> <h3>Grab your crew and set sail...er, the desktop...to get ready for an adventure like never before!</h3>',
        copy: "<p>Share this website and the code with up to five friends who you think might have what it takes to help you in Captain Morgan's first digital escape room.<br/><br/>It became extra created for you together with DJ Psaiko.Dino, who even stands by your side in the game. But be careful - choose your crew wisely, because you'll want only the best of the best on your team as you solve the puzzles together and save the day.<br /><br />Dare you dare to embark on this adventure together to compete? Ahahaha, what a question, a \"No\" is not accepted anyway, because a real captain does not know fear!<br /><br />And last but not least, simply enter the code in the field and click on \"Go\". Good luck to you and your crew!<br /><br /></p><h4>Additional info:</h4><p>Don't have a code yet? Just follow these steps:</p><ol><li>Buy a promo bottle of Captain Morgan Original Spiced Gold at your local supermarket or online (link).</li><li>Scan the QR code and enter it unique access code from the neckhanger on the Captain Morgan website.</li><li>Assemble your crew and save the day together. Simple.</li></ol>",
        copyposter: MintCoolerPosterDE,
        copyimg: MintCoolerWebMDE,
        copyvideo: MintCoolerMP4FR,
        formterms: "I accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_de.html?locale=de-de' target='_blank'>Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_de.html?locale=de-de' target='_blank'>Privacy and Cookie Notice</a>. *",
        formcopybot: 'You want to stay up to date? Click below to sign up for our newsletter and receive future updates.',
        formregctatext: 'Sign up',
        formregctaurl: '/escapegamebelgiumfr/register/',
        formregtermsctatext: 'agb',
        formregtermsctaurl: '/escapegamebelgiumfr/terms/',
        redirectUrl: '/game_assets/belgiumfr/',
        rulestitle: '',
        rulessubtitle: "VOUS N'AVEZ PAS ENCORE DE CODE?",
        rulesbuyhereimg: RulesBuyFR,
        rulesbuyhereurl: 'https://www.amazon.de/Captain-Morgan-9-CM-006-35-Captain-Morgan-Original-Rumverschnitt/dp/B004K1D0BG?ref_=ast_sto_dp',
        rulesbuyheremodal: [{image:BuyLogoCora, link:"https://www.cora.be/home"},{image:BuyLogoMatch, link:"https://www.supermarche-match.be/"},{image:BuyLogoDelhaize, link:"https://www.delhaize.be/"},{image:BuyLogoIntermarche, link:"https://www.intermarche.be/"},{image:BuyLogoMakro, link:"https://www.makro.be/"}],
        ruleslist: [
          {
            copy: 'ACHETEZ UNE BOUTEILLE PROMOTIONNELLE DE CAPTAIN MORGAN.',
            image: Icons1
          },
          {
            copy: 'SCANNEZ LE CODE OU SAISISSEZ LE CODE UNIQUE SUR LA COLLERETTE EN HAUT DE LA PAGE.',
            image: Icons2
          },
          {
            copy: 'RASSEMBLEZ VOS COÉQUIPIERS, PARTAGEZ VOTRE CODE AVEC EUX, FAITES UN MINT COOLER ET A VOUS DE JOUER!',
            image: Icons3
          }
        ],
        rulesfollowtext: 'Suivez-nous',
        rulesfollowurl: 'https://www.instagram.com/captainmorgan_official/',
        rulesFollowUrlFacebook:'https://www.facebook.com/CaptainMorgan/',
        rulesFollowUrlTwitter:'https://twitter.com/captainmorgan',
        rulestrailerurl: '',
        rulestnctext: '',
        rulestncurl: '',
        videomp4: MintCoolerMP4FR,
        videourl:' https://www.captainmorgan.com/en-gb/cocktails/captain-mint-cooler/#overview',
        umbrellagif: UmbrellaGifFR,
        computer: ComputerFR
      },
      gamewrapper: {
        gamepath: '/game_assets/belgiumfr/',    
        completepath:  "/escapegamebelgiumfr/complete/",
        enterpath:  "/escapegamebelgiumfr/enter-code/",
      },
      gameform: {
        title: '<h2>Lorem ipsum</h2>',
        copy: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>',
        termscopy: "I accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_de.html?locale=de-de' target='_blank'>Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_de.html?locale=de-de' target='_blank'>Privacy and Cookie Notice</a>. *",
        newslettercopy: 'Click here if you would like to receive special offers and news from Captain Morgan by email. You can unsubscribe at any time.',
        promocode: 'DWE028SMI0721WC0005',
        countryval: 54
      },
      gameterms: {
        heading: '[Escape room Captain Morgan]',
        headingcopy: 'À l\'achat d\'une bouteille de Captain Morgan Original Spiced Gold avec une collerette ou de deux boissons Captain Morgan dans les magasins participants, le client reçoit un code individuel qui lui permet de participer une fois à une escape room en ligne, à laquelle il peut jouer avec maximum cinq autres adultes. Les joueurs doivent progresser ensemble dans le jeu en résolvant des énigmes et des puzzles ainsi qu\'en répondant à des questions.<br><br/>L\'offre est soumise aux conditions générales suivantes:',
        listcopy: [
          'Chaque étiquette de bouteille contient un code individuel à usage unique, dissimulé sous la surface à gratter à l\'intérieur de la collerette. Le code ne peut être utilisé que sous réserve de l\'achat préalable d\'une bouteille Captain Morgan Original Spiced Gold.',
          'Un appareil numérique (ordinateur, smartphone, tablette, etc.) et une connexion internet sont nécessaires pour utiliser le code individuel et jouer à l\`escape room en ligne.',
          'Pour jouer, le client doit saisir le code individuel dissimulé sous la surface à gratter sur le site web, auquel il peut accéder en introduisant l\'URL dans un navigateur web ou en scannant le code QR sur l\'étiquette de la bouteille. Le client peut inviter jusqu\'à cinq autres joueurs en partageant avec eux son code individuel. <strong>Tous les joueurs doivent résider en Belgique et être âgés d\'au moins 18 ans. La participation est interdite à toute personne de moins de 18 ans.</strong>',
          'Chaque code ne peut être utilisé qu\'une seule fois. Une fois le code utilisé, les joueurs peuvent accéder à l\'escape room pendant une semaine, chaque joueur pouvant se connecter au maximum deux fois durant cette période. L\'accès à l\'escape room sera définitivement clôturé le 31 juillet 2023 à 23h59.',
          'L\'escape room est proposée par <strong>Diageo Belgium N.V.</strong>, dont le siège social est situé Zone 3, Doornveld 150, 1731, Belgium.',
          '<strong>Diageo Belgium N.V.</strong>, ne peut être tenue responsable de tout problème technique en lien avec la connexion internet ou les appareils numériques, sauf en cas de faute intentionnelle ou de négligence grave de la part de <strong>Diageo Belgium N.V.</strong>. Cela vaut aussi pour la responsabilité personnelle des représentants légaux, collaborateurs dirigeants et auxiliaires d\'exécution de <strong>Diageo Belgium N.V.</strong>.',
          'Cette promotion et ces conditions générales sont soumises au droit belge et à la juridiction exclusive des tribunaux de Belgique. Il n\'existe aucune voie de recours légal.'
        ],
        faqtitle: '',
        faqlist: [
          {
            faqheading: 'Qu\'est-ce qu\'une escape room en ligne ?',
            faqcopy: 'Captain Morgan Online Escape Room est un escape game virtuel auquel vous pouvez jouer chez vous, seul ou entre amis. Embarquez pour une aventure palpitante sur le thème du voyage dans le temps, truffée d’énigmes et de puzzles que vous devrez résoudre ensemble. Le jeu dure généralement entre 45 et 90 minutes et se joue à maximum 6 joueurs.'
          },
          {
            faqheading: 'Comment fonctionne Captain Morgan Online Escape Room ?',
            faqcopy: "Vous pouvez jouer à notre escape room en ligne confortablement depuis chez vous. Vous n’aurez besoin que d’un PC, d’un ordinateur portable ou d’un smartphone connecté à internet, et d’un téléphone. Pensez également à vous munir d’une feuille et d’un stylo. <br/><br/>Vous et vos amis n’êtes pas au même endroit ? Pas de problème, il vous suffit de vous connecter sur Zoom, Skype ou Google Hangouts pour pouvoir communiquer."
          },
          {
            faqheading: 'Quel est l’âge minimum pour jouer à Captain Morgan Online Escape Room ?',
            faqcopy: 'Les joueurs doivent être âgés de minimum 18 ans.'
          },
          {
            faqheading: 'De quoi ai-je besoin pour jouer à Captain Morgan Online Escape Room ?',
            faqcopy: 'Pour profiter d’une expérience optimale, nous vous recommandons d’utiliser un ordinateur avec un navigateur et une connexion internet, ainsi qu’un téléphone. Vous pouvez toutefois jouer sur un smartphone. Vous aurez également besoin d’une feuille et d’un stylo pour prendre des notes.'
          },
          {
            faqheading: 'Comment jouer à Captain Morgan Online Escape Room à plusieurs ?',
            faqcopy: "Il vous suffit de partager le lien du jeu et votre code personnel avec maximum cinq amis, qui pourront alors rejoindre le jeu. Chaque joueur peut se connecter jusqu’à deux fois. Vous avez une semaine pour résoudre l’énigme, après quoi vous n’aurez plus accès au jeu. <br/><br/>Tous les joueurs ne doivent pas jouer sur le même ordinateur : vous pouvez jouer ensemble depuis les quatre coins du monde. Si vous et vos amis n’êtes pas au même endroit, il vous suffit de vous connecter en ligne via une plateforme comme Google Hangouts, WhatsApp, Skype ou Zoom pour pouvoir communiquer. Vous pouvez également inviter vos amis chez vous et jouer tous ensemble sur le même ordinateur. "
          },
           {
            faqheading: 'L\'escape room en ligne est-elle uniquement disponible en français ?',
            faqcopy: 'L’escape room n’est pas encore disponible en anglais. '
          },
           {
            faqheading: 'Des indices sont-ils disponibles pendant le jeu ?',
            faqcopy: 'Si vous êtes bloqué, vous pouvez demander des indices à tout moment. Vous ne recevrez pas la solution, simplement quelques conseils utiles et des pistes de réflexion.'
          },
           {
            faqheading: 'Tous les joueurs doivent-ils être au même endroit ?',
            faqcopy: "Puisque l’escape room en ligne est hébergée sur un navigateur web ou un smartphone, les joueurs ne doivent pas nécessairement être au même endroit. Vous pouvez communiquer en ligne en utilisant Zoom, Google Hangouts ou des plateformes similaires."
          },
          {
            faqheading: 'Qui puis-je contacter si j\'ai des questions sur l\'escape room en ligne Captain Morgan ?',
            faqcopy: 'Le meilleur moyen de nous contacter est d\'utiliser notre adresse e-mail cmsupport@diageo.com. Nous vous répondrons dans un délai de 2 jours ouvrables.'
          }
        ]
      },
      gamecomplete: {
        spotifyLink: "https://www.spotify.com",
        heading: CompleteHeadingFT,
        subcopy: 'Vous avez sauvé le festival de musique',
        yturl: 'https://youtu.be/qCbvemLQKoM',
        ytcopy: '<p>Obtenez l\'ambiance des années 90 avec notre sélection de musique!.</p> <p>La playlist parfaite pour une soirée entre amis!</p>',
        gifurl: CompleteGifUrlDE
      }
    }
  }else if(route !== '' && route === 'belgiumdt'){
    routeData = {
      gameheader: {
        gamehero: GameHeroDE,
        gameherovid: MintCoolerMP4DT,
        headerbg: EnterCodeHeaderBgDE,
        headertime: EnterCodeHeaderTimeDE,
        headerhand: EnterCodeHeaderHandDE,
        headercopy: "CAN YOU BREAK THE CAPTAIN'S RECORD",
        headerentercopy: 'VUL HIER JE CODE IN:',
        headercodeplaceholdertext: 'Code van 8 cijfers*',
        headerentersubmittext: 'verzenden',
        headersubcopy: '',
        headertnccopy: 'Voorwaarden en veelgestelde vragen',
        headertncurl: '/escapegamebelgiumdt/terms/',
        headernextimage: EnterCodeHeaderHandDE,
        headernextcopy: "HEB JE NOG GEEN CODE?",
        headerfaqurl: '/escapegamebelgiumdt/terms/',
        headerfaqtext: '/escapegamebelgiumdt/terms/',
        headeranimation: HeaderAnimationDT,
        formterms: "* Ik ga akkoord met de <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_de.html?locale=de-de' target='_blank'>Algemene voorwaarden</a> en de <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_de.html?locale=de-de' target='_blank'>Privacy - en cookie-instellingen</a>."
      },
      gamecode:{
        headingimage: CodeHeadingImageEN,
        heading: '<h3>Ahoy, Captain!</h3> <h3>Grab your crew and set sail...er, the desktop...to get ready for an adventure like never before!</h3>',
        copy: "<p>Share this website and the code with up to five friends who you think might have what it takes to help you in Captain Morgan's first digital escape room.<br/><br/>It became extra created for you together with DJ Psaiko.Dino, who even stands by your side in the game. But be careful - choose your crew wisely, because you'll want only the best of the best on your team as you solve the puzzles together and save the day.<br /><br />Dare you dare to embark on this adventure together to compete? Ahahaha, what a question, a \"No\" is not accepted anyway, because a real captain does not know fear!<br /><br />And last but not least, simply enter the code in the field and click on \"Go\". Good luck to you and your crew!<br /><br /></p><h4>Additional info:</h4><p>Don't have a code yet? Just follow these steps:</p><ol><li>Buy a promo bottle of Captain Morgan Original Spiced Gold at your local supermarket or online (link).</li><li>Scan the QR code and enter it unique access code from the neckhanger on the Captain Morgan website.</li><li>Assemble your crew and save the day together. Simple.</li></ol>",
        copyposter: MintCoolerPosterDE,
        copyimg: MintCoolerWebMDE,
        copyvideo: MintCoolerMP4DT,
        formterms: "I accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_de.html?locale=de-de' target='_blank'>Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_de.html?locale=de-de' target='_blank'>Privacy and Cookie Notice</a>. *",
        formcopybot: 'You want to stay up to date? Click below to sign up for our newsletter and receive future updates.',
        formregctatext: 'Sign up',
        formregctaurl: '/escapegamebelgiumdt/register/',
        formregtermsctatext: 'agb',
        formregtermsctaurl: '/escapegamebelgiumdt/terms/',
        redirectUrl: '/game_assets/belgiumdt/',
        rulestitle: '',
        rulessubtitle: "Heb je nog geen code?",
        rulesbuyhereimg: RulesBuyDT,
        rulesbuyhereurl: '',
        rulesbuyheremodal: [{image:BuyLogoCora, link:"https://www.cora.be/home"},{image:BuyLogoMatch, link:"https://www.supermarche-match.be/"},{image:BuyLogoDelhaize, link:"https://www.delhaize.be/"},{image:BuyLogoIntermarche, link:"https://www.intermarche.be/"},{image:BuyLogoMakro, link:"https://www.makro.be/"}],
        ruleslist: [
          {
            copy: 'Koop een promotionele fles Captain Morgan.',
            image: Icons1
          },
          {
            copy: 'Scan de QR-code en voer je unieke code van het flessenhalslabel bovenaan de pagina in.',
            image: Icons2
          },
          {
            copy: 'Verzamel je crew, deel je code met hen, mix een mint cooler en probeer te ontsnappen!',
            image: Icons3
          }
        ],
        rulesfollowtext: 'Volg ons',
        rulesfollowurl: 'https://www.instagram.com/captainmorgan_official/',
        rulesFollowUrlFacebook:'https://www.facebook.com/CaptainMorgan/',
        rulesFollowUrlTwitter:'https://twitter.com/captainmorgan',
        rulestrailerurl: '',
        rulestnctext: '',
        rulestncurl: '',
        videomp4: MintCoolerMP4DT,
        videourl:' https://www.captainmorgan.com/en-gb/cocktails/captain-mint-cooler/#overview',
        umbrellagif: UmbrellaGifDutch,
        computer: ComputerDT
      },
      gamewrapper: {
        gamepath: '/game_assets/belgiumdt/',    
        completepath:  "/escapegamebelgiumdt/complete/",
        enterpath:  "/escapegamebelgiumdt/enter-code/",
      },
      gameform: {
        title: '<h2>Lorem ipsum</h2>',
        copy: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>',
        termscopy: "I accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_de.html?locale=de-de' target='_blank'>Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_de.html?locale=de-de' target='_blank'>Privacy and Cookie Notice</a>. *",
        newslettercopy: 'Click here if you would like to receive special offers and news from Captain Morgan by email. You can unsubscribe at any time.',
        promocode: 'DWE028SMI0721WC0005',
        countryval: 54
      },
      gameterms: {
        heading: '[De escape room van Captain Morgan]',
        headingcopy: 'Bij aankoop van een fles Captain Morgan Original Spiced Gold met flessenhalslabel of twee Captain Morgan-drankjes in deelnemende horecazaken, geeft de individuele code op het label de klant het recht om samen met maximaal vijf andere volwassenen, één keer deel te nemen aan een online escape room. Het doel is om verder te geraken in het spel door samen opdrachten uit te voeren, zoals raadsels en puzzels oplossen en vragen beantwoorden.<br /><br /> De volgende voorwaarden zijn van toepassing bij het claimen van een code:',
        listcopy: [
          'Onder de kraslaag aan de binnenkant van het flessenhalslabel bevindt zich een eenmalige individuele code. Je dient eerder Captain Morgan Original Spiced Gold te hebben aangekocht om de code te kunnen verzilveren.',
          'Je moet over een digitaal apparaat (computer, smartphone, tablet, enz.) en een internetverbinding beschikken om de individuele code te kunnen gebruiken en deel te nemen aan de online escape room.',
          'De individuele code onder de kraslaag kan worden verzilverd op deze website, die toegankelijk is door het adres in een webbrowser in te voeren of door de QR-code op het flessenlabel te scannen. Je kunt maximaal vijf andere spelers uitnodigen door de individuele code met hen te delen. <strong>Elke speler moet inwoner zijn van België en minstens 18 jaar oud zijn. Personen jonger dan 18 jaar mogen niet deelnemen</strong>.',
          'Elke code kan slechts één keer worden gebruikt. Nadat de code is verzilverd, is de escape room één week toegankelijk. Elke speler mag in die week maximaal twee keer inloggen. De toegang tot de escape room eindigt definitief op 31 juli 2023 om 23.59 uur.',
          'De escape room wordt aangeboden door Diageo Belgium N.V., met hoofdkantoor op adres: Zone 3, Doornveld 150, 1731, Belgium.',
          '<strong>Diageo Belgium N.V.</strong> is niet aansprakelijk voor technische problemen in verband met internetverbindingen of digitale eindapparatuur, tenzij deze zijn ontstaan door opzet of grove nalatigheid van <strong>Diageo Belgium N.V.</strong>. Dit geldt ook voor de persoonlijke aansprakelijkheid van de wettelijke vertegenwoordigers, leidinggevenden en overige medewerkers van <strong>Diageo Belgium N.V.</strong>.',
          'Deze promotie en voorwaarden zijn onderhevig aan de wetgeving van België en de exclusieve jurisdictie van de rechtbanken van België. Er is geen mogelijkheid tot juridisch verhaal.'
        ],
        faqtitle: '',
        faqlist: [
          {
            faqheading: 'Wat is een online escape room?',
            faqcopy: 'De online escape room van Captain Morgan is een virtueel ontsnappingsspel dat je thuis, alleen of met vrienden kunt spelen. Je wordt ondergedompeld in een aangrijpend verhaal over tijdreizen, vol met puzzels die je samen moet oplossen. Het spel duurt meestal 45 tot 90 minuten en er kunnen maximaal 6 personen meespelen.'
          },
          {
            faqheading: 'Hoe werkt de online escape room van Captain Morgan?',
            faqcopy: "Om de online escape room te spelen hoef je niet eens je huis te verlaten. Je hebt alleen een pc, laptop of smartphone met internettoegang en een telefoon nodig. Ook is het een goed idee om pen en papier bij de hand te hebben. <br/><br/>Als jij en je vrienden je op verschillende locaties bevinden, kun je bijvoorbeeld via Zoom, Skype of Google Hangouts met elkaar communiceren."
          },
          {
            faqheading: 'Hoe oud moet je zijn om de online escape room van Captain Morgan te spelen?',
            faqcopy: 'Je moet minstens 18 jaar oud zijn om de online escape room te spelen.'
          },
          {
            faqheading: 'Wat heb ik nodig om de online escape room van Captain Morgan te spelen?',
            faqcopy: 'Voor een goede ervaring raden we je aan om een telefoon, computer met een browser en een internetverbinding te gebruiken. Je kunt het spel echter ook met een smartphone spelen. Verder heb je pen en papier nodig om aantekeningen te maken.'
          },
          {
            faqheading: 'Hoe speel je de online escape room van Captain Morgan met meer dan één persoon?',
            faqcopy: "Het enige wat je moet doen is de link naar het spel en je persoonlijke code met maximaal vijf van je vrienden delen. Zij kunnen dan allemaal meedoen aan het spel. Elke speler kan maximaal twee keer inloggen. Je hebt een week om de puzzel op te lossen voordat de toegang tot het spel vervalt.<br/><br/> Je hoeft niet allemaal dezelfde computer te gebruiken, want je kunt overal ter wereld samen spelen. Als jij en je vrienden je op verschillende locaties bevinden, kun je online communiceren via een platform zoals Google Hangouts, WhatsApp, Skype of Zoom. Je kunt je vrienden ook thuis uitnodigen en samen op één computer spelen."
          },
           {
            faqheading: 'Kan ik de online escape room alleen in het Nederlands spelen?',
            faqcopy: 'Het spel is nog niet beschikbaar in het Engels.'
          },
           {
            faqheading: 'Kan ik ergens aanwijzingen vinden voor de online escape room?',
            faqcopy: 'Als je vastloopt kun je op elk moment om een hint vragen. De oplossing wordt je niet verteld, maar je krijgt enkele nuttige tips en punten om over na te denken.'
          },
           {
            faqheading: 'Moeten alle spelers zich in dezelfde ruimte of op dezelfde plaats bevinden?',
            faqcopy: "De online escape room wordt gespeeld via een webbrowser of smartphone. Daarom hoeven de spelers zich niet op dezelfde fysieke locatie te bevinden. Je kunt online met elkaar communiceren via Zoom, Google Hangouts of vergelijkbare online platforms."
          },
          {
            faqheading: 'Wie kan ik contacteren met vragen over de Captain Morgan Online Escape Room?',
            faqcopy: 'De beste manier om ons te contacteren is via ons e-mailadres cmsupport@diageo.com. We antwoorden je binnen 2 werkdagen.'
          }
        ]
      },
      gamecomplete: {
        spotifyLink: "https://www.spotify.com",
        heading: CompleteHeadingDT,
        subcopy: 'Je hebt het muziekfestival gered',
        yturl: 'https://youtu.be/qCbvemLQKoM',
        ytcopy: '<p>Krijg de 90s vibe met onze 25 min muziekselectie!</p> <p>De perfecte playlist voor een huisfeestje met je crew!</p>',
        gifurl: CompleteGifUrlDE
      }
    }
  }else if(route !== '' && route === 'netherlands'){
    routeData = {
      gameheader: {
        gamehero: GameHeroDE,
        gameherovid: MintCoolerMP4DT,
        headerbg: EnterCodeHeaderBgDE,
        headertime: EnterCodeHeaderTimeDE,
        headerhand: EnterCodeHeaderHandDE,
        headercopy: "CAN YOU BREAK THE CAPTAIN'S RECORD",
        headerentercopy: 'VUL HIER JE CODE IN:',
        headercodeplaceholdertext: 'Code van 8 cijfers*',
        headerentersubmittext: 'verzenden',
        headersubcopy: '',
        headertnccopy: 'Voorwaarden en veelgestelde vragen',
        headertncurl: '/escapegamebelgiumdt/terms/',
        headernextimage: EnterCodeHeaderHandDE,
        headernextcopy: "HEB JE NOG GEEN CODE?",
        headerfaqurl: '/escapegamebelgiumdt/terms/',
        headerfaqtext: '/escapegamebelgiumdt/terms/',
        headeranimation: HeaderAnimationDT,
        formterms: "* Ik ga akkoord met de <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_de.html?locale=de-de' target='_blank'>Algemene voorwaarden</a> en de <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_de.html?locale=de-de' target='_blank'>Privacy - en cookie-instellingen</a>."
      },
      gamecode:{
        headingimage: CodeHeadingImageEN,
        heading: '<h3>Ahoy, Captain!</h3> <h3>Grab your crew and set sail...er, the desktop...to get ready for an adventure like never before!</h3>',
        copy: "<p>Share this website and the code with up to five friends who you think might have what it takes to help you in Captain Morgan's first digital escape room.<br/><br/>It became extra created for you together with DJ Psaiko.Dino, who even stands by your side in the game. But be careful - choose your crew wisely, because you'll want only the best of the best on your team as you solve the puzzles together and save the day.<br /><br />Dare you dare to embark on this adventure together to compete? Ahahaha, what a question, a \"No\" is not accepted anyway, because a real captain does not know fear!<br /><br />And last but not least, simply enter the code in the field and click on \"Go\". Good luck to you and your crew!<br /><br /></p><h4>Additional info:</h4><p>Don't have a code yet? Just follow these steps:</p><ol><li>Buy a promo bottle of Captain Morgan Original Spiced Gold at your local supermarket or online (link).</li><li>Scan the QR code and enter it unique access code from the neckhanger on the Captain Morgan website.</li><li>Assemble your crew and save the day together. Simple.</li></ol>",
        copyposter: MintCoolerPosterDE,
        copyimg: MintCoolerWebMDE,
        copyvideo: MintCoolerMP4DT,
        formterms: "I accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_de.html?locale=de-de' target='_blank'>Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_de.html?locale=de-de' target='_blank'>Privacy and Cookie Notice</a>. *",
        formcopybot: 'You want to stay up to date? Click below to sign up for our newsletter and receive future updates.',
        formregctatext: 'Sign up',
        formregctaurl: '/escapegamenetherlands/register/',
        formregtermsctatext: 'agb',
        formregtermsctaurl: '/escapegamenetherlands/terms/',
        redirectUrl: '/game_assets/netherlands/',
        rulestitle: '',
        rulessubtitle: "Heb je nog geen code?",
        rulesbuyhereimg: RulesBuyDT,
        rulesbuyhereurl: 'https://www.amazon.de/Captain-Morgan-9-CM-006-35-Captain-Morgan-Original-Rumverschnitt/dp/B004K1D0BG?ref_=ast_sto_dp',
        rulesbuyheremodal: [{image:BuyLogoGall, link:"https://www.gall.nl/acties/win-escape-room-captain-morgen/"}, {image:BuyHoogvliet, link:"https://www.hoogvliet.com/"}, {image:BuyJumbo, link:"https://www.jumbo.com/"}, {image:BuyMirta, link:"https://www.mitra.nl/"}, {image:BuyDirck, link:"https://www.dirckiii.nl/"}],

        ruleslist: [
          {
            copy: 'Koop een promotionele fles Captain Morgan.',
            image: Icons1
          },
          {
            copy: 'Scan de QR-code en voer je unieke code van het flessenhalslabel bovenaan de pagina in.',
            image: Icons2
          },
          {
            copy: 'Verzamel je crew, deel je code met hen, mix een mint cooler en probeer te ontsnappen!',
            image: Icons3
          }
        ],
        rulesfollowtext: 'Volg ons',
        rulesfollowurl: 'https://www.instagram.com/captainmorgan_official/',
        rulesFollowUrlFacebook:'https://www.facebook.com/CaptainMorgan/',
        rulesFollowUrlTwitter:'https://twitter.com/captainmorgan',
        rulestrailerurl: '',
        rulestnctext: '',
        rulestncurl: '',
        videomp4: MintCoolerMP4DT,
        videourl:' https://www.captainmorgan.com/en-gb/cocktails/captain-mint-cooler/#overview',
        umbrellagif: UmbrellaGifDutch,
        computer: ComputerDT
      },
      gamewrapper: {
        gamepath: '/game_assets/netherlands/',    
        completepath:  "/escapegamenetherlands/complete/",
        enterpath:  "/escapegamenetherlands/enter-code/",
      },
      gameform: {
        title: '<h2>Lorem ipsum</h2>',
        copy: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>',
        termscopy: "I accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_de.html?locale=de-de' target='_blank'>Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_de.html?locale=de-de' target='_blank'>Privacy and Cookie Notice</a>. *",
        newslettercopy: 'Click here if you would like to receive special offers and news from Captain Morgan by email. You can unsubscribe at any time.',
        promocode: 'DWE028SMI0721WC0005',
        countryval: 54
      },
      gameterms: {
        heading: '[De ontsnappingskamer van Captain Morgan]',
        headingcopy: 'Bij aankoop van een fles Captain Morgan Original Spiced Gold met flessenhalslabelof twee Captain Morgan-drankjes in deelnemende verkooppunten, geeft de daarin opgenomen individuele code de klant het recht om één keer deel te nemen aan een online ontsnappingskamer, samen met maximaal vijf andere volwassenen. Het doel van het spel is om samen opdrachten uit te voeren, raadsels en puzzels op te lossen en vragen te beantwoorden, om zo verder te komen.<br /><br />De volgende voorwaarden zijn van toepassing bij het claimen van een code:',
        listcopy: [
          'Op elk fleslabel staat een eenmalige individuele code, die zich onder de kraslaag aan de binnenkant van het flessenhalslabel bevindt. Een voorafgaande aankoop van Captain Morgan Original Spiced Gold is vereist om de code te verzilveren.',
          'Een digitaal apparaat (computer, smartphone, tablet, enz.) en een internetverbinding zijn vereist om de individuele code te gebruiken voor deelname aan de online ontsnappingskamer.',
          'De individuele code onder de kraslaag kan worden verzilverd op deze website, die toegankelijk is door het adres in een webbrowser in te voeren of door de QR-code op het fleslabel te scannen. Maximaal vijf andere spelers kunnen worden uitgenodigd door de individuele code met hen te delen. <strong>Elke speler moet inwoner zijn van Nederland en minstens 18 jaar oud zijn. Personen jonger dan deze leeftijd mogen niet deelnemen.</strong>',
          'Elke code kan slechts één keer worden gebruikt. De ontsnappingskamer is één week toegankelijk nadat de code is verzilverd en elke speler mag in die week maximaal twee keer inloggen. De toegang tot de ontsnappingskamer eindigt definitief op 31 juli 2023 om 23.59 uur.',
          'De ontsnappingskamer wordt aangeboden door Diageo Nederland B.V., met hoofdkantoor op adres: Molenwerf 12, 1014 BG Amsterdam.',
          '<strong>Diageo Nederland B.V.</strong> is niet aansprakelijk voor technische problemen in verband met internetverbindingen of digitale eindapparatuur, tenzij deze ontstaan door opzet of grove nalatigheid van <strong>Diageo Nederland B.V.</strong>. Dit geldt ook voor de persoonlijke aansprakelijkheid van de wettelijke vertegenwoordigers, leidinggevenden en overige medewerkers van <strong>Diageo Nederland B.V.</strong>.',
          'Deze promotie en voorwaarden zijn onderhevig aan de wetgeving van Nederland en de exclusieve jurisdictie van de rechtbanken van Nederland. Er is geen recht op juridisch verhaal.'
        ],
        faqtitle: '',
        faqlist: [
          {
            faqheading: 'Wat is een online ontsnappingskamer?',
            faqcopy: 'De online ontsnappingskamer van Captain Morgan is een virtueel ontsnappingsspel dat je alleen of met vrienden thuis kunt spelen. Je wordt ondergedompeld in een aangrijpend verhaal over tijdreizen, vol met puzzels die je samen moet oplossen. Het spel duurt meestal 45 tot 90 minuten en er kunnen maximaal 6 personen meespelen.'
          },
          {
            faqheading: 'Hoe werkt de online ontsnappingskamer van Captain Morgan?',
            faqcopy: "Om de online ontsnappingskamer te spelen hoef je niet eens je huis te verlaten. Je hebt alleen een pc, laptop of smartphone met internettoegang en een telefoon nodig. Ook is het een goed idee om een vel papier en een pen bij de hand te hebben. <br/><br/> Als jij en je vrienden zich op verschillende locaties bevinden, kun je bijvoorbeeld via Zoom, Skype of Google Hangouts met elkaar communiceren."
          },
          {
            faqheading: 'Hoe oud moet je zijn om de online ontsnappingskamer van Captain Morgan te spelen?',
            faqcopy: 'Je moet minstens 18 jaar oud zijn om de online ontsnappingskamer te spelen. '
          },
          {
            faqheading: 'Wat heb ik nodig om de online ontsnappingskamer van Captain Morgan te spelen?',
            faqcopy: 'Voor een goede ervaring raden we je aan om een telefoon en een computer met een browser en een internetverbinding te gebruiken. Je kunt het spel echter ook met een smartphone spelen. Verder heb je een pen en papier nodig om aantekeningen te maken.'
          },
          {
            faqheading: 'Hoe speel je de online ontsnappingskamer van Captain Morgan met meer dan één persoon?',
            faqcopy: "Het enige wat je hoeft te doen is de link naar het spel en je persoonlijke code met maximaal vijf van je vrienden te delen. Zij kunnen dan allemaal meedoen aan het spel. Elke speler kan maximaal twee keer inloggen. Je hebt een week om de puzzel op te lossen voordat de toegang tot het spel vervalt. <br/><br/> Je hoeft niet allemaal dezelfde computer te gebruiken, want je kunt overal ter wereld samen spelen. Als jij en je vrienden zich op verschillende locaties bevinden, kun je online communiceren via een platform zoals Google Hangouts, WhatsApp, Skype of Zoom. Je kunt je vrienden ook thuis uitnodigen en samen op één computer spelen. "
          },
           {
            faqheading: 'Kan ik de online ontsnappingskamer alleen in het Nederlands spelen?',
            faqcopy: 'Het spel is nog niet beschikbaar in het Engels.'
          },
           {
            faqheading: 'Kan ik ergens aanwijzingen vinden voor de online ontsnappingskamer?',
            faqcopy: 'Als je vastloopt kun je op elk moment om een hint vragen. De oplossing wordt niet verteld, je krijgt alleen enkele nuttige tips en punten om over na te denken.'
          },
           {
            faqheading: 'Moeten alle spelers zich in dezelfde ruimte of op dezelfde plaats bevinden?',
            faqcopy: "Aangezien de online ontsnappingskamer wordt gespeeld via een webbrowser of smartphone, hoeven de spelers zich niet op dezelfde fysieke locatie te bevinden. Je kunt online met elkaar communiceren via Zoom, Google Hangouts of vergelijkbare online platforms."
          },
          {
            faqheading: 'Wie kan ik contacteren met vragen over de Captain Morgan Online Escape Room?',
            faqcopy: 'De beste manier om ons te contacteren is via ons e-mailadres cmsupport@diageo.com. We antwoorden je binnen 2 werkdagen.'
          }
        ]
      },
      gamecomplete: {
        spotifyLink: "https://www.spotify.com",
        heading: CompleteHeadingDT,
        subcopy: 'Je hebt het muziekfestival gered',
        yturl: 'https://youtu.be/qCbvemLQKoM',
        ytcopy: '<p>Krijg de 90s vibe met onze 25 min muziekselectie!</p> <p>De perfecte playlist voor een huisfeestje met je crew!</p>',
        gifurl: CompleteGifUrlDE
      }
    }
  }else if(route !== '' && route === 'poland'){
    routeData = {
      gameheader: {
        gamehero: GameHeroDE,
        gameherovid: GameHeroMP4DE,
        headerbg: EnterCodeHeaderBgDE,
        headertime: EnterCodeHeaderTimeDE,
        headerhand: EnterCodeHeaderHandDE,
        headercopy: "CAN YOU BREAK THE CAPTAIN'S RECORD",
        headerentercopy: 'WPISZ SWÓJ KOD TUTAJ:',
        headercodeplaceholdertext: '8-cyfrowy kod*',
        headerentersubmittext: 'Wyślij',
        headersubcopy: '',
        headertnccopy: 'Regulamin i często zadawane pytania',
        headertncurl: '/escapegamepoland/terms/',
        headernextimage: EnterCodeHeaderHandDE,
        headernextcopy: " NIE MASZ JESZCZE KODU?",
        headerfaqurl: '/escapegamepoland/terms/',
        headerfaqtext: '/escapegamepoland/terms/',
        headeranimation: HeaderAnimationPL,
        formterms: "* Akceptuję Regulamin oraz Politykę prywatności i ustawienia dotyczące plików cookies."
      },
      gamecode:{
        headingimage: CodeHeadingImagePL,
        heading: '<h3>Ahoy, Captain!</h3> <h3>Grab your crew and set sail...er, the desktop...to get ready for an adventure like never before!</h3>',
        copy: "<p>Share this website and the code with up to five friends who you think might have what it takes to help you in Captain Morgan's first digital escape room.<br/><br/>It became extra created for you together with DJ Psaiko.Dino, who even stands by your side in the game. But be careful - choose your crew wisely, because you'll want only the best of the best on your team as you solve the puzzles together and save the day.<br /><br />Dare you dare to embark on this adventure together to compete? Ahahaha, what a question, a \"No\" is not accepted anyway, because a real captain does not know fear!<br /><br />And last but not least, simply enter the code in the field and click on \"Go\". Good luck to you and your crew!<br /><br /></p><h4>Additional info:</h4><p>Don't have a code yet? Just follow these steps:</p><ol><li>Buy a promo bottle of Captain Morgan Original Spiced Gold at your local supermarket or online (link).</li><li>Scan the QR code and enter it unique access code from the neckhanger on the Captain Morgan website.</li><li>Assemble your crew and save the day together. Simple.</li></ol>",
        copyposter: MintCoolerPosterDE,
        copyimg: MintCoolerWebMDE,
        copyvideo: MintCoolerMP4PL,
        formterms: "I accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_de.html?locale=de-de' target='_blank'>Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_de.html?locale=de-de' target='_blank'>Privacy and Cookie Notice</a>. *",
        formcopybot: 'You want to stay up to date? Click below to sign up for our newsletter and receive future updates.',
        formregctatext: 'Sign up',
        formregctaurl: '/escapegamepoland/register/',
        formregtermsctatext: 'agb',
        formregtermsctaurl: '/escapegamepoland/terms/',
        redirectUrl: '/game_assets/poland/',
        rulestitle: '',
        rulessubtitle: " NIE MASZ JESZCZE KODU?",
        rulesbuyhereimg: RulesBuyPL,
        rulesbuyhereurl: 'https://www.amazon.de/Captain-Morgan-9-CM-006-35-Captain-Morgan-Original-Rumverschnitt/dp/B004K1D0BG?ref_=ast_sto_dp',
        rulesbuyheremodal: [{image:BuyLogoIntermarchePL, link:"https://intermarche.pl/"}, {image:BuyLogoAuchan, link:"https://www.auchan.pl/"}, {image:BuyLogoCarrefour, link:"https://serwiskorporacyjny.carrefour.pl/en/"}, {image:BuyLogoKaufland, link:"https://www.kaufland.pl/"}, {image:BuyLogoSelgros, link:"https://www.selgros.pl/"}],
        ruleslist: [
          {
            copy: 'Zdobądź promocyjną butelkę Captain Morgan',
            image: Icons1
          },
          {
            copy: 'Zeskanuj kod QR i wprowadź swój unikalny kod z zawieszki na butelce',
            image: Icons2
          },
          {
            copy: 'Zbierz sprawdzoną ekipę, podziel się z nią swoim kodem, przygotuj Mint Cooler i spróbujcie się wydostać!',
            image: Icons3
          }
        ],
        rulesfollowtext: 'śledź nas',
        rulesfollowurl: 'https://www.instagram.com/captainmorgan_official/',
        rulesFollowUrlFacebook:'https://www.facebook.com/CaptainMorgan/',
        rulesFollowUrlTwitter:'https://twitter.com/captainmorgan',
        rulestrailerurl: '',
        rulestnctext: '',
        rulestncurl: '',
        videomp4: MintCoolerMP4PL,
        videourl:'https://www.captainmorgan.com/en-gb/cocktails/captain-mint-cooler/#overview',
        umbrellagif: UmbrellaGifPL,
        computer: ComputerPL
      },
      gamewrapper: {
        gamepath: '/game_assets/poland/',    
        completepath:  "/escapegamepoland/complete/",
        enterpath:  "/escapegamepoland/enter-code/",
      },
      gameform: {
        title: '<h2>Lorem ipsum</h2>',
        copy: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>',
        termscopy: "I accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_de.html?locale=de-de' target='_blank'>Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_de.html?locale=de-de' target='_blank'>Privacy and Cookie Notice</a>. *",
        newslettercopy: 'Click here if you would like to receive special offers and news from Captain Morgan by email. You can unsubscribe at any time.',
        promocode: 'DWE028SMI0721WC0005',
        countryval: 54
      },
      gameterms: {
        heading: '[Gra Captain Morgan Escape Room]',
        headingcopy: 'Przy zakupie butelki rumu Captain Morgan Original Spiced Gold z etykietką na szyjce lub dwóch drinków Captain Morgan w punktach gastronomicznych biorących udział w akcji, klient uzyska indywidualny kod upoważniający do jednorazowego udziału w internetowym escape roomie, w którym można grać z maksymalnie pięcioma innymi dorosłymi osobami. Celem jest wspólne wykonywanie zadań, tj. rozwiązywanie zagadek i łamigłówek oraz udzielanie odpowiedzi na pytania, które pozwalają kontynuować grę. <br/><br/>Aby wziąć udział w grze, należy spełnić następujące warunki:',
        listcopy: [
          'Każda butelka opatrzona jest indywidualnym jednorazowym kodem, który znajduje się pod zdrapką pod etykietą na szyjce. Do zrealizowania kodu wymagany jest wcześniejszy zakup butelki Captain Morgan Original Spiced Gold lub Captain Morgan White lub Captain Morgan Dark.',
          'Aby wykorzystać otrzymany kod i wziąć udział w escape roomie online potrzebne jest urządzenie cyfrowe (komputer, smartfon, tablet itp.) oraz połączenie z internetem.',
          'Indywidualny kod znajdujący się pod zdrapką należy wpisać na stronie internetowej, do której dostęp uzyskasz wpisując adres w przeglądarce internetowej lub skanując kodu QR znajdujący się na zawieszce butelki. Do gry można zaprosić do pięciu innych graczy, dzieląc się z nimi indywidualnym kodem. <strong>Każdy gracz musi być mieszkańcem Polski i mieć ukończone 18 lat; osoby poniżej 18 roku życia nie mogą brać udziału w rozgrywce.</strong>',
          'Każdy kod może być zrealizowany tylko raz. Escape room jest dostępny przez tydzień od wpisania kodu, a każdy gracz może zalogować się maksymalnie dwa razy w ciągu tego tygodnia. Możliwość dołączenia do escape roomu wygasa 31 lipca 2023 roku o godzinie 23:59.',
          'Podmiotem organizującym escape room jest Diageo Polska Sp. z o.o, z siedzibą pod adresem <strong>Diageo Polska Sp. z o.o.</strong>, ul. Przyokopowej 31, 01-208 Warszawa.',
          '<strong>Diageo Polska Sp. z o.o</strong> nie ponosi odpowiedzialności za problemy techniczne związane z jakością połączenia internetowego lub cyfrowymi urządzeniami końcowymi, o ile nie wynikają one z umyślnego lub rażąco niedbałego naruszenia obowiązków przez <strong>Diageo Polska Sp. z o.o.</strong> Dotyczy to również osobistej odpowiedzialności przedstawicieli prawnych <strong>Diageo Polska Sp. z o.o</strong>, pracowników wykonawczych i pełnomocników.',
          'Niniejsza promocja oraz jej zasady i warunki podlegają prawu polskiemu i wyłącznej jurysdykcji sądów w Polsce. Nie przysługuje prawo do regresu prawnego.'
        ],
        faqtitle: '',
        faqlist: [
          {
            faqheading: 'Czym jest Escape Room Online?',
            faqcopy: 'Captain Morgan Escape Room Online to wirtualna gra, polegająca na wydostaniu się, w którą można zagrać z domu, samodzielnie lub z przyjaciółmi. Przeniesiesz się do trzymającej w napięciu historii o podróży w czasie, wypełnionej zagadkami, które musicie wspólnie rozwiązać. Gra trwa zazwyczaj od 45 do 90 minut, a grać może do 6 osób.'
          },
          {
            faqheading: 'Jak grać w Captain Morgan Online Escape Room?',
            faqcopy: "Aby zagrać w escape room online, nie musisz opuszczać z domowego zacisza. Wystarczy komputer, laptop lub smartfon z dostępem do internetu, a także telefon. Dobrze jest też mieć pod ręką kartkę papieru i długopis.<br/><br/> Jeśli Ty i Twoi znajomi znajdujecie się w różnych miejscach, możecie połączyć się na przykład przez Zoom, Skype lub Google Hangouts, aby móc się ze sobą komunikować."
          },
          {
            faqheading: 'Ile trzeba mieć lat, aby grać w Captain Morgan Online Escape Room?',
            faqcopy: 'Aby zagrać w escape room online trzeba mieć ukończone 18 lat.'
          },
          {
            faqheading: 'Czego potrzebujesz, aby grać w Captain Morgan Online Escape Room?',
            faqcopy: 'Aby gra przebiegała bezproblemowo, zalecamy korzystanie z komputera z przeglądarką i dostępem do internetu lub też z telefonu. Możesz jednak grać, używając smartfona. Do sporządzania notatek potrzebne są również długopis i kartka.'
          },
          {
            faqheading: 'Jak grać w Captain Morgan Online Escape Room w kilka osób?',
            faqcopy: "Wystarczy, że udostępnisz link do gry i swój osobisty kod maksymalnie pięciu znajomym. Wszyscy oni będą mogli wtedy dołączyć do gry. Każdy gracz może zalogować się maksymalnie dwa razy. Macie tydzień na rozwiązanie zagadki, zanim dostęp do gry wygaśnie. <br/><br/> Nie musicie korzystać z tego samego komputera ― możecie grać razem z dowolnego miejsca na świecie. Jeśli Ty i Twoi znajomi jesteście w różnych miejscach, wystarczy połączyć się online poprzez platformę taką jak Google Hangouts, WhatsApp, Skype lub Zoom, abyście mogli się ze sobą komunikować. Możesz też zaprosić przyjaciół do siebie i grać razem na jednym komputerze. "
          },
           {
            faqheading: 'Czy mogę grać w escape room online tylko w języku polskim?',
            faqcopy: 'Escape room nie jest jeszcze dostępny w języku angielskim.'
          },
           {
            faqheading: 'Czy w grze dostępne są jakieś wskazówki?',
            faqcopy: 'Jeśli nie udaje Ci się przejść dalej, możesz w każdej chwili poprosić o podpowiedź. Rozwiązanie nie zostanie Ci ujawnione, otrzymasz jedynie kilka pomocnych wskazówek i podpowiedzi.'
          },
           {
            faqheading: 'Czy wszyscy gracze muszą być w tym samym pomieszczeniu lub miejscu?',
            faqcopy: "Ponieważ wirtualny escape room jest rozgrywany z wykorzystaniem przeglądarki internetowej lub smartfona, gracze nie muszą znajdować się w tej samej lokalizacji. Można połączyć się online i komunikować się poprzez Zoom, Google Hangouts lub podobne aplikacje."
          }
        ]
      },
      gamecomplete: {
        spotifyLink: "https://www.spotify.com",
        heading: CompleteHeadingPL,
        subcopy: 'Uratowaliście festival muzyczny.',
        yturl: 'https://youtu.be/qCbvemLQKoM',
        ytcopy: '<p>Ciesz się muzyką z lat 90-tych</p> <p>To idealna playlista na imprezę z Twoją ekipą!</p>',
        gifurl: CompleteGifUrlDE
      }
    }
  }else if(route !== '' && route === 'germanyen'){
    routeData = {
      gameheader: {
        gamehero: GameHeroDE,
        gameherovid: MintCoolerMP4EG,
        headerbg: EnterCodeHeaderBgDE,
        headertime: EnterCodeHeaderTimeDE,
        headerhand: EnterCodeHeaderHandDE,
        headercopy: "CAN YOU BREAK THE CAPTAIN'S RECORD",
        headerentercopy: 'ENTER YOUR CODE HERE:',
        headercodeplaceholdertext: '8 DIGIT CODE *',
        headerentersubmittext: 'SEND',
        headersubcopy: '',
        headertnccopy: 'Terms & FAQs',
        headertncurl: '/escapegameenglish/terms/',
        headernextimage: EnterCodeHeaderHandDE,
        headernextcopy: "DIDN'T GET YOUR CODE YET MATEY?",
        headerfaqurl: '/escapegameenglish/terms/',
        headerfaqtext: '/escapegameenglish/terms/',
        headeranimation: HeaderAnimationEN,
        formterms: "* I accept the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_de.html?locale=de-de' target='_blank'>terms and conditions</a> and <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_de.html?locale=de-de' target='_blank'>Privacy & Cookie notice</a>."
      },
      gamecode:{
        headingimage: CodeHeadingImageEN,
        heading: '<h3>Ahoy, Captain!</h3> <h3>Grab your crew and set sail...er, the desktop...to get ready for an adventure like never before!</h3>',
        copy: "<p>Share this website and the code with up to five friends who you think might have what it takes to help you in Captain Morgan's first digital escape room.<br/><br/>It became extra created for you together with DJ Psaiko.Dino, who even stands by your side in the game. But be careful - choose your crew wisely, because you'll want only the best of the best on your team as you solve the puzzles together and save the day.<br /><br />Dare you dare to embark on this adventure together to compete? Ahahaha, what a question, a \"No\" is not accepted anyway, because a real captain does not know fear!<br /><br />And last but not least, simply enter the code in the field and click on \"Go\". Good luck to you and your crew!<br /><br /></p><h4>Additional info:</h4><p>Don't have a code yet? Just follow these steps:</p><ol><li>Buy a promo bottle of Captain Morgan Original Spiced Gold at your local supermarket or online (link).</li><li>Scan the QR code and enter it unique access code from the neckhanger on the Captain Morgan website.</li><li>Assemble your crew and save the day together. Simple.</li></ol>",
        copyposter: MintCoolerPosterDE,
        copyimg: MintCoolerWebMDE,
        copyvideo: MintCoolerMP4EG,
        formterms: "I accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_de.html?locale=de-de' target='_blank'>Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_de.html?locale=de-de' target='_blank'>Privacy and Cookie Notice</a>. *",
        formcopybot: 'You want to stay up to date? Click below to sign up for our newsletter and receive future updates.',
        formregctatext: 'Sign up',
        formregctaurl: '/escapegameenglish/register/',
        formregtermsctatext: 'agb',
        formregtermsctaurl: '/escapegameenglish/terms/',
        redirectUrl: '/game_assets/english/',
        rulestitle: '',
        rulessubtitle: "HAVEN'T GOT A CODE YET?",
        rulesbuyhereimg: RulesBuyEN,
        rulesbuyhereurl: 'https://www.amazon.de/stores/CaptainMorgan/page/43E9B7E5-ED24-41AC-9045-D47A98012BD7?ref_=ast_bln',
        ruleslist: [
          {
            copy: 'GET A CAPTAIN MORGAN ORIGINAL SPICED GOLD PROMO BOTTLE AT YOUR USUAL SUPERMARKET BAR OR:',
            image: Icons1
          },
          {
            copy: 'SCAN THE QR CODE AND ENTER THE UNIQUE ACCESS CODE FROM THE NECKHANGER ON THE CAPTAIN MORGAN DIGITAL ESCAPE ROOM WEBSITE.',
            image: Icons2
          },
          {
            copy: 'GET YOUR CREW TOGETHER AND SAVE THE DAY. THAT EASY.',
            image: Icons3
          }
        ],
        rulesfollowtext: '',
        rulesfollowurl: 'https://www.instagram.com/captainmorgande/',
        rulesFollowUrlFacebook:'https://www.facebook.com/CaptainMorganDE',
        rulesFollowUrlTwitter:'https://twitter.com/captainmorgande',
        rulestrailerurl: '',
        rulestnctext: '',
        rulestncurl: '',
        videomp4: MintCoolerMP4EG,
        videourl:'https://www.captainmorgan.com/en-gb/cocktails/captain-mint-cooler/#overview',
        umbrellagif: UmbrellaGifEG
      },
      gamewrapper: {
        gamepath: '/game_assets/english/',    
        completepath:  "/escapegameenglish/complete/",
        enterpath:  "/escapegameenglish/enter-code/",
      },
      gameform: {
        title: '<h2>Lorem ipsum</h2>',
        copy: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>',
        termscopy: "I accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_de.html?locale=de-de' target='_blank'>Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_de.html?locale=de-de' target='_blank'>Privacy and Cookie Notice</a>. *",
        newslettercopy: 'Click here if you would like to receive special offers and news from Captain Morgan by email. You can unsubscribe at any time.',
        promocode: 'DWE028SMI0721WC0005',
        countryval: 54
      },
      gameterms: {
        heading: '[Captain Morgan Escape Room Game]',
        headingcopy: 'When purchasing Captain Morgan Original Spiced Gold with a bottle tag or 2 Captain Morgan drinks in participating restaurants, the individual code contained therein entitles you to participate once in a digital escape room, which can be played together with up to 5 other adult players. The aim is to master tasks together in a playful way, i.e. to solve riddles and puzzles and to answer questions. The way is the goal.<br /><br />The following conditions apply to the claim:',
        listcopy: [
          'Each bottle tag carries an individual code for one-time use. This is located under the scratch field on the inside of the trailer. Code redemption requires prior purchase of Captain Morgan Original Spiced Gold.',
          'The use of the individual code to participate in the digital escape room requires a digital device (computer, smartphone, tablet, etc.) and an internet connection.',
          'To redeem the individual code, the individual code revealed under the scratch field can be entered on this website. This page can be entered as an address in the web browser; alternatively, the page can be opened by scanning the QR code on the bottle tag. Up to 5 other players can then be invited by sharing the individual code with them. <strong>Each player must be a resident of Germany over the age of 18; the participation of minors is excluded.</strong>',
          'Each code can only be redeemed once. The Escape Room is accessible for a week after redemption, with each player being able to log in a maximum of two times during this week. Regardless, the opportunity to participate in the Escape Room ends at 11:59 p.m. on July 31, 2023.',
          'The provider of the escape room is DIAGEO Germany GmbH with its main place of business: DIAGEO Germany GmbH, Reeperbahn 1, 20359 Hamburg. Tel.: +49 (0)40 236486-0, commercial register: AG Hamburg (HRB 41802), register number: HRB 20145, VAT ID no.: DE 118539398s, managing director: Soraya Zoueihed Benchikh.',
          'Diageo GmbH is not liable for technical problems with the Internet connection or the digital end devices, unless these are caused by an intentional or grossly negligent breach of duty by Diageo GmbH. This also applies to the personal liability of the legal representatives, executive employees and simple vicarious agents of Diageo GmbH.',
          'This promotion and these terms and conditions are governed by German law and are subject to the exclusive jurisdiction of the German courts. The legal process is excluded.'
        ],
        faqtitle: '',
        faqlist: [
           {
            faqheading: 'What is an online escape room?',
            faqcopy: 'Our Captain Morgan Online Escape Room is a virtual escape game that you can play alone or with friends from the comfort of your own home. You become part of an exciting story about time travel, which is full of puzzles that you have to solve. The game usually lasts between 45 and 90 minutes and can be played with up to 6 people.'
          },
          {
            faqheading: 'How does the Captain Morgan Online Escape Room work?',
            faqcopy: "You can play to our escape room from the comfort of your home. All you need is a PC, laptop or smartphone with internet access and a telephone. We also recommend that you have a piece of paper and a pen ready. <br/> <br/>If you and your friends are in several places, you can use e.g. B. Zoom, Skype or Hangouts to communicate with each other."
          },
          {
            faqheading: 'From what age can you play the Captain Morgan Online Escape Room?',
            faqcopy: 'You must be at least 18 years old to play our online escape room.'
          },
          {
            faqheading: 'What are the requirements to play the Captain Morgan Online Escape Room?',
            faqcopy: 'For a smooth gaming experience, we recommend a computer with a browser and an internet connection and a telephone. But it is also possible to play with a smartphone. You will also need a pen and paper to take notes.'
          },
          {
            faqheading: 'How to play Captain Morgan Online Escape Room with multiple people?',
            faqcopy: "All you have to do is share with your friends (up to five people) the game's website URL and your unique code. That way, everyone has access to the game. Each player can log in up to two times. You have one week to solve the game before access expires. <br/><br/>If you and your friends are in different places, you can easily connect digitally and use services such as Hangouts, WhatsApp, Skype or Zoom to communicate and exchange ideas. So you don't have to use the same computer, you can play together from anywhere in the world. Alternatively, you can invite your friends over to your home and just play together on your computer."
          },
           {
            faqheading: 'Is the Online Escape Game only available in German?',
            faqcopy: 'For the mean time only in German, Polish, Dutch and French, but soon you will be able to play our Online Escape Room in English.'
          },
           {
            faqheading: 'Are there any hints for the Online Escape Game?',
            faqcopy: 'You can always ask for hints if you get stuck. However, the solution will not be revealed to you immediately, only helpful tips and food for thought will be given.'
          },
           {
            faqheading: 'Do all players have to be in the same room/place?',
            faqcopy: "Since our online escape room is played via internet browser or smartphone, the players do not have to be in the same place. You can also connect digitally via the Internet and communicate with each other using Zoom, Hangouts or similar platforms. This means that playing is possible from any location."
          },
          {
            faqheading: 'Who can I contact with questions about the Captain Morgan Online Escape Room?',
            faqcopy: 'The best way to contact us is via our email address cmsupport@diageo.com. We will come back to you within 2 working days.'
          }
        ]
      },
      gamecomplete: {
        spotifyLink: "https://www.spotify.com",
        heading: CompleteHeading,
        subcopy: 'You saved the music festival',
        yturl: 'https://youtu.be/qCbvemLQKoM',
        ytcopy: '<p>get the 90s vibe with our 25 min music selection!</p> <p>the perfect playlist for a house party with your crew!</p>',
        gifurl: CompleteGifUrlDE
      }
    }
  }else if(route !== '' && route === 'slovakia'){
    routeData = {
      gameheader: {
        gamehero: '',
        gameherovid: MintCoolerMP4EG,
        headerbg: EnterCodeHeaderBgDE,
        headertime: "",
        headerhand: EnterCodeHeaderHandDE,
        headercopy: "Vitaj v digitálnej únikovej hre Captain Morgan",
        headerentercopy: 'SEM ZADAJ SVOJ KÓD:',
        headercodeplaceholdertext: '8-miestny kód *',
        headerentersubmittext: 'Poslať',
        headersubcopy: '',
        headertnccopy: 'PODMIENKY A NAJČASTEJŠIE OTÁZKY',
        headertncurl: '/escapegameslovakia/terms/',
        headernextimage: EnterCodeHeaderHandDE,
        headernextcopy: "EŠTE NEMÁŠ KÓD?",
        headerfaqurl: '/escapegameslovakia/terms/',
        headerfaqtext: '/escapegameslovakia/terms/',
        headeranimation: '',
        formterms: "* I accept the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_de.html?locale=de-de' target='_blank'>terms and conditions</a> and <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_de.html?locale=de-de' target='_blank'>Privacy & Cookie notice</a>."
      },
      gamecode:{
        headingimage: CodeHeadingImageSV,
        heading: '<h3>Ahoy, Captain!</h3> <h3>Grab your crew and set sail...er, the desktop...to get ready for an adventure like never before!</h3>',
        copy: "<p>Share this website and the code with up to five friends who you think might have what it takes to help you in Captain Morgan's first digital escape room.<br/><br/>It became extra created for you together with DJ Psaiko.Dino, who even stands by your side in the game. But be careful - choose your crew wisely, because you'll want only the best of the best on your team as you solve the puzzles together and save the day.<br /><br />Dare you dare to embark on this adventure together to compete? Ahahaha, what a question, a \"No\" is not accepted anyway, because a real captain does not know fear!<br /><br />And last but not least, simply enter the code in the field and click on \"Go\". Good luck to you and your crew!<br /><br /></p><h4>Additional info:</h4><p>Don't have a code yet? Just follow these steps:</p><ol><li>Buy a promo bottle of Captain Morgan Original Spiced Gold at your local supermarket or online (link).</li><li>Scan the QR code and enter it unique access code from the neckhanger on the Captain Morgan website.</li><li>Assemble your crew and save the day together. Simple.</li></ol>",
        copyposter: MintCoolerPosterDE,
        copyimg: MintCoolerWebMDE,
        copyvideo: MintCoolerMP4EG,
        formterms: "I accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_de.html?locale=de-de' target='_blank'>Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_de.html?locale=de-de' target='_blank'>Privacy and Cookie Notice</a>. *",
        formcopybot: 'You want to stay up to date? Click below to sign up for our newsletter and receive future updates.',
        formregctatext: 'Sign up',
        formregctaurl: '/escapegameslovakia/register/',
        formregtermsctatext: 'agb',
        formregtermsctaurl: '/escapegameslovakia/terms/',
        redirectUrl: '/game_assets/slovakia/',
        rulestitle: '',
        rulessubtitle: "EŠTE NEMÁŠ KÓD?",
        rulesbuyhereimg: RulesBuySV,
        rulesbuyhereurl: 'https://www.alkoshop.sk/product/rum-captain-morgan-original-spiced-gold-35-07l/',
        ruleslist: [
          {
            copy: 'Kúp si špeciálnu fľašu Captain Morgan',
            image: Icons1
          },
          {
            copy: 'Naskenuj QR kód a v hornej časti stránky zadaj jedinečný kód, ktorý nájdeš na štítku na hrdle fľaše.',
            image: Icons2
          },
          {
            copy: 'Daj dokopy bandu, zdieľaj so všetkými kód, namiešaj Mint Cooler a skús uniknúť!',
            image: Icons3
          }
        ],
        rulesfollowtext: '',
        rulesfollowurl: 'https://www.instagram.com/captainmorgande/',
        rulesFollowUrlFacebook:'https://www.facebook.com/CaptainMorganDE',
        rulesFollowUrlTwitter:'https://twitter.com/captainmorgande',
        rulestrailerurl: '',
        rulestnctext: '',
        rulestncurl: '',
        videomp4: MintCoolerMP4EG,
        videourl:'https://www.captainmorgan.com/en-gb/cocktails/captain-mint-cooler/#overview',
        umbrellagif: UmbrellaGifSV
      },
      gamewrapper: {
        gamepath: '/game_assets/escapegameslovakia/',    
        completepath:  "/escapegameslovakia/complete/",
        enterpath:  "/escapegameslovakia/enter-code/",
      },
      gameform: {
        title: '<h2>Lorem ipsum</h2>',
        copy: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>',
        termscopy: "I accept Diageo's <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_de.html?locale=de-de' target='_blank'>Conditions of Use</a> and acknowledge the <a href='https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_de.html?locale=de-de' target='_blank'>Privacy and Cookie Notice</a>. *",
        newslettercopy: 'Click here if you would like to receive special offers and news from Captain Morgan by email. You can unsubscribe at any time.',
        promocode: 'DWE028SMI0721WC0005',
        countryval: 54
      },
      gameterms: {
        heading: '[Captain Morgan Escape Room Game]',
        headingcopy: 'Keď si zákazník zakúpi fľašu Captain Morgan Original Spiced Gold so štítkom alebo dva drinky Captain Morgan v zapojených podnikoch, vďaka jedinečnému kódu získava nárok na jednorazovú účasť v únikovej hre online, ktorú môžu hrať najviac piati dospelí. Cieľom je spoločne plniť úlohy, teda riešiť hlavolamy a rébusy, a odpovedať na otázky, čím sa účastníci posúvajú v hre ďalej.<br /><br />Na tento nárok sa vzťahujú nasledujúce podmienky:',
        listcopy: [
          'Každý štítok na fľaši obsahuje jedinečný jednorazový kód, ktorý sa nachádza pod stieracou vrstvou vnútri štítku. Na uplatnenie kódu je potrebný predchádzajúci nákup Captain Morgan Original Spiced Gold.',
          'Na použitie jedinečného kódu a na účasť v únikovej hre je potrebné digitálne zariadenie (počítač, smartfón, tablet atď.) a internetové pripojenie.',
          'Ak si chcete uplatniť jedinečný kód spod stieracej plôšky, je možné ho zadať na tejto webovej stránke, na ktorú prejdete zadaním adresy do webového prehliadača alebo naskenovaním QR kódu na štítku z fľaše. Zdieľaním jednorazového kódu možno pozvať päť ďalších hráčov. <strong>Každý z hráčov musí mať bydlisko v krajine konania hry Slovakia a najmenej 18 rokov. Zúčastniť sa nesmie žiadna osoba mladšia ako 18 rokov.</strong>',
          'Každý kód je možné uplatniť iba raz. Prístup k únikovej hre je k dispozícii jeden týždeň od uplatnenia kódu, pričom každý z hráčov sa počas tohto týždňa môže prihlásiť najviac dvakrát. Všeobecná možnosť prístupu k únikovej hre sa končí o 23:59 dňa 31. júla 2023.',
          'TÚnikovú hru poskytuje spoločnosť {Diageo Germany GmbH} so sídlom na adrese: AG Hamburg (HRB 41802), register number: HRB 20145, VAT ID no.: DE 118539398s, managing director: Soraya Zoueihed Benchikh.',
          'Spoločnosť Diageo {GmbH} nenesie zodpovednosť za prípadné technické problémy s internetovým pripojením alebo koncovými digitálnymi zariadeniami, pokiaľ k nim nedôjde pre porušenie povinností zo strany spoločnosti Diageo {GmbH} v dôsledku zámeru alebo hrubej nedbanlivosti. Platí to aj pre osobnú zodpovednosť právnych zástupcov, riadiacich pracovníkov a radových sprostredkovateľov spoločnosti Diageo GmbH.',
          'Na túto akciu a tieto podmienky sa vzťahujú právne predpisy a výhradná právomoc súdov krajiny konania Slovakia. K dispozícii nie je právo na regres.'
        ],
        faqtitle: '',
        faqlist: [
           {
            faqheading: 'Čo je online úniková hra?',
            faqcopy: 'Captain Morgan Online Escape Room je virtuálna úniková hra, ktorú môžete hrať doma sami alebo s priateľmi. Ponoríte sa do napínavého príbehu o cestovaní v čase plnom hlavolamov, ktoré musíte spoločne rozlúštiť. Hra obvykle trvá 45 až 90 minút, pričom ju môže hrať najviac 6 ľudí.'
          },
          {
            faqheading: 'Ako funguje úniková hra Captain Morgan Online Escape Room?',
            faqcopy: "Ak si chcete zahrať túto únikovú hru online, nemusíte sa vzdať pohodlia domova. Stačí vám počítač, notebook alebo smartfón s prístupom na internet a telefónne číslo. Hodí sa aj papier a pero.<br/> <br/>If you and your friends are in several places, you can use e.g. B. Zoom, Skype or Hangouts to communicate with each other."
          },
          {
            faqheading: 'Koľko musíte mať rokov, aby ste si mohli zahrať hru Captain Morgan Online Escape Room?',
            faqcopy: 'Aby ste si mohli zahrať túto únikovú hru, musíte mať aspoň 18 rokov.'
          },
          {
            faqheading: 'Čo potrebujete, aby ste si mohli zahrať hru Captain Morgan Online Escape Room?',
            faqcopy: 'Na bezproblémovú hru môžete použiť počítač s prehliadačom a internetovým pripojením a telefón. Môžete však hrať aj pomocou smartfónu. Budete potrebovať papier a pero na zapisovanie poznámok.'
          },
          {
            faqheading: 'Ako sa hrá Captain Morgan Online Escape Room s viac ako jedným hráčom?',
            faqcopy: "Stačí zdieľať odkaz na hru a váš osobný kód s  najviac piatimi priateľmi. Tak sa budú môcť všetci zapojiť do hry. Každý z hráčov sa môže prihlásiť najviac dvakrát. Na vylúštenie hlavolamu máte jeden týždeň, potom platnosť prístupu k hre vyprší.<br/><br/>Nemusíte všetci používať ten istý počítač. Hry sa môžete zúčastniť z ktoréhokoľvek kúta sveta. Ak sa vy a vaši priatelia nenachádzate na jednom mieste, spojte sa online pomocou platformy, ako je Google Hangouts, WhatsApp, Skype alebo Zoom, aby ste mohli navzájom komunikovať. Prípadne môžete priateľov pozvať na návštevu a zahrať si spoločne na jednom počítači."
          },
           {
            faqheading: 'Môže sa online úniková hra hrať iba v jazyku German?',
            faqcopy: 'Online úniková hra zatiaľ nie je k dispozícii v angličtine.'
          },
           {
            faqheading: 'Sú pre online únikovú hru k dispozícii aj nejaké pomôcky?',
            faqcopy: 'Ak nebudete vedieť pokračovať, kedykoľvek môžete požiadať o pomoc. Riešenie vám neprezradíme, dostanete však užitočné rady, ktoré vás navedú správnym smerom.'
          },
           {
            faqheading: 'Musia sa všetci hráči nachádzať v tej istej miestnosti alebo na tom istom mieste?',
            faqcopy: "Keďže online úniková hra sa hrá prostredníctvom webového prehliadača alebo smartfónu, hráči nemusia byť fyzicky na tom istom mieste. Môžete sa spojiť online a navzájom komunikovať cez Zoom, Google Hangouts alebo podobnú službu."
          },
          {
            faqheading: 'Who can I contact with questions about the Captain Morgan Online Escape Room?',
            faqcopy: 'The best way to contact us is via our email address cmsupport@diageo.com. We will come back to you within 2 working days.'
          }
        ]
      },
      gamecomplete: {
        spotifyLink: "https://www.spotify.com",
        heading: CompleteHeadingSV,
        subcopy: 'Dobrá práca, námorník! Podarilo sa ti zachrániť hudobný festival.',
        yturl: 'https://youtu.be/qCbvemLQKoM',
        ytcopy: '<p>Pusti si nejakú hudbu z 90-ok na Spotify.</p> <p>Dokonalý playlist na párty s bandou u teba doma!</p>',
        gifurl: CompleteGifUrlDE
      }
    }
  }

  return routeData
}
