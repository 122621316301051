/* eslint-disable no-empty-function */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable max-len, max-lines, no-console, no-return-assign, class-methods-use-this, max-statements */
/* globals mapboxgl, MapboxGeocoder */
import "./style.scss";
import React from "react";


class WhereToBuyShopOnline extends React.Component {

    constructor (props) {

        super(props);
        this.findLink = "";

    }

    componentDidMount () {


    }

    handleSearch () {

        console.log(this.findLink);
        if (this.findLink) {

            window.location.href = `/where-to-buy/find-near-me/?lat=${this.findLink.center[0]}&lng=${this.findLink.center[1]}&text=${this.findLink.place_name}`;

        } else {

            window.location.href = "/where-to-buy/find-near-me/";

        }

    }

    render () {

        return (
			<>
			 <main className="main">
			    <section className="section">
			        <div className="container">
                <header className="where-to-buy__hero">
                  <div className="where-to-buy__hero-content">
                    <h1 className="where-to-buy__hero-title gold_font">Shop Online</h1>
                  </div>
			          </header>
			          <iframe id="wtbIframe" src="https://where-to-buy.co/widgets/core/BuyOnlineBuyLocalV2/index.html?pid=12037984" title="Where To Buy" aria-label="Where To Buy" />
			        </div>
			    </section>
			 </main>
			</>
        );

    }

}

export default WhereToBuyShopOnline;
