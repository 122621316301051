import "./content-drinksworks.scss";
import React from "react";

import * as utils from 'global/utils/utils'

const DWContentQuestions = (props) => {
  const pageLocale = props && props.pagelocale ? props.pagelocale : ""
  const contentData = getData(pageLocale)
  
  function goToNextPage (e, item) {
    const target = e.target
    let label
    let answer
    if(target && (target.dataset && target.dataset.title)) {
      answer = target.dataset.title
    }
    if(target && (target.dataset && target.dataset.title)) {
      label = target.dataset.question
    }
    const ga = {
      'event':'form',
      'form_name': `Drinksworks_${pageLocale}`,  
      'form_field': label,
      'detail': answer
    }
    utils.gaSubmit(ga)
    props.nextpagecallback(item)
  }
  function getData (pageLocale) {
    const data = [
      {
        locale: 'en-us',
        data: {
          headertitle: '<span class="text--white dwtitle--span dwtitle--small">discover</span><span class="text--white dwtitle--span dwtitle--large">Tasty Recipes</span>',
          questions: [
            {
              title: "You're all about classic cocktail flavours, without the fuss",
              recipe: 'mojito'
            },
            {
              title: "Sweet and tropical flavours are up your street",
              recipe: 'tikilemonade'
            },
            {
              title: "You're always looking to discover fresh new flavours",
              recipe: 'applelemonade'
            },
            {
              title: "You're a porveyor of pineapple on you pizza",
              recipe: 'captainexotic'
            }
          ]
        }
      },
      {
        locale: 'de-de',
        data: {
          headertitle: '<span class="text--white dwtitle--span dwtitle--small">ENTDECKE</span><span class="text--white dwtitle--span dwtitle--large">LECKERE REZEPTE</span>',
          questiontitle: 'ENTDECKE LECKERE REZEPTE',
          questions: [
            {
              title: "DU STEHST AUF KLASSISCHEN COCKTAILGESCHMACK, OHNE VIEL AUFWAND",
              recipe: 'de_recipe_1'
            },
            {
              title: "SÜSSE UND TROPISCHE GESCHMACKSRICHTUNGEN SIND GENAU DAS RICHTIGE FÜR DICH",
              recipe: 'de_recipe_2'
            },
            {
              title: "DER SOMMER ENDET NIE IN DER KARIBIK",
              recipe: 'de_recipe_3'
            },
            {
              title: "MIX IT FLAVOUR FULL & ALKOHOLFREI",
              recipe: 'de_recipe_4'
            }
          ]
        }
      }
    ]

    if(pageLocale) {
      const contentItems = data.filter(x => x.locale === pageLocale)[0]
      if(contentItems) {
        return contentItems.data
      }else{
        return null
      }
    }else{
      return null
    }
  }

  return (
    <div className="dwcontent">
      <h1 className="dwcontent__title dwtitle" dangerouslySetInnerHTML={{
        __html: `${contentData.headertitle}
        `
        }}
      />
      {contentData.questions && contentData.questions.length > 0 &&
        <div className="dwcontent__questions questions">
          {contentData.questions.map( (question, key) => {
            return (
              <button key={`question_${key}`} data-title={question.title} data-question={contentData.questiontitle} className="btn btn--goldbggradient btn--text-small" onClick={(e) => goToNextPage(e, question.recipe)}>
                {question.title}
              </button>
            )
          })}          
        </div>
    }      
    </div>
  );
};

export default DWContentQuestions;
