import "./buy-product-promo.scss";
import Cta from "../../atoms/cta";
import Lazy from "global/utils/lazy";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

const BuyProductPromo = (props) => {

    const {
        CardImage,
        CardTitle,
        CardSummary,
        CardCtaText,
        HeroBackgroundColour,
        Url,
    } = fieldsArrayToKeyValueHash(props.fields);

    if (!CardTitle || !CardCtaText || !Url) {

        return false;

    }

    const backgroundColor = {backgroundColor: HeroBackgroundColour && `#${HeroBackgroundColour}`};
    const CardUrl = CardImage.find((field) => field.title === "Url");
    const CardAlt = CardImage.find((field) => field.title === "Alt");

    const cta = {
        fields: [
            {
                title: "Link",
                value: Url,
            },
            {
                title: "Caption",
                value: CardCtaText,
            },
        ],
    };

    return (
        <aside className="buy-product-promo" style={backgroundColor}>
            <div className="buy-product-promo__inner">
                <div className="buy-product-promo__content">
                    { CardUrl.value &&
                        <Lazy
                            Tag="div"
                            containerClassName="buy-product-promo__visual"
                        >
                            <img className="buy-product-promo__visual-image"
                                src={CardUrl.value}
                                alt={CardAlt.value && CardAlt.value} />
                        </Lazy>
                    }
                    <div className="buy-product-promo__copy">
                        <h2 className="buy-product-promo__heading">
                            {CardTitle}
                        </h2>
                        <Cta
                            cta={cta}
                            version="secondary"
                            varient="ghost"
                        />
                    </div>
                </div>
            </div>
        </aside>
    );

};

export default BuyProductPromo;
