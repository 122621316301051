import Helmet from "react-helmet";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

export default class StructuredData extends React.Component {

    render () {

        const {StructuredDataValue} = fieldsArrayToKeyValueHash(this.props.fields);

        return <Helmet
            script={[
                {
                    innerHTML: `${StructuredDataValue}`,
                    type: "application/ld+json",
                },
            ]}
        />;

    }

}
