import "./drink-aware-banner.scss";
import {BlockingLink} from "global/utils/blocking-link";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";


const DrinkAwareBanner = (props) => {

    const {
        DrinkResponsiblyCtaTitle,
        DrinkResponsiblyCtaText,
        DrinkResponsiblyCtaTarget,
        DrinkResponsiblyCtaUrl,
    } = fieldsArrayToKeyValueHash(props.fields);

    if (!DrinkResponsiblyCtaTitle) {

        return false;

    }

    return (
        <div className="drink-aware-banner">
            <div className="drink-aware-banner__inner">
                <strong className="drink-aware-banner__text">
                    {DrinkResponsiblyCtaTitle}
                </strong>
                <span className="drink-aware-banner__action">
                    <BlockingLink
                        target={DrinkResponsiblyCtaTarget}
                        to={DrinkResponsiblyCtaUrl}
                        className={"drink-aware-banner__action-link"}
                    >
                        {DrinkResponsiblyCtaText}
                    </BlockingLink>
                </span>
            </div>
        </div>
    );

};

export default DrinkAwareBanner;
