/* eslint-disable no-magic-numbers */
/* eslint-disable max-statements */
/* eslint-disable no-console */
import "./page-default.scss";
import $ from 'jquery'
import React, {Component} from "react";
import {Helmet} from 'react-helmet'
import AppError from "../../organisms/app-error";
import RsvpForm from "../../organisms/RsvpForm";
import sanitise from "global/utils/sanitise";
import buildPage from "../page-builder";
import heroImage from 'global/images/en-us-custom/en-us-custom-header_v3.jpg'
import heroImageCans from 'global/images/en-us-custom/header_cans.png'
import heroImageMobile from 'global/images/en-us-custom/header_bg_mobile.jpg'
import heroImageFG from 'global/images/en-us-custom/en-us-custom-header-overlay.png'
import heroImageFGmobile from 'global/images/en-us-custom/header_overlay_mobile.png'
import heroImageGB from 'global/images/en-us-custom/en-us-custom-header_v2.jpg'
import timerBG from 'global/images/en-us-custom/timer_bg.jpg'
import rsvpImage1 from 'global/images/en-us-custom/rsvp_1.jpg'
import rsvpImage2 from 'global/images/en-us-custom/rsvp_2.jpg'
import rsvpImage3 from 'global/images/en-us-custom/rsvp_3.jpg'
import redBackground from 'global/images/backgrounds/german-red-background.jpg'
import btsPlaceholder from 'global/images/en-us-custom/tropi_kit_v2.png'
import spikedLogo from 'global/images/en-us-custom/spiked_logo_white.png'

export default class PageContainer extends Component {

    state = {
        "isLoaded": false,
        "setRoutes": false,
        "video":""
    };

    constructor (props) {

        super(props);
        this.fetchData();

    }

    async init () {
      try {
        const res = await fetch("/api/rsvp/status", {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",

          },
          method: "POST",
        // eslint-disable-next-line no-shadow
        }).then((res) => res.json());

        if(res && res.data) {
          res.data.forEach(loc => {
            if(loc && loc.venueFull) {
              if(!$(`#${loc.eventId}`).hasClass('disabled')) {
                $(`#${loc.eventId}`).addClass('disabled').prop('disabled', true).prop('href', '#').html('VENUE FULL')
              }
            }
          })
        }
      }catch(error) {
        console.log('INIT ERROR', error)
      }
    }

    getPageName () {

        const path = this.props.location.pathname
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'>/g, "&#x27;");

        if (path.endsWith("/")) {

            return path;

        }

        return `${path}/`;

    }

    fetchData () {

        const pageName = this.getPageName();

        if (!this.props.data[pageName]) {

            // No! Lots wrong here:
            // - whatabout fetching data?
            // - Transition should be blocked before
            //   We have the data to avoid rendering noop
            this.props.fetchData(
                {name: pageName},
                false
            );

        }

    }

    componentDidUpdate (prevProps) {
        const newLocation = this.props.location.pathname;
        
        const {data} = this.props;

        if (prevProps.location.pathname !== newLocation && !data[pageName]) {

            this.props.fetchData(
                {name: newLocation},
                false
            );

        }
        
        $(function () {
          // Set the date we're counting down to
          const countDownDate = new Date('2023-07-21T09:00:00.000-04:00').getTime()

          // Update the count down every 1 second
          let x = setInterval(function () {
              // Get today's date and time
              const now = new Date().getTime()

              // Find the distance between now and the count down date
              const distance = countDownDate - now

              // Time calculations for days, hours, minutes and seconds
              let days = Math.floor(distance / (1000 * 60 * 60 * 24))
              let hours = Math.floor(
                  (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
              )
              let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
              let seconds = Math.floor((distance % (1000 * 60)) / 1000)

              // Display the result in the element with id="demo"
              //   document.getElementById("demo").innerHTML = days + "d " + hours + "h "
              //   + minutes + "m " + seconds + "s ";
              const daysDiv = document.getElementById('days')
              const hoursDiv = document.getElementById('hours')
              const minutesDiv = document.getElementById('minutes')
              const secDiv = document.getElementById('seconds')

              if(daysDiv && hoursDiv && minutesDiv && secDiv) {
                daysDiv.innerHTML = days
                hoursDiv.innerHTML = hours
                minutesDiv.innerHTML = minutes
                secDiv.innerHTML = seconds
                // If the count down is finished, write some text
                if (distance < 0) {
                    clearInterval(x)
                    document.getElementById('days').innerHTML =
                        '<span>' + '0' + '</span> <br/> days '
                    document.getElementById('hours').innerHTML =
                        '<span>' + '0' + '</span> <br/> hours '
                    document.getElementById('minutes').innerHTML =
                        '<span>' + '0' + '</span> <br/> minutes '
                    document.getElementById('seconds').innerHTML =
                        '<span>' + '0' + '</span> <br/> seconds '
                }
              }
          }, 1000)

          $('.disabled').on('click', function (e) {
            e.preventDefault()
          })
        })
      this.init()
    }

    render () {

        const {data} = this.props;

        const pageName = this.getPageName();
        const pageSplit = pageName.split('/');
        let showRegister = false;

        if (!data[pageName] || data[pageName].isFetching) {
            // In future, avoid this!
            return null;
        }

        if(pageSplit.includes('jamaicaqueens') 
          || pageSplit.includes('sanjuancapistrano') 
          || pageSplit.includes('jamaicaplain')) {
          showRegister = true
        }
        const page = buildPage(data, pageName);

        // IF the API is unreachable
        // OR the page response is not as expected
        // BuildPage will return null
        // Without this the App will error server side and bring down the node server
        if (page === null) {

            return (
                <div className="page">
                    <AppError
                        message="This site is currently unavailable - Come back soon."
                    />
                </div>
            );


        }
     
        let locale
        if(this.props.location.pathname.includes("en-gb")){
          locale="en-gb"
        } else if(this.props.location.pathname.includes("en-za")) {
              locale="en-za"
        }else if(this.props.location.pathname.includes("en-us")) {
              locale="en-us"
        }
        page.headerComponents[0].props.fields[0].value = "A Taste of the Tropics | Captain Morgan";
        page.headerComponents[0].props.fields[4].value = "200";
        return (
            <div className="page-uscm">
                 {page.headerComponents}
                <header className="hero" style={{backgroundImage: `url(${heroImage})`}}>
                  <div className="hero--mobile" style={{backgroundImage: `url(${heroImageMobile})`}}></div>
                  <div className="hero__foreground foreground">
                    <img src={heroImageFG} className="foreground__image foreground__image--desktop" aria-hidden="true" />
                    <img src={heroImageFGmobile} className="foreground__image foreground__image--mobile" aria-hidden="true" />
                  </div>
                  <div className="hero__cans">
                    <img src={heroImageCans} className="cans__image foreground__image--desktop" aria-hidden="true" />
                  </div>
                  <div className="hero__content">
                      <h1 className="hero__title gold_font">
                        <span className="hero__small">A</span>
                        taste of the tropics<br/>experience
                      </h1>
                      <div className="hero__copy">
                        Three parties. Three artists. Three different non-tropical cities that will be transported to the tropics for an evening.
                      </div>
                  </div>
                </header>
              
                <main className="page__content" id="main" role="main">
                  {showRegister === true &&
                    <RsvpForm location={this.props.location} />
                  }

                  {showRegister === false &&
                    <section className="timer hidden" style={{backgroundImage: `url(${timerBG})`}}>
                      <div className="timer__wrap">
                        <h2 className="timer__title">
                          Next Round of RSVP’s Open in
                        </h2>
                        <div className="timer__content countdown">
                          <div className="contdown__block block">
                            <div className="block__number" id="days">
                              00
                            </div>
                            <div className="block__text">
                              Days
                            </div>
                          </div>

                          <div className="contdown__block block">
                            <div className="block__number block__number--spacer">
                              :
                            </div>
                            <div className="block__text">
                              &nbsp;
                            </div>
                          </div>

                          <div className="contdown__block block">
                            <div className="block__number" id="hours">
                              21
                            </div>
                            <div className="block__text">
                              hours
                            </div>
                          </div>

                          <div className="contdown__block block">
                            <div className="block__number block__number--spacer">
                              :
                            </div>
                            <div className="block__text">
                              &nbsp;
                            </div>
                          </div>

                          <div className="contdown__block block">
                            <div className="block__number" id="minutes">
                              42
                            </div>
                            <div className="block__text">
                              minutes
                            </div>
                          </div>

                          <div className="contdown__block block">
                            <div className="block__number block__number--spacer">
                              :
                            </div>
                            <div className="block__text">
                              &nbsp;
                            </div>
                          </div>

                          <div className="contdown__block block">
                            <div className="block__number" id="seconds">
                              51
                            </div>
                            <div className="block__text">
                              seconds
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  }

                  {showRegister === false &&
                    <section className="video-section" >
                      <div className="video-row">

                        <div className="partner">
                          <div className="partner__block intro image">
                            <div className="intro__date">
                              6/28
                            </div>
                            <div className="intro__location">
                              <span>Jamaica</span>
                            </div>
                            <h2>
                              Jamaica Queens<br/>
                              <span className="gold_font">New York</span>
                            </h2>

                            <div className="image__wrap">
                              <img src={rsvpImage1} alt="Feature Shnseea" />
                              <div className="image__title">
                                <h3>
                                  Featuring<br />
                                  Shenseea
                                </h3>
                              </div>
                            </div>
                            <p>
                              Shenseea is a global artist with Afro-Jamaican and Korean roots. After going viral and touring across the world, she debuted her first studio album, <i>Alpha</i>, which peaked at #2 on the US reggae chart. <br/><br/> Opening performances by DJ Austin Millz and DJ duo Angel + Dren.
                            </p>
                          </div>

                          <div className= "partner__block content">
                            <div className="content__cta">
                              <a href="#" className="cta disabled" id="jamaicaCTA" disabled>
                                CLOSED
                              </a>
                            </div>
                          </div>             
                        </div>

                        <div className="partner">
                          <div className="partner__block intro image">
                            <div className="intro__date">
                              8/4
                            </div>
                            <div className="intro__location">
                              <span>San Juan</span>
                            </div>
                            <h2>
                              San Juan Capistrano<br/>
                              <span className="gold_font">California</span>
                            </h2>

                            <div className="image__wrap">
                              <img src={rsvpImage2} alt="Feature Shnseea" />
                              <div className="image__title">
                                <h3>
                                  Featuring<br />
                                  Myke Towers
                                </h3>
                              </div>
                            </div>
                            <p>
                              Myke Towers is a Puerto Rican rapper, singer, and songwriter. After making a name for himself in the underground rap scene, he soared to the top of the Latin chart. Now, in his latest album, <i>La Vida Es Una</i>, Towers mixes genres and gives fans even more reasons to dance.
                            </p>
                          </div>

                          <div className= "partner__block content">
                            <div className="content__cta">
                              <a href="#" className="cta disabled" id="sanjaunCTA">
                                CLOSED
                              </a>
                            </div>
                          </div>             
                        </div>

                        <div className="partner">
                          <div className="partner__block intro image">
                            <div className="intro__date">
                              9/14
                            </div>
                            <div className="intro__location">
                              <span>Jamaica</span>
                            </div>
                            <h2>
                              Jamaica Plain<br/>
                              <span className="gold_font">Boston</span>
                            </h2>
                            <div className="image__wrap">
                              <img src={rsvpImage3} alt="Feature Shnseea" />
                              <div className="image__title">
                                <h3>
                                  Featuring<br />Sean Paul
                                </h3>
                              </div>
                            </div>
                            <p>
                              With his commanding voice and lyrical prowess, Sean Paul is the Ambassador of Dancehall music. More than just a Grammy Award-winning recording artist, songwriter, and producer, Sean Paul is a cultural force. Having reached audiences in over 120 countries and achieving 19 Billboard chart-topping hits, he remains a major contributor to Jamaica’s culture and global music on a whole.
                            </p>
                          </div>

                          <div className= "partner__block content">
                            <div className="content__cta">
                              <a href="/en-us/a-taste-of-the-tropics/jamaicaplain" className="cta" id="jamaicaplCTA" disabled>
                                SIGN UP NOW
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  }

                  {showRegister === false &&
                    <section style={{backgroundImage: `url(${redBackground})`, overflow: "hidden"}} className="video-section" >
                        <div className="gift-row">
                            <div className="left">
                               <div className="vimeo-video">
                                 <img id="slowBtsPlaceholder"  src={btsPlaceholder} className="video-placeholder" alt="Three cans of Vita Coco and an open treasure chest" />
                                </div>
                            </div>
                            <div className="right">
                              <div className="right__wrap">
                                <div className="right__image">
                                  <img src={spikedLogo} className="" alt="Vita Coco Spiked with Captain Morgan" />
                                </div>
                                <h2 className="gold_font">The Tropi-kit</h2>
                                <div className="right__copy">
                                  <p>If you can’t make it to one of our experiences, bring the taste of the tropics to you. Enter our sweepstakes to win an at-home tropical kit to make any location feel like a vacation.</p>
                                </div>
                                <a href="/en-us/tropi-kit-rules" target="_blank" className="cta text--white border--white bg--transparent">
                                  Rules
                                </a>
                              </div>
                            </div>
                            
                        </div>
                        
                    </section>
                  }
                </main>
            </div>
        );

    }

}
