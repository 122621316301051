import "./form-simple.scss";
import React, { useEffect, useState } from "react";
import fetch from "isomorphic-fetch";
import {load} from "recaptcha-v3";
import moment from "moment";

import $ from 'jquery'


/****
 Under Video - Add in 'form' to collect first name, last name, email and DOB. Please use existing boxes we have already created for Follow the Captain and match how the page looks (dont go crazy).
I will get you a list ID
****/
const FormSimple = (props) => {
  const listid = props && props.listid ? props.listid : ''
  const entrysource = props && props.entrySource ? props.entrySource : ''
  const bg = props && props.background ? props.background : ''
  const [date, setDate] = useState("");
  const [thanks, setThanks] = useState(false)

  const sectionStyle = {
    backgroundImage: bg !== '' ? `url(${bg})` : ''
  }

  // eslint-disable-next-line complexity
  async function registerUser(event) {
    let formError = false
    let errorMessage = ''
    let ageError = false
   
    $('#formSimpleSubmit').prop('disabled', true).addClass('disabled')
    $('#formSimpleError').html('')
    // eslint-disable-next-line func-names
    $(".error").each(function () {
        // eslint-disable-next-line babel/no-invalid-this
        $(this).removeClass("error");
    });

    event.preventDefault()
    const fname = $("#FSCP_FirstName").val();
    const lname = $("#FSCP_LastName").val();
    const email = $("#FSCP_EmailId").val();
    const dob =  moment($("#FSCP_DOB").val()).format("YYYY-MM-DD")
    const zip = $("#FSPR_4_324_1_freetext").val();
    const newsLetter = $('#newsletter_optin_terms').is(':checked');
    let captcha = "";
    
    //check required fields are present
    if (fname.trim() === "" 
        || lname.trim() === "" 
        || email.trim() === "" 
        || zip.trim() === "" 
        || date.trim() === "") {

      if (fname.trim() === "") {
        $("#FSCP_FirstName").addClass("error");
      }

      if (lname.trim() === "") {
        $("#FSCP_LastName").addClass("error");
      }

      if (email.trim() === "") {
        $("#FSCP_EmailId").addClass("error");
      }

      if (zip.trim() === "") {
        $("#FSPR_4_324_1_freetext").addClass("error");
      }

      if (date.trim() === "") {
        $("#FSCP_DOB").addClass("error");
      }
      formError = true
    }

    if(newsLetter === false) {
      formError = true
    }
    //check age
    ageError = checkAge(dob)
    //Converts formdata to json
    const send = {};
    const data = new FormData(document.getElementById('formSimple'));
    window.grecaptcha.execute('6LfF0ZUlAAAAAE8MfPz-FBWAIC5fIS1g-r-Mjv0T', {action: 'submit'}).then(async function(token) {
   
   
      data.forEach((value, key) => {
        if (key === "FSHD_PR_1_64_1_Brand") {
          // eslint-disable-next-line radix
          send['HD_PR_1_64_1_Brand'] = parseInt(value);
        }else if(key === "FSPR_4_92_1"){
          send['PR_4_92_1'] = parseInt(value);
        } else if (key === "FSPR_4_92_1") {
          if (value === "on") {
            send[key] = "1";
          }else{
            send[key] = '99'
          }
        } else if (key === "g-recaptcha-response") {
          send['captcha'] = token
          send[key] = token
        } else if (key === "FSCP_DOB") {
          send['CP_DOB'] = moment($("#FSCP_DOB").val()).format("YYYY-MM-DD")
        } else if (key === "tc") {
          send[key] = value === 1 ? true : false
        } else if (key === "plusone") {
          send[key] = value === "true" || value === true ? true : false
        } else if (key === "newsletter_optin_terms") {
          send[key] = true
        } else {
          if(key.startsWith('FS', 0)) {
            send[key.slice(2)] = value;
          }else {
            send[key] = value;
          }
        }
        const ppVer = window && window.dg_footer_configurations && window.dg_footer_configurations.hg_PP_Version ? window.dg_footer_configurations.hg_PP_Version : null;

        if (ppVer) {

            send['ppVersion'] = ppVer;

        } else {

            console.log("Cannot get Privacy Policy version");
          send['ppVersion'] = '14.0';
        }
        send['PR_1_64_1'] = true
        send['PR_1_64_2'] = true
        send['PR_4_466_1_freetext'] = ''
        send['CP_WebsiteUrl'] = window.location.origin + window.location.pathname
        // send['newsletter_optin_terms'] = true
        send['HD_PromoCode'] = 'DNA107CPT0322WA0013'
      });

      // send to api
      send["captcha"] = token
      const json = JSON.stringify(send);
      captcha = send["g-recaptcha-response"];
      if(!formError && !ageError && captcha !== "") {
        try {
          const res = await fetch(`/api/newsletter`, {
              body: json,
              headers: {
                  "Accept": "application/json",
                  "Content-Type": "application/json",

              },
              method: "POST",
          // eslint-disable-next-line no-shadow
          }).then((res) => res.json());
          if(res === 200) {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: 'sign_up',
              step: 'success',
              sign_up_type: 'newsletter',
              form_name: 'Follow the Captain Post Super Bowl',
              detail: 'newsletter opt-in',
              //"inline", "modal", "fly-out modal" or "footer"
              form_location: 'inline',
              optin_brands: 'Captain Morgan',
            })
            setThanks(true)
          }else{
            // ERROR GOES HERE
            thanks(false)
            $('#formSimpleError').html('<div>Something went wrong. Please try again.</div>')
            $('#formSimpleSubmit').prop('disabled', false).removeClass('disabled')
          }
        } catch (error) {
          console.log("ERROR ERROR ANS", error);
          if($('#formSimpleSubmit')) {
            $('#formSimpleSubmit').prop('disabled', false).removeClass('disabled')
          }
          return 0;
        }
      }else{
        let errorMsg = "";
        if (fname.trim() === ""
          || lname.trim() === ""
          || email.trim() === ""
          || zip.trim() === ""
          || date.trim() === ""
          || newsLetter === false) {
          errorMsg = "<div>A required field is missing.</div>";
        }

        if(ageError) {
          errorMsg = "<div>You must be 21 and over to enter.</div>";
        }

        if (captcha === "") {
          errorMsg += "<div>Please answer the Captcha.</div>";
        }
        $('#formSimpleSubmit').prop('disabled', false).removeClass('disabled')
        $('#formSimpleError').html(errorMsg)
        errorMessage = errorMsg
        formError = false
      }
    })
  }

  function checkAge (dob) {
    /* [SEARCH-MINIMUM-AGE] */
    const birthday = moment(dob);
    if (moment().diff(birthday, "years", false) < 21) {
      return true
    } else {
      return false
    }
  }

  const handleDateChange = (e) => {
    let value = e.target.value;
    let backsapce = date.length > value.length;

    if (!backsapce && (date.length == 1 || date.length == 4)) {
      value += "/";
    }
    setDate(value);
  };

  useEffect( () => {
    $(function () {
      // this is for the input on clicks to make the labels smaller
      $(".formSimple__wrap input.input, select.input").on("focusin", function () {       
        $(this).siblings(".inputlabel").removeClass("inputlabel--empty");
      });
      // this keeps the labels small if the input is changed and not empty
      $(".formSimple__wrap input.input, select.input").on("focusout change", function () {
        if ($(this).val() === "") {
          $(this).siblings(".inputlabel").addClass("inputlabel--empty");
        }
      });
    })
  })

  return (
    <section className="formSimple rsvp video" style={sectionStyle} id="signup">
      <div className="container container--1200">
        {thanks === false &&
          <div className="formSimple__wrap">
            {/*<h2 className="formSimple__title gold__font small text--center">
              Continue the adventure
            </h2>
            <div className="formSimple__copy rsvp__copy rsvp__copy text--white text--center">
              Sign up to be notified of the captains new adventure!
            </div>*/}
            <h1 className="formSimple__title gold__font small text--center">
              Sign up to be notified of the captains new adventure!
            </h1>
            <form id="formSimple" runat="server" action="/api/CIDBapi_Form">
              <div className="form__wrap">
                <div className="inputWrapper labeled half">
                  <label htmlFor="FSCP_FirstName" className="inputlabel inputlabel--empty">FIRST NAME*</label>
                  <input id="FSCP_FirstName" name="FSCP_FirstName" type="text" className="input" required />
                </div>

                <div className="inputWrapper labeled half">
                  <label htmlFor="FSCP_LastName" className="inputlabel inputlabel--empty">LAST NAME*</label>
                  <input id="FSCP_LastName" name="FSCP_LastName" type="text" className="input" required />
                </div>

                <div className="inputWrapper labeled half">
                  <label htmlFor="FSCP_EmailId" className="inputlabel inputlabel--empty">EMAIL*</label>
                  <input id="FSCP_EmailId" name="FSCP_EmailId" type="email" className="input" required />
                </div>

                <div className="inputWrapper labeled half">
                  <label htmlFor="FSCP_DOB" className="inputlabel inputlabel--empty">DATE OF BIRTH*</label>
                  <input id="FSCP_DOB" name="FSCP_DOB" type="text" maxLength={10} className="input" value={date} onChange={handleDateChange} placeholder="mm/dd/yyyy" required />
                      <div id="FSdob__error" className="dob__error">
                        You must be over 21 to enter. 
                      </div>
                </div>

                <div className="inputWrapper labeled half">
                  <label htmlFor="FSPR_4_324_1_freetext" className="inputlabel inputlabel--empty">Zip Code*</label>
                  <input id="FSPR_4_324_1_freetext" name="FSPR_4_324_1_freetext" placeholder="Zip Code*" type="text" maxLength={10} className="input" required />
                </div>
                <div className="inputWrapper inputWrapper--checkbox full">
                  <input
                    type="checkbox"
                    id="newsletter_optin_terms"
                    name="newsletter_optin_terms"
                    value="1"
                    required
                  />

                  <label
                    htmlFor="newsletter_optin_terms"
                    className="inputlabel"
                  >
                  Tick here if you would like us to use your email to keep you informed about products, services and events from Captain Morgan & other Diageo brands including TheBar.com. You can unsubscribe at any time.<br />
                  By signing up, you accept Diageo&apos;s{" "}
                      <a
                          href="https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_uen.html?locale=uen-us"
                          target="_blank"
                          style={{textDecoration: "underline"}}
                      >Conditions of Use</a>{" "}and acknowledge the{" "}<a
                          href="https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_uen.html?locale=uen-us"
                          target="_blank"
                          style={{textDecoration: "underline"}}
                      >Privacy & Cookie Notice</a>. *
                  </label>
                </div>
                <div className="inputWrapper">
                    <div className="errorMessage" id="formSimpleError"></div>
                </div>

                <div className="inputWrapper">
                  <input id="source" name="source" runat="server" type="hidden" value={entrysource} />
                  <input id="FSPR_4_466_1_freetext" name="FSPR_4_466_1_freetext" value="" type="hidden" />

                  <input className="submit btn btn--bggold btn--textwhite" type="submit" value="Adventure Awaits" id='formSimpleSubmit' onClick={(evnt) => registerUser(evnt)} />
                </div>

                {/*<div className="inputWrapper full disclaimer">
                  By Submitting this form, you agree and accept the <a href="https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/PrivacyPolicy_uen.html?locale=uen-us" target="_blank">Privacy Policy</a>&nbsp;and&nbsp;<a href="https://footer.diageohorizon.com/dfs/assets/www.captainmorgan.com/TnC_uen.html?locale=uen-us" target="_blank">Terms of Use</a>.
                </div>*/}
              </div>
            </form>
          </div>
        }
        {thanks === true &&
          <div className="formSimple__wrap">
            <h2 className="formSimple__title gold__font small text--center">
              You've been registered!
            </h2>
            <div className="formSimple__copy rsvp__copy rsvp__copy text--white text--center">
              Thank you for signing up!
            </div>
          </div>
        }
      </div>
    </section>
  );
};

export default FormSimple;
