/* eslint-disable no-magic-numbers */
/* eslint-disable max-statements */
/* eslint-disable no-console */
import "./page-default.scss";
import React, {Component} from "react";
import {Helmet} from 'react-helmet'

import * as utils from 'global/utils/utils'

import AppError from "../../../organisms/app-error";
import NflProgressiveModal, { RenderButton } from "../../../organisms/NflProgressiveModal";
import Heading from "../heading";
import HeadingWithCopy from "../heading-with-copy/";
import Footer from "../footer";
import CloudLayer from "../cloud-layer/";
import buildPage from "../../page-builder";
import {load} from "recaptcha-v3";

import NavCustom from "../nav-custom";

const cn = require('classnames')

import heroImage from 'global/images/ftc2024/bg-red.jpg'
import fotyBanner from 'global/images/ftc2024/NFL_FOY_spon_horz_alt3_RGB_r.png'
import fotyBannerMobile from 'global/images/ftc2024/foty_banner--mobile.png'
import btnFoty from 'global/images/ftc2024/btn__foty.png'
import btnVoteNow from 'global/images/ftc2024/btn__votenow.png'

import foty1 from 'global/images/ftc2024/foty/foty_1.jpg'
import foty2 from 'global/images/ftc2024/foty/foty_2.jpg'
import foty3 from 'global/images/ftc2024/foty/foty_3.jpg'
import foty4 from 'global/images/ftc2024/foty/foty_4.jpg'
import foty5 from 'global/images/ftc2024/foty/foty_5.jpg'
import foty6 from 'global/images/ftc2024/foty/foty_6.jpg'
import foty7 from 'global/images/ftc2024/foty/foty_7.jpg'
import foty8 from 'global/images/ftc2024/foty/foty_8.jpg'
import foty9 from 'global/images/ftc2024/foty/foty_9.jpg'
import foty10 from 'global/images/ftc2024/foty/foty_10.jpg'
import foty11 from 'global/images/ftc2024/foty/foty_11.jpg'
import foty12 from 'global/images/ftc2024/foty/foty_12.jpg'
import foty13 from 'global/images/ftc2024/foty/foty_13.jpg'
import foty14 from 'global/images/ftc2024/foty/foty_14.jpg'

import logoCardinals from 'global/images/ftc2024/foty/logo_nfl_small_cardinals.png'
import logoFalcons from 'global/images/ftc2024/foty/logo_nfl_small_falcons.png'
import logoRavens from 'global/images/ftc2024/foty/logo_nfl_small_ravens.png'
import logoBills from 'global/images/ftc2024/foty/logo_nfl_small_bills.png'
import logoPanthers from 'global/images/ftc2024/foty/logo_nfl_small_panthers.png'
import logoBears from 'global/images/ftc2024/foty/logo_nfl_small_bears.png'
import logoBengals from 'global/images/ftc2024/foty/logo_nfl_small_bengals.png'
import logoBrowns from 'global/images/ftc2024/foty/logo_nfl_small_browns.png'

import logoCowboys from 'global/images/ftc2024/foty/logo_nfl_small_cowboys.png'
import logoBroncos from 'global/images/ftc2024/foty/logo_nfl_small_broncos.png'
import logoLions from 'global/images/ftc2024/foty/logo_nfl_small_lions.png'
import logoPackers from 'global/images/ftc2024/foty/logo_nfl_small_packers.png'
import logoTexans from 'global/images/ftc2024/foty/logo_nfl_small_texans.png'
import logoColts from 'global/images/ftc2024/foty/logo_nfl_small_colts.png'
import logoJaguars from 'global/images/ftc2024/foty/logo_nfl_small_jaguars.png'
import logoChiefs from 'global/images/ftc2024/foty/logo_nfl_small_chiefs.png'

import logoRaiders from 'global/images/ftc2024/foty/logo_nfl_small_raiders.png'
import logoChargers from 'global/images/ftc2024/foty/logo_nfl_small_chargers.png'
import logoRams from 'global/images/ftc2024/foty/logo_nfl_small_rams.png'
import logoDolphins from 'global/images/ftc2024/foty/logo_nfl_small_dolphins.png'
import logoVikings from 'global/images/ftc2024/foty/logo_nfl_small_vikings.png'
import logoPatriots from 'global/images/ftc2024/foty/logo_nfl_small_patriots.png'
import logoSaints from 'global/images/ftc2024/foty/logo_nfl_small_saints.png'
import logoGiants from 'global/images/ftc2024/foty/logo_nfl_small_giants.png'

import logoJets from 'global/images/ftc2024/foty/logo_nfl_small_jets.png'
import logoEagles from 'global/images/ftc2024/foty/logo_nfl_small_eagles.png'
import logoSteelers from 'global/images/ftc2024/foty/logo_nfl_small_steelers.png'
import logo49Ers from 'global/images/ftc2024/foty/logo_nfl_small_49ers.png'
import logoSeahawks from 'global/images/ftc2024/foty/logo_nfl_small_seahawks.png'
import logoBuccs from 'global/images/ftc2024/foty/logo_nfl_small_buccaneers.png'
import logoTitans from 'global/images/ftc2024/foty/logo_nfl_small_titans.png'
import logoComms from 'global/images/ftc2024/foty/logo_nfl_small_commanders.png'


export default class PageContainer extends Component {

  state = {
    "recaptcha":"",
    "locationData": [],
    "locationsFiltered": [],
    "firstClicked": false,
    "headingProps": {
      "image": fotyBanner,
      "imageMobile": fotyBannerMobile,
      "imagealt": "NFL Fan of the Year, presented by Captain Morgan",
      "title": "",      
      "srText": "NFL Fan of the Year, presented by Captain Morgan",
      "copy": "",
      "imageSize":"foty",
      "pModalOpen": false,
    },
  };

  constructor (props) {
    super(props);
    this.fetchData();
  }

  getPageName () {
    const path = this.props.location.pathname
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'>/g, "&#x27;");

    if (path.endsWith("/")) {
      return path;
    }

    return `${path}/`;

  }

  fetchData () {
    const pageName = this.getPageName();

    if (!this.props.data[pageName]) {

        // No! Lots wrong here:
        // - whatabout fetching data?
        // - Transition should be blocked before
        //   We have the data to avoid rendering noop
        this.props.fetchData(
            {name: pageName},
            false
        );

    }
  }

  pModalClickHandler () {
    this.setState({
      pModalOpen: this.state.pModalOpen === true ? false : true
    })
  }
  pModalCloseHandler (that) {
    this.setState({
      pModalOpen: false
    })
  }

  initGallery() {
    const gallerySlider = document.getElementsByClassName('gallery-slider')

    if (gallerySlider.length > 0) {
      // var thumbs = new Swiper ('.gallery-thumbs', {
      //   slidesPerView: 'auto',
      //   freeMode: true,
      //   watchSlidesVisibility: true,
      //   watchSlidesProgress: true,
      //   loop: true,
      //   spaceBetween: 10
      // });
      var slider = new Swiper ('.gallery-slider', {
        slidesPerView: 1,
        // centeredSlides: true,
        loop: true,
        loopedSlides: 6,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        // thumbs: {
        //   swiper: thumbs
        // }
      });
    }
  }

  componentDidUpdate (prevProps) {
    const pageName = this.getPageName();
    const newLocation = this.props.location.pathname;
    const {data} = this.props;

    if (prevProps.location.pathname !== newLocation && !data[pageName]) {
      this.props.fetchData(
          {name: newLocation},
          false
      );
    }
  }

  waitForElem () {
    const gallerySlider = document.getElementsByClassName('gallery-slider')
    if(gallerySlider !== "undefined"){
      setTimeout( () => {
        this.initGallery()
      }, 500)
    }else{
      setTimeout( () => { this.waitForElem(), 1500})
    }
  }
  componentDidMount () {
    $(function () {
      $('body').addClass('ftcBody')
      // need to remove newsletter modal, it's conflicting with the form
      if($('#newsletterModal')) {
        $("#newsletterModal").remove()
      }

      $('body').on('click', '.btn--ga', function (e) {
        var btnText = $(this).data('text') || ''
        var btnHref = $(this).data('url') || ''
        var btnPath = $(this).data('path') || ''
        var btnName = $(this).data('name') || ''
        var btnSection = $(this).data('section') || ''
        var data = {
          event: 'in_page_button',
          click_text: btnText,
          destination_page_path: btnPath,
          link_url: btnHref,
          section: btnSection,
          location_name: btnName,
        }
        utils.gaSubmit(data)
      })

      // Set the date we're counting down to
      // const countDownDate = new Date('2024-09-30T09:00:00.000-04:00').getTime()
      // // Update the count down every 1 second
      // let x = setInterval(function () {
      //   // Get today's date and time
      //   const now = new Date().getTime()

      //   // Find the distance between now and the count down date
      //   const distance = countDownDate - now

      //   // Time calculations for days, hours, minutes and seconds
      //   let days = Math.floor(distance / (1000 * 60 * 60 * 24))
      //   let hours = Math.floor(
      //       (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      //   )
      //   let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      //   let seconds = Math.floor((distance % (1000 * 60)) / 1000)

      //   // Display the result in the element with id="demo"
      //   //   document.getElementById("demo").innerHTML = days + "d " + hours + "h "
      //   //   + minutes + "m " + seconds + "s ";
      //   const daysDiv = document.getElementById('days')
      //   const hoursDiv = document.getElementById('hours')
      //   const minutesDiv = document.getElementById('minutes')
      //   const secDiv = document.getElementById('seconds')

      //   if(daysDiv && hoursDiv && minutesDiv && secDiv) {
      //     daysDiv.innerHTML = days
      //     hoursDiv.innerHTML = hours
      //     minutesDiv.innerHTML = minutes
      //     secDiv.innerHTML = seconds
      //     // If the count down is finished, write some text
      //     if (distance < 0) {
      //       clearInterval(x)
      //       document.getElementById('days').innerHTML =
      //           '<span>' + '0' + '</span> <br/> days '
      //       document.getElementById('hours').innerHTML =
      //           '<span>' + '0' + '</span> <br/> hours '
      //       document.getElementById('minutes').innerHTML =
      //           '<span>' + '0' + '</span> <br/> minutes '
      //       document.getElementById('seconds').innerHTML =
      //             '<span>' + '0' + '</span> <br/> seconds '
      //     }
      //   }
      // }, 1000)
    })
    setTimeout( () => { this.waitForElem(), 1500})
  }
  
  render () {
    const {data} = this.props;
    const pageName = this.getPageName();

    if (!data[pageName] || data[pageName].isFetching) {
      // In future, avoid this!
      return null;
    }

    const page = buildPage(data, pageName);
    const pageTitle = "NFL Fans of the Year | Follow The Captain 2024 | Captain Morgan"

    // IF the API is unreachable
    // OR the page response is not as expected
    // BuildPage will return null
    // Without this the App will error server side and bring down the node server
    if (page === null) {
      return (
        <div className="page">
          <AppError
              message="This site is currently unavailable - Come back soon."
          />
        </div>
      );
    }

    const navData = {
      logo: {
        url: "https://media.captainmorgan.com/media/1543/red-logo.png?mode=crop",
        alt: "Captain Morgan Logo"
      },
      navigation: [
        {
          title: 'Home',
          inactive: true,
          url: '/followthecaptain',
        },
        {
          title: 'About',
          inactive: true,
          url: '/followthecaptain/about',
        },
        {
          title: 'NFL Fans of the Year',
          url: '/followthecaptain/nfl-fan-of-the-year',
        },
        {
          title: 'Something Super',
          inactive: true,
          url: '/followthecaptain/something-super',
        },
        {
          title: 'Buy Captain',
          inactive: true,
          url: 'https://productshop.captainmorgan.com/social/65f0bdd0b932a830b410bc0b?&utm_medium=referral&utm_campaign=FTC_FY25_websiteMikMak&utm_placement=body&utm_marketing_tactic=conversion',
          path: '/social/65f0bdd0b932a830b410bc0b?&utm_medium=referral&utm_campaign=FTC_FY25_websiteMikMak&utm_placement=body&utm_marketing_tactic=conversion',
          newTab: true,
          track: true,
        },
      ]
    }

    const galleryData = [
      {
        image: foty1,
        imagealt: "NFL Fan of the Year Image 1"
      },
      {
        image: foty2,
        imagealt: "NFL Fan of the Year Image 2"
      },
      {
        image: foty3,
        imagealt: "NFL Fan of the Year Image 3"
      },
      {
        image: foty4,
        imagealt: "NFL Fan of the Year Image 4"
      },
      {
        image: foty5,
        imagealt: "NFL Fan of the Year Image 5"
      },
      {
        image: foty6,
        imagealt: "NFL Fan of the Year Image 6"
      },
      {
        image: foty7,
        imagealt: "NFL Fan of the Year Image 7"
      },
      {
        image: foty8,
        imagealt: "NFL Fan of the Year Image 8"
      },
      {
        image: foty9,
        imagealt: "NFL Fan of the Year Image 9"
      },
      {
        image: foty10,
        imagealt: "NFL Fan of the Year Image 10"
      },
      {
        image: foty11,
        imagealt: "NFL Fan of the Year Image 11"
      },
      {
        image: foty12,
        imagealt: "NFL Fan of the Year Image 12"
      },
      {
        image: foty13,
        imagealt: "NFL Fan of the Year Image 13"
      },
      {
        image: foty14,
        imagealt: "NFL Fan of the Year Image 14"
      },
    ]

    const fotyData = [
      {
        team: 'Arizona Cardinals',
        image: logoCardinals,
        imageAlt: 'Arizona Cardinals',
        copy: 'Jay Hastings',
      },
      {
        team: 'Atlanta Falcons',
        image: logoFalcons,
        imageAlt: 'Atlanta Falcons',
        copy: 'Tripp Warwick',
      },
      {
        team: 'Baltimore Ravens',
        image: logoRavens,
        imageAlt: 'Baltimore Ravens',
        copy: 'Cindy Layton',
      },
      {
        team: 'Buffalo Bills',
        image: logoBills,
        imageAlt: 'Buffalo Bills',
        copy: 'Richard "Poo" Peterson<br />Derrick "Norm" Norman',
      },
      {
        team: 'Carolina Panthers',
        image: logoPanthers,
        imageAlt: 'Carolina Panthers',
        copy: 'David McClelland',
      },
      {
        team: 'Chicago Bears',
        image: logoBears,
        imageAlt: 'Chicago Bears',
        copy: 'Mackenzie Currans',
      },
      {
        team: 'Cincinnati Bengals',
        image: logoBengals,
        imageAlt: 'Cincinnati Bengals',
        copy: 'Jeremy "Bengals Captain" Conley<br />Jessica "Who Bae" Conley',
      },
      {
        team: 'Cleveland Browns',
        image: logoBrowns,
        imageAlt: 'Cleveland Browns',
        copy: 'Lawrence Schmitz',
      },
      {
        team: 'Dallas Cowboys',
        image: logoCowboys,
        imageAlt: 'Dallas Cowboys',
        copy: 'Amanda Foster',
      },
      {
        team: 'Denver Broncos',
        image: logoBroncos,
        imageAlt: 'Denver Broncos',
        copy: 'Christina Leo',
      },
      {
        team: 'Detroit Lions',
        image: logoLions,
        imageAlt: 'Detroit Lions',
        copy: 'Megan Stefanski',
      },
      {
        team: 'Green Bay Packers',
        image: logoPackers,
        imageAlt: 'Green Bay Packers',
        copy: 'David Lucero',
      },
      {
        team: 'Houston Texans',
        image: logoTexans,
        imageAlt: 'Houston Texans',
        copy: 'Pati Cream',
      },
      {
        team: 'Indianapolis Colts',
        image: logoColts,
        imageAlt: 'Indianapolis Colts',
        copy: 'Seth Irskens',
      },
      {
        team: 'Jacksonville Jaguars',
        image: logoJaguars,
        imageAlt: 'Jacksonville Jaguars',
        copy: 'Melissa Fullmore',
      },
      {
        team: 'Kansas City Chiefs',
        image: logoChiefs,
        imageAlt: 'Kansas City Chiefs',
        copy: 'Tom Masterson',
      },
      {
        team: 'Las Vegas Raiders',
        image: logoRaiders,
        imageAlt: 'Las Vegas Raiders',
        copy: 'Jena Magallanes',
      },
      {
        team: 'Los Angeles Chargers',
        image: logoChargers,
        imageAlt: 'Los Angeles Chargers',
        copy: 'James Ebo',
      },
      {
        team: 'Los Angeles Rams',
        image: logoRams,
        imageAlt: 'Los Angeles Rams',
        copy: 'Gary Young',
      },
      {
        team: 'Miami Dolphins',
        image: logoDolphins,
        imageAlt: 'Miami Dolphins',
        copy: 'Lisa Siegel',
      },
      {
        team: 'Minnesota Vikings',
        image: logoVikings,
        imageAlt: 'Minnesota Vikings',
        copy: 'Betsy Fine',
      },
      {
        team: 'New England Patriots',
        image: logoPatriots,
        imageAlt: 'New England Patriots',
        copy: 'Dan Sweeney',
      },
      {
        team: 'New Orleans Saints',
        image: logoSaints,
        imageAlt: 'New Orleans Saints',
        copy: 'Jonathan Roberts',
      },
      {
        team: 'New York Giants',
        image: logoGiants,
        imageAlt: 'New York Giants',
        copy: 'Kyle Searfoss',
      },
      {
        team: 'New York Jets',
        image: logoJets,
        imageAlt: 'New York Jets',
        copy: 'Joseph Abramski',
      },
      {
        team: 'Philadelphia Eagles',
        image: logoEagles,
        imageAlt: 'Philadelphia Eagles',
        copy: 'Frank Crinite',
      },
      {
        team: 'Pittsburgh Steelers',
        image: logoSteelers,
        imageAlt: 'Pittsburgh Steelers',
        copy: 'Taylor Sinnott',
      },
      {
        team: 'San Francisco 49ers',
        image: logo49Ers,
        imageAlt: 'San Francisco 49ers',
        copy: 'Sofia Villalpando',
      },
      {
        team: 'Seattle Seahawks',
        image: logoSeahawks,
        imageAlt: 'Seattle Seahawks',
        copy: 'Terri Fletcher',
      },
      {
        team: 'Tampa Bay Buccaneers',
        image: logoBuccs,
        imageAlt: 'Tampa Bay Buccaneers',
        copy: 'Matt Algeri',
      },
      {
        team: 'Tennesse Titans',
        image: logoTitans,
        imageAlt: 'Tennesse Titans',
        copy: 'Adam McMilian',
      },
      {
        team: 'Washington Commanders',
        image: logoComms,
        imageAlt: 'Washington Commanders',
        copy: 'Dale McCord',
      },
    ]

    return (

      <div className="page mappage pageftc">
        <NflProgressiveModal 
          modalstate={this.state.pModalOpen}
          callback={() => this.pModalCloseHandler()}
          that={this}
        />
        <Helmet htmlAttributes={{ class:"ftc-html"}}>
          <script>var dg_locale="uen-us"</script>
          <meta charset="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="description" content="The captain is missing but he left behind some treasure. Play along for a chance to win prizes and once-in-a-lifetime experiences." />

          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content="The captain is missing but he left behind some treasure. Play along for a chance to win prizes and once-in-a-lifetime experiences." />
          <meta property="og:image" content={`https://media.captainmorgan.com/media/1543/red-logo.png?mode=crop`} />
          <meta name="og:url" content={`https://www.captainmorgan.com/followthecaptain`} />
          <meta property="og:type" content="website" />

          <script src="https://cdnjs.cloudflare.com/ajax/libs/Swiper/6.8.4/swiper-bundle.min.js"></script>
          
          <title>{pageTitle}</title>
        </Helmet>
        <NavCustom navdata={navData} />
        <main className="page__content ftc24 ftc24--foty" id="main" role="main">
          {/* <CloudLayer background={heroImage} /> */}
          {/* DRIZLY SECTION */}
          <section className="flex heading__section foty__heading">
            <div className="container container--1600">
              <Heading {...this.state.headingProps} />
            </div>
            <div className="container container--1600 z-10 mobile-nopad" id="mapContainer">
              
            </div>
          </section>          
          <section className="flex content heading__section foty__heading">
            <div className="container container--1200">
              <div className="content__wrap flex">                
                <div className="slideswrap__heading">
                  <h2 className="slideswrap__title text--white">
                    2024-2025 NFL Fans of the Year
                  </h2>
                </div>
                <div class="heading__copy text--white text--center ">
                  Congratulations to our 32 NFL Fans of the Year! See our lucky winners get surprised below.
                </div>
                <div className="content__copy text--copy text--white">
                  {/*Check back in November to see the 32 team nominees and vote for the Ultimate NFL Fan of the Year!*/}
                  Vote now for the Ultimate Fan of the Year.<br />
                  <em>Closes February 5, 2025</em>
                  <br />
                  <br />
                  <div className="cta__link">
                    <a
                      aria-label="Vote Now"
                      className="clear btn--ga"
                      data-text="Vote Now"
                      data-name='Vote Now'
                      data-path="/honors/fan-of-the-year/"
                      data-section='FOTY CTA'
                      data-url="https://www.nfl.com/honors/fan-of-the-year/"
                      href="https://www.nfl.com/honors/fan-of-the-year/"
                      rel="noreferrer"
                      target="_blank"
                      title="Vote Now"
                    >
                      <img aria-label="Vote now" alt="Vote now" className="cta__image" src={btnVoteNow} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="flex">
            <div className="container container--1200">
              <div className="slideswrap">
                <div className="slideswrap__wrap">
                  <div className="gallery">
                    <div className="swiper-container gallery-slider">
                      <div className="swiper-wrapper">
                        {galleryData && galleryData.length > 0 && galleryData.map((item, key) => {
                          return (
                            <div key={`gallery_item_${key}`}  className="swiper-slide" data-index={key} data-status={key === 0 ? 'active' : "unknown"}>
                              <div className="content-wrap">
                                <img src={item.image} alt={item.imagealt} />
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <div className="swiper-button-prev"></div>
                    <div className="swiper-button-next"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="flex teams">
            <div className="container container--1440">
              <div className="teams__wrap flex text--white">
                {fotyData.map((team, i) => {
                  return (
                    <div className="teams__col" key={`foty_team_${i}`}>
                      <div className="teams__block">
                        <div className="teams__image">
                          <img src={team.image} alt={team.imageAlt} />
                        </div>
                        <strong dangerouslySetInnerHTML={{ __html: `${team.copy}` }} />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>                
          <section className="flex otherinfo">
            <div className="container container--1440" style={{paddingTop: '40px', paddingBottom: '40px'}}>
                <HeadingWithCopy
                // title="Join our crew to get notified of legendary celebrations throughout the season!"
                 contentCopy="Join our crew to get notified of legendary celebrations throughout the season!"
                // contentCopy="Have you followed the Captain?"
              />
              <div className="button__wrap">
                <RenderButton callback={() => this.pModalClickHandler()} />
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    );
  }
}
