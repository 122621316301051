import Helmet from "react-helmet";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import withConfig from "hoc/with-config";
import {withRouter} from "react-router";

const Meta = (props) => {

    const {
        MetaPageTitle,
        MetaDescription,
        MetaLanguage,
        MetaImage,
    } = fieldsArrayToKeyValueHash(props.fields);
    // Const MetaPageUrl = window.location.href;
    const MetaPageUrl = `${props.location.pathname}${props.location.search}`;
    const MetaDefinedImageWidth = "800";
    const MetaDefinedImageHeight = "800";

    let MetaImageConstructedUrl = "";

    if (MetaImage) {

        const MetaImageUrl = MetaImage.find((element) => element.title === "Url");

        if (MetaImageUrl) {

            MetaImageConstructedUrl = encodeURI(`${MetaImageUrl.value}&width=${MetaDefinedImageWidth}&height=${MetaDefinedImageHeight}`); // eslint-disable-line max-len

        }

    }

    return (
        <Helmet htmlAttributes={{"lang": MetaLanguage}}>
            <meta charSet="utf-8" />
            <meta http-equiv="X-UA-Compatible" content="ie=edge" />
            {
                MetaPageTitle &&
                    <title>{MetaPageTitle}</title>
            }
            {
                MetaDescription &&
                    <meta name="description" content={MetaDescription} />
            }
            <meta name="robots" content="all,index,follow" />
            <meta name="distribution" content="global" />
            <meta name="rating" content="general" />

            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="HandheldFriendly" content="True" />
            <meta name="MobileOptimized" content="380" />
            <meta http-equiv="cleartype" content="on" />

            <meta http-equiv="x-dns-prefetch-control" content="on" />
            <link rel="preconnect" href={`${props.config.appUrl}`} />
            <link rel="preconnect" href={`${props.config.apiUrl}`} />
            <link rel="preconnect" href="https://fonts.googleapis.com/" />
            <link rel="preconnect" href="https://www.googletagmanager.com/" />
            <link rel="preconnect" href="https://www.google-analytics.com/" />
            <link rel="preconnect" href="https://cdn.polyfill.io/" />
            <link rel="preconnect" href="https://cdn.rawgit.com/" />
            <link rel="preconnect" href="https://footer.diageohorizon.com/" />
            <link rel="preconnect" href="https://web.diageoagegate.com/" />
            <link rel="preconnect" href="https://cdnjs.cloudflare.com/" />
            <link rel="preconnect" href="https://s.ytimg.com/" />
            <link rel="preconnect" href="https://www.youtube.com/" />

            {
                MetaPageTitle &&
                    <meta property="og:title" content={MetaPageTitle} />
            }
            {
                MetaDescription &&
                    <meta property="og:description" content={MetaDescription} />
            }
            {
                MetaLanguage &&
                    <meta property="og:locale" content={MetaLanguage} />
            }
            {
                MetaPageUrl &&
                    <meta property="og:url" content={MetaPageUrl} />
            }
            {
                MetaImageConstructedUrl &&
                    <meta property="og:image" content={MetaImageConstructedUrl} />
            }
            {
                MetaImageConstructedUrl && MetaDefinedImageWidth &&
                    <meta property="og:image:width" content={MetaDefinedImageWidth} />
            }
            {
                MetaImageConstructedUrl && MetaDefinedImageHeight &&
                <meta property="og:image:height" content={MetaDefinedImageHeight} />
            }
            <meta name="twitter:card" content="summary" />
        </Helmet>
    );

};

export default withRouter(withConfig(Meta));
