/* eslint-disable no-empty-function */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable max-len, max-lines, no-console, no-return-assign, class-methods-use-this, max-statements */
/* globals mapboxgl, MapboxGeocoder */
import "./style.scss";
import React from "react";


class WhereToBuyNearMe extends React.Component {

    constructor (props) {

        super(props);
        this.findLink = "";

    }

    componentDidMount () {

        const lat = this.getParameterByName("lat");

        const lng = this.getParameterByName("lng");

        const product = this.getParameterByName("product");

        const baseSrc = "https://where-to-buy.co/widgets/core/BuyOnlineBuyLocalV2/index.html?pid=12037984&type=local";

        const iframe = document.getElementById("wtbIframe");

        if (lat && lng && !product) {

            iframe.src = `${baseSrc}&lat=${lat}&long=${lng}`;

        } else if (lat && lng && product) {

            iframe.src = `${baseSrc}&lat=${lat}&long=${lng}&product=${product}`;

        } else if (product) {

            iframe.src = `${baseSrc}&product=${product}`;

        } else {

            iframe.src = baseSrc;

        }

    }

    getParameterByName (name) {

        const match = RegExp(`[?&]${name}=([^&]*)`).exec(window.location.search);


        return match && decodeURIComponent(match[1].replace(/\+/g, " "));

    }


    render () {

        return (
			<>
            <main className="main">
			     <section className="section">
			         <div className="container">
			             <header class="where-to-buy__hero">
			                 <div class="where-to-buy__hero-content">
			                     <h1 class="where-to-buy__hero-title gold_font">Find Near Me</h1>
			                 </div>
			             </header>
			             <iframe id="wtbIframe" src="" title="Where To Buy" aria-label="Where To Buy" />
			         </div>
			     </section>
			 </main>
			</>
        );

    }

}

export default WhereToBuyNearMe;
