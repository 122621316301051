/* eslint-disable max-lines */
/* eslint-disable consistent-return */
/* eslint-disable id-length */
/* eslint-disable max-lines */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-empty-function */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable max-len, max-lines, no-console, no-return-assign, class-methods-use-this, max-statements */
/* globals mapboxgl, MapboxGeocoder */
import "./style.scss";
import $ from "jquery";
import React from "react";
import * as routeList from "global/utils/routing";

const cn = require("classnames");

import ahoyImage from 'global/images/de_de_comp_game/welcome-aboard.png'
import RulesBg from 'global/images/de_de_comp_game/enter_middle_bg_desktop.jpg'
import RulesLeftDesktop from 'global/images/de_de_comp_game/middle_section_left_desktop.png'
import RulesRight from 'global/images/de_de_comp_game/complete_right.png'
import RulesLeftMobile from 'global/images/de_de_comp_game/middle_section_left_mobile.png'
import IconsYt from 'global/images/de_de_comp_game/icons_social_yt.png'
import GifWrapBg from 'global/images/de_de_comp_game/complete_gifholder.png'

class GameComplete extends React.Component {

    constructor (props) {
      super(props);
    }

    componentDidMount () {
      //JS goes here
    }

    render () {
      const gameAssets = routeList.getSectionAssets(this.props.location.pathname)
      const heading = gameAssets && gameAssets.gamecomplete && gameAssets.gamecomplete.heading ? gameAssets.gamecomplete.heading : ''
      const subcopy = gameAssets && gameAssets.gamecomplete && gameAssets.gamecomplete.subcopy ? gameAssets.gamecomplete.subcopy : ''
      const completeLink = gameAssets && gameAssets.gamecomplete && gameAssets.gamecomplete.spotifyLink ? gameAssets.gamecomplete.spotifyLink : ""
      const ytUrl = gameAssets && gameAssets.gamecomplete && gameAssets.gamecomplete.yturl ? gameAssets.gamecomplete.yturl : ""
      const ytCopy = gameAssets && gameAssets.gamecomplete && gameAssets.gamecomplete.ytcopy ? gameAssets.gamecomplete.ytcopy : ""
      const gifUrl = gameAssets && gameAssets.gamecomplete && gameAssets.gamecomplete.gifurl && gameAssets.gamecomplete.gifurl !== '' ? gameAssets.gamecomplete.gifurl : GifWrapBg
      

      return (
      	<>
      		<section className="GameEnterCode gamecomplete" style={{backgroundImage: `url(${RulesBg}`}} id='gameComplete'>
          <div className='gamecomplete__images images images__left images--desktop'>
            <img src={RulesLeftDesktop} alt='Captain Morgan Bottle Image Desktop' />
          </div>
          <div className='gamecomplete__images images images__left images--mobile'>
            <img src={RulesLeftMobile} alt='Captain Morgan Bottle Image Mobile' />
          </div>
          <div className='gamecomplete__images images images__right images--desktop'>
            <img src={RulesRight} alt='Captain Morgan Cocktail Image Mobile' />
          </div>
          <div className="container">
            <div className="inner">
              <div className='gamecomplete__wrap'>
                <div className='gamecomplete__header'>
                  {heading && heading !== '' &&
                    <img className='gamecomplete__wrap-header-image' src={heading} alt="Well done, Captain!" />
                  }
                  <h2 className='gamecomplete__title gold_font' id='rulesTitle'>
                    {subcopy}
                  </h2>
                </div>
                <div className='gamecomplete__content'>
                  <h3 className="gamecomplete__subtitle">{subcopy}</h3>
                  <div className="gamecomplete__ytwrap ytinfo">
                    <div className="ytinfo__linkwrap">
                      <a href={ytUrl} className="ytinfo__link" target="_blank">
                        <img src={IconsYt} alt="YouTube Icon" />
                        <div className="ytinfo__text">
                          YouTube
                        </div>
                      </a>
                    </div>
                    <div className="ytinfo__copy" dangerouslySetInnerHTML={{
                      __html: `${ytCopy}`
                      }}
                    />
                  </div>
                  <div className="gamecomplete__gifwrap gifwrap">
                    <div className="gifwrap__imgwrap">
                      <img src={gifUrl} alt="Gif Holder" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      	</>
      );

    }

}

export default GameComplete;
