import "./cocktail-tiles.scss";
import React from "react";

import recipeImage1 from 'global/images/comp2023/recipes/sample.png'
import BoltBreeze from 'global/images/comp2023/recipes/Bolt_Breeze.jpg'
import CardinalRules from 'global/images/comp2023/recipes/Cardinal_Rules.jpg'
import CowboyCooler from 'global/images/comp2023/recipes/Cowboy_Cooler.jpg'
import GiantsJuice from 'global/images/comp2023/recipes/Giants_Juice.jpg'
import JetsandGinger from 'global/images/comp2023/recipes/Jets_and_Ginger.jpg'
import LALemonade from 'global/images/comp2023/recipes/LA_Lemonade.jpg'
import StrawberryRumRita from 'global/images/comp2023/recipes/Strawberry_RumRita.jpg'
import WakeUpCaptain from 'global/images/comp2023/recipes/Wake_Up_Captain.jpg'

const CocktailTiles = (props) => {
  const data = [
    {
      slug: '/en-us/cocktails/giants-juice/',
      img: GiantsJuice,
      imgalt:'Giants Juice',
      name:'Giants<br />Juice',
    },
    {
      slug: '/en-us/cocktails/captain-and-ginger/',
      img: JetsandGinger,
      imgalt:'Jets & Ginger',
      name:'Jets &<br />Ginger',
    },
    {
      slug: '/en-us/cocktails/cowboy-cooler/',
      img: CowboyCooler,
      imgalt:'Cowboy Cooler',
      name:'Cowboy<br />Cooler',
    },
    {
      slug: '/en-us/cocktails/cardinal-rules/',
      img: CardinalRules,
      imgalt:'Cardinal Rules',
      name:'Cardinal<br />Rules',
    },
    {
      slug: '/en-us/cocktails/captain-and-pineapple/',
      img: BoltBreeze,
      imgalt:'Bolt Breeze',
      name:'Bolt<br />Breeze',
    },
    {
      slug: '/en-us/cocktails/la-lemonade/',
      img: LALemonade,
      imgalt:'LA Lemonade',
      name:'LA<br />Lemonade',
    },
    {
      slug: '/en-us/cocktails/keepin-it-100/',
      img: WakeUpCaptain,
      imgalt:'Wake Up Captain',
      name:'Wake Up<br />Captain',
    },
    {
      slug: '/en-us/cocktails/strawberry-rum-rita/',
      img: StrawberryRumRita,
      imgalt:'Strawberry Rum Rita',
      name:'Strawberry<br />Rum Rita',
    }
  ]

  const createBlock = (recipe1, recipe2, key) => {
    return (
      <div className="cocktailTiles__block" key={`cocktailblock_${key}`}>
        {recipe1 &&
          <div className="cocktailTiles__item" key={`cocktailitem_${key}`}>
            <a href={recipe1.slug} className="cocktailTiles__wrap">
              <h3 dangerouslySetInnerHTML={{
                __html: `${recipe1.name} `
                }} />
              <img src={recipe1.img} alt={`${recipe1.imgalt ? recipe1.imgalt : recipe1.name}`} />
            </a>
          </div>
        }
        {recipe2 &&
          <div className="cocktailTiles__item" key={`cocktailitem_${key + 1}`}>
            <a href={recipe2.slug} className="cocktailTiles__wrap">
              <h3 dangerouslySetInnerHTML={{
                __html: `${recipe2.name} `
                }} />
              <img src={recipe2.img} alt={`${recipe2.imgalt ? recipe2.imgalt : recipe2.name}`} />
            </a>
          </div>
        }
      </div>
    )
  }
  
  return (
    <div className="cocktailTiles">
      {data && data.length > 0 && data.map( (recipe, i) => {
        return (
          (i % 2 === 0) ? createBlock(data[i], data[i+1] ? data[i+1] : null,i) : null
        )
      })}
    </div>
  );
};

export default CocktailTiles;
