import "./video.scss";
import Lazy from "global/utils/lazy";
import React from "react";
import VideoPlayer from "../../molecules/video-player";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

const Video = (props) => {

    if (props.posterImage) {

        // Temp hack due to bad data?
        return null;

    }

    const {
        VideoId,
        PosterImage,
    } = fieldsArrayToKeyValueHash(props.fields);

    if (!VideoId || !VideoPlayer || !PosterImage) {

        return null;

    }

    const {
        Alt,
        Url,
    } = fieldsArrayToKeyValueHash(PosterImage);

    return (
        <Lazy Tag="section" containerClassName="video">
            <div className="video__inner">
                <VideoPlayer
                    videoId={VideoId}
                    posterImage={Url}
                />
            </div>
        </Lazy>
    );

};

export default Video;
