import "./style.scss";
import Cta from "../../atoms/cta";
import React, { useEffect } from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import sanitise from "global/utils/sanitise";

import iconChatbot from 'global/images/chatbot/icon--chatbot.svg'
import chevronDown from 'global/images/chatbot/chevron_down.svg'


const cn = require('classnames')

const AccordionBlock = (props) => {
  const getImage = (image, size) => {
    const imgSize = size ? size : '1920'
    let useImage = ''

    if(image && image[0].value !== '') {
      if(image[0].value.includes('mode=crop')) {
        useImage = `${image[0].value.split("?")[0]}?mode=max&width=${imgSize}`
      }else{
        useImage = image[0].value
      }
    }

    return useImage
  }

  if (props && props.fields) {
    const {
      AccordionItems,
      AccordionTextColor,
      BackgroundImage,
      BlockBackgroundColor,
      BlockTitle,
      BlockTitleTextColor,
    } = fieldsArrayToKeyValueHash(props.fields);

    const useBG = getImage(BackgroundImage)

    const initAccordion = () => {
      const accordion = document.querySelector(".accordion");
      if (accordion) {
        accordion.addEventListener("click", (e) => {
          const activePanel = e.target.closest(".accordion__item");
          if (!activePanel) return;
          toggleAccordion(activePanel);
        });
      }
    }

    const toggleAccordion = (panelToActivate) => {
      const activeButton = panelToActivate.querySelector("button");
      const activePanel = panelToActivate.querySelector(".accordionitem__content");
      const activePanelIsOpened = activeButton.getAttribute("aria-expanded");
      if (activePanelIsOpened === "true") {
        panelToActivate
          .querySelector("button")
          .setAttribute("aria-expanded", false);

        panelToActivate
          .querySelector(".accordionitem__content")
          .setAttribute("aria-hidden", true);
      } else {
        panelToActivate.querySelector("button").setAttribute("aria-expanded", true);

        panelToActivate
          .querySelector(".accordionitem__content")
          .setAttribute("aria-hidden", false);
      }
    }

    useEffect(() => {
      initAccordion()
    }, [])
    return (
      <section className={`accordionblock ${BlockBackgroundColor && BlockBackgroundColor !== '' ? `accordionblock--${BlockBackgroundColor}` : ''}`}>
        <div className="container container--1600">
          <div className="accordionblock__wrap">
            <div className={`content`}>
              {BlockTitle ? (
                  <>
                    <h2 className={`content__title ${BlockTitleTextColor && BlockBackgroundColor !== '' ? `text--${BlockTitleTextColor}` : 'text--black'}`}>
                      {BlockTitle}
                    </h2>
                  </>
                ) : null
              }
              {(AccordionItems && AccordionItems.length > 0) ? (
                  <div className={`accordion`}>
                    {AccordionItems.map( (item, x) => {
                      return (
                        <div className={`accordion__item accordionitem ${AccordionTextColor && AccordionTextColor !== '' ? `text--${AccordionTextColor}` : 'text--black'}`} key={`accordionitem--${x}`}>
                          <h3 className="accordionitem__title" id={`accordionitem--${x}`}>
                            <button className={`accordionitem__trigger ${AccordionTextColor && AccordionTextColor !== '' ? `text--${AccordionTextColor}` : 'text--black'}`} aria-expanded="false" aria-controls={`accordionitem__content--${x}`}>
                              <span>{`${item?.fields[0]?.value}`}</span>
                              <img src={chevronDown} className="content__image" aria-hidden="true" loading="lazy" />
                            </button>
                          </h3>
                          <div className="accordionitem__content" role="region" aria-labelledby={`accordionitem--${x}`} aria-hidden="true" id={`accordionitem__content--${x}`}>
                            <div dangerouslySetInnerHTML={{__html: item?.fields[1]?.value}} />
                          </div>
                        </div>
                    )})}
                  </div>
                ) : null}
            </div>
          </div>
        </div>
      </section>
    );

  }

  return false;
};

export default AccordionBlock;
