import "./cta.scss";
import {BlockingLink} from "global/utils/blocking-link";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

const getClassNameFromVersionAndVariant = (version, varient) => {

    let className = "";

    if (version) {

        className = `cta-${version}`;

        if (varient === "ghost") {

            className += "--ghost";

        }

    }

    return className;

};

const renderCtaLink = ({Caption, NewWindow, Link, variantClassName}) => <BlockingLink
    target={NewWindow
        ? "_blank"
        : "_self"}
    to={Link}
    className={`cta ${variantClassName}`}
>
    <span className="cta__content">{Caption}</span>
</BlockingLink>;

const renderShopalystCta = ({Caption, ShopalystProductCode, variantClassName}) => {

    const openShopalyst = () => {

        // eslint-disable-next-line no-underscore-dangle
        window._shopalyst.openSlPdp(ShopalystProductCode, "ean");

    };

    return <button className={`cta ${variantClassName}`} onClick={openShopalyst}>
        <span className="cta__content">{Caption}</span>
    </button>;

};

const renderCtaButton = ({Caption, onClick, variantClassName}) => <button
    className={`cta ${variantClassName}`}
    onClick={onClick}>
    <span className="cta__content">{Caption}</span>
</button>;

const CtaComponent = ({Link, Caption, NewWindow, ShopalystProductCode, version, varient, onClick}) => {

    const variantClassName = getClassNameFromVersionAndVariant(version, varient);

    if (!Caption) {

        return null;

    }

    if (Link) {

        return renderCtaLink({
            Caption,
            Link,
            NewWindow,
            variantClassName,
        });

    }

    if (ShopalystProductCode) {

        return renderShopalystCta({
            Caption,
            ShopalystProductCode,
            variantClassName,
        });

    }

    return renderCtaButton({
        Caption,
        onClick,
        variantClassName,
    });


};

export {CtaComponent};

export default ({cta, version, varient}) => {

    if (!cta) {

        return null;

    }

    const {
        Link,
        Caption,
        NewWindow,
        ProductCode,
    } = fieldsArrayToKeyValueHash(cta.fields);

    return <CtaComponent
        Link={Link}
        Caption={Caption}
        NewWindow={NewWindow}
        ShopalystProductCode={ProductCode}
        version={version}
        varient={varient}
    />;

};
