import "./follow-us.scss";
import React from "react";
import StyledIcon from "../../../components/atoms/styled-icon";

import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

export default class FollowUs extends React.Component {

    render () {

        const {
            Title,
            Strapline,
            CtaCollection,
        } = fieldsArrayToKeyValueHash(this.props.fields);

        if (!CtaCollection) {

            return false;

        }

        return (
            <aside className="follow-us">
                <div className="follow-us__inner">
                    {
                        Title &&
                            <h2 className="follow-us__title">
                                {Title}
                            </h2>
                    }
                    {
                        Strapline &&
                            <div className="follow-us__strapline">
                                {Strapline}
                            </div>
                    }
                    <ul className="follow-us__options">
                        {CtaCollection.map((cta, index) => {

                            const {
                                Link,
                                Caption,
                            } = fieldsArrayToKeyValueHash(cta.fields);

                            return <li key={`cta-${index}`} className="follow-us__option">
                                <a
                                    className="follow-us__option-link"
                                    href={Link} target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <StyledIcon icon={Caption} />
                                    <span className="follow-us__option-link-alt">
                                        {Caption}
                                    </span>
                                </a>
                            </li>;

                        })}
                    </ul>
                </div>
            </aside>
        );

    }

}
