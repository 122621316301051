import "./method.scss";
import React from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import sanitise from "global/utils/sanitise";

const Method = (props) => {

    const {
        Title,
        MethodList,
    } = fieldsArrayToKeyValueHash(props.fields);

    if (!MethodList) {

        return false;

    }


    return (
        <section className="method" tabIndex="-1" id="method">
            <div className="method__inner">
                {
                    Title &&
                        <h2 className="method__title">{Title}</h2>
                }
                <ol className="method__list">
                    {
                        MethodList.map((content, index) => <li
                            key={`method-${index}`}
                            className="method__list-item"
                            dangerouslySetInnerHTML={{__html: sanitise(content.value)}}
                        />)
                    }
                </ol>
            </div>
        </section>
    );

};

export default Method;
