import "./video-player.scss";
import React from "react";
import YTPlayer from "yt-player";

export default class VideoPlayer extends React.Component {

    state = {posterImage: true};

    componentDidMount () {

        this.loadYouTube();

    }

    componentDidUpdate (prevProps) {

        if (prevProps.videoId !== this.props.videoId) {

            this.loadYouTube();

        }

    }

    loadYouTube () {

        this.ytplayer = new YTPlayer(`#player-${this.props.videoId}`, {
            height: "100%",
            modestBranding: true,
            related: false,
            width: "100%",
        });
        this.ytplayer.load(this.props.videoId);

    }

    play () {

        this.setState({posterImage: false});
        this.ytplayer.play();

    }

    render () {

        const {props} = this;

        if (!props) {

            return null;

        }

        const posterImageStyle = {
            display:
                this.state.posterImage
                    ? "block"
                    : "none",
        };

        const videoPlayerStyle = {
            display:
                this.state.posterImage
                    ? "none"
                    : "block",
        };

        return (
            <div className="video-player">
                <div
                    className="video-player__poster"
                    style={posterImageStyle}
                >
                    <img
                        className="video-player__poster-image"
                        src={props.posterImage}
                        alt=""
                    />
                    <a
                        className="video-player__poster-playbtn"
                        onClick={this.play.bind(this)}
                    >
                        <span className="video-player__poster-playbtn-alt">
                          Play video
                        </span>
                    </a>
                </div>

                <div
                    className="video-player__video"
                    style={videoPlayerStyle}
                >
                    <div id={`player-${this.props.videoId}`} />
                </div>
            </div>
        );

    }

}
