/*
 * A bit weird, this value or fields thing.
 * Maybe take a look if it feels confusing...
 * This schema schould be fixed though and isArray() should help
 */

export default (fields) => fields.reduce((map, field) => {

    //Console.log("fields", fields);
    if (field.value === null || typeof field.value === "undefined") {

        map[field.title] = field.fields;

    } else {

        map[field.title] = field.value;

    }

    return map;

}, {});
