import React, {Component} from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

export default class Image extends Component {

    state = {backgroundImage: null};

    componentDidMount () {

        this.updateBackgroundImage();

    }

    componentDidUpdate (prevProps) {

        const newImageFields = fieldsArrayToKeyValueHash(this.props.image);
        const oldImageFields = fieldsArrayToKeyValueHash(prevProps.image);

        if (newImageFields.Url !== oldImageFields.Url) {

            this.updateBackgroundImage();

        }

    }

    getImage () {

        const {Url} = fieldsArrayToKeyValueHash(this.props.image);

        return encodeURI(Url);

    }

    updateBackgroundImage () {

        this.setState({backgroundImage: this.getImage()});

    }

    render () {

        let imageUrl = this.state.backgroundImage;
        
        if (!imageUrl) {

            imageUrl = this.getImage();

        }

        return <div
            className="hero__background"
            style={{backgroundImage: `url(${imageUrl})`}}
            role="img"
            aria-label="Hero Background Image"
        />;

    }

}
