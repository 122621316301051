import React, {Component} from "react";
import filterRecipes, {getMatchingRecipeCountForTag} from "./helpers/filter";
import CheckBox from "./checkbox";
import PropTypes from "prop-types";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";

export default class FilterAccordion extends Component {

    state = {accordionOpen: null};

    applyOtherFilterGroups (recipes, currentGroup) {

        const filterGroups = {...this.props.selectedTags};

        delete filterGroups[currentGroup];

        return filterRecipes(recipes, filterGroups);

    }

    handleKeyPress (event, index) {

        const spaceCode = 32;

        if (event.keyCode === spaceCode) {

            this.toggleAccordion(index);

        }

    }

    render () {

        const {isExpanded} = this.props;

        return (
            <div className={"recipe-filters__accordion"}>
                { this.props.filters.map((accordion, index) => {

                    const {
                        Title,
                        Tags,
                        TagProperty,
                    } = fieldsArrayToKeyValueHash(accordion.fields);
                    const isOpen = this.state.accordionOpen === index;
                    const tags = Tags.split(",");

                    const filteredRecipes = this.applyOtherFilterGroups(this.props.recipes, TagProperty);

                    return (
                        <div
                            key={`filter-${index}`}
                            className={"recipe-filters__accordion-group"}
                        >
                            <div className="recipe-filters__accordion-header">
                                <div className="recipe-filters__accordion-header-inner">
                                    <h4
                                        className={`recipe-filters__accordion-title ${isOpen && "recipe-filters__accordion-title--expanded"}`} // eslint-disable-line max-len
                                        tabIndex="0"
                                        onClick={() => this.toggleAccordion(index)}
                                        onKeyPress={(event) => this.handleKeyPress(event, index)}
                                    >
                                        <span className="recipe-filters__accordion-title-text">
                                            {Title}
                                        </span>
                                        <span className="recipe-filters__accordion-title-icon"/>
                                    </h4>
                                </div>
                            </div>

                            <div
                                data-index={index}
                                className={`recipe-filters__accordion-body ${isOpen && "recipe-filters__accordion-body--expanded"}`} // eslint-disable-line max-len
                            >
                                <div className="recipe-filters__accordion-items">
                                    {
                                        tags.map((tag, index2) => <div
                                            key={`filter-${index2}`}
                                            className={"recipe-filters__accordion-item"}
                                        >
                                            <label
                                                className="recipe-filters__accordion-item-heading"
                                                htmlFor={`recipe-filters__accordion-item-checkbox-${index}-${index2}`}
                                            >
                                                {tag}
                                                ({getMatchingRecipeCountForTag(filteredRecipes, TagProperty, tag)})
                                            </label>
                                            <CheckBox
                                                id={`${index}-${index2}`}
                                                filter={tag}
                                                onToggle={() => this.props.toggleTag(TagProperty, tag)}
                                                isChecked={this.props.selectedTags[TagProperty].includes(tag)}
                                            />
                                        </div>)
                                    }
                                </div>
                            </div>
                        </div>
                    );

                })}
            </div>

        );

    }

    toggleAccordion = (index) => {

        const isCurrentlyOpen = this.state.accordionOpen === index;

        if (isCurrentlyOpen) {

            this.setState({accordionOpen: null});

        } else {

            this.setState({accordionOpen: index});

        }

    }

}

FilterAccordion.propTypes = {
    isExpanded: PropTypes.bool.isRequired,
    recipes: PropTypes.array.isRequired,
    selectedTags: PropTypes.object.isRequired,
    toggleTag: PropTypes.func.isRequired,
};
