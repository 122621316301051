/* eslint-disable max-len, max-lines, no-console, no-return-assign, class-methods-use-this, max-statements */
import React from "react";
import WhereToBuySection from "../WhereToBuySection";
import scriptLoader from "react-async-script-loader";


class WhereToBuy extends React.Component {

    componentWillReceiveProps ({isScriptLoaded, isScriptLoadSucceed}) {

        if (isScriptLoaded && !this.props.isScriptLoaded) {

            if (isScriptLoadSucceed) {

                this.renderWtb();

            }

        }

    }

    componentDidMount () {

        const {isScriptLoaded, isScriptLoadSucceed} = this.props;

        if (isScriptLoaded && isScriptLoadSucceed) {

            this.renderWtb();

        }

    }

    renderWtb () {

        return <WhereToBuySection {...this.props} />;

    }

    render () {

        return <WhereToBuySection {...this.props} />;

    }

}

export default scriptLoader([])(WhereToBuy);
