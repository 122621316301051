import "./hero-background.scss";
import React, {Component} from "react";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import isMobile from "is-mobile";
import isServerSide from "global/utils/is-server-side";

const renderStaticImage = (imageProps) => <div
    className="hero__background"
    style={{backgroundImage: `url(${imageProps.Url})`}}
/>;

export default class VideoHero extends Component {

    videoPlayerRef = null;

    setVideoRef = (ref) => {

        this.videoPlayerRef = ref;

    };

    renderVideo (videoFile) {

        if (isServerSide()) {

            return <div className="hero__background" />;

        }

        return <div className="hero__background">
            <video
                className="hero__background-video"
                ref={this.setVideoRef}
                muted
                loop
                autoPlay
                aria-hidden={true}
            >
                <source src={videoFile} />
            </video>
        </div>;

    }

    componentDidMount () {

        if (this.videoPlayerRef) {

            this.videoPlayerRef.play();

        }

    }

    render () {

        const {
            PosterImage,
            VideoFile,
        } = fieldsArrayToKeyValueHash(this.props.video);

        if (isMobile()) {

            return renderStaticImage(fieldsArrayToKeyValueHash(PosterImage));

        }

        return this.renderVideo(VideoFile);

    }


}

