import "./recipe-hero.scss";

import {elementFocusWithoutScroll, getScrollTop} from "global/utils/scroll";

import {NavLink} from "react-router-dom";
import React from "react";

import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import smoothScroll from "global/utils/smooth-scroller";
import {withRouter} from "react-router";
import { link } from "fs";

const Subnav = (props) => {

    const stickyElementSelectors = ["#recipe__navigation"];


    return <nav id="recipe__navigation" className="recipe__navigation">
        <ul className="recipe__navigation-items">
            {props.links.map((field, index) => {

                const {Caption, Link} = fieldsArrayToKeyValueHash(field.fields);
                const isSelected = props.location.hash === Link;
                const selectedClassName = isSelected && "selected";

                return <li key={`recipe-${index}`} className="recipe__navigation-item">
                    <NavLink
                        className={`recipe__navigation-action ${selectedClassName || ""}`}
                        to={Link}
                        onClick={(evt) => {

                            evt.preventDefault();
                            props.history.replace(Link);

                            const target = document.querySelector(Link);

                            elementFocusWithoutScroll(target);
                            smoothScroll(Link, stickyElementSelectors);

                        }}>
                        {Caption ? Caption: Link.substring(1)}
                    </NavLink>
                </li>;

            })}
        </ul>
    </nav>;

};

export default withRouter(Subnav);
